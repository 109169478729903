"use client";
import Buttons from "../../components/Buttons";
import FormFields from "../../components/FormFields";
import { useUserContext } from "../../context/UserProvider";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { BENEFICIARIES_ALL_PATH, BENEFICIARIES_PATH } from "../../helpers/Slug";

const LoginForm = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const { LoginUser } = useUserContext();
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        const res = await LoginUser(data);

        if (res?.status === 200) {
            if (res.data.user.role === "Dealer") {
                navigate("/dealer-info");
            } else {
                navigate(`${BENEFICIARIES_PATH}/${BENEFICIARIES_ALL_PATH}`);
            }
        }
    };
    return (
        <div className="w-full h-[calc(100vh-21vh)] flex items-center justify-center ">
            <div className="py-12 px-6 bg-slate-100 rounded-lg shadow-gray">
                <h1 className="text-2xl font-bold text-blue-950 text-center w-full">
                    রায়পুরা সুপার এডমিন লগইন/Login{" "}
                </h1>
                <form onSubmit={handleSubmit(onSubmit)} className="mt-12">
                    <label className="text-blue-900 text-base font-medium mb-2 block">
                        User ID / ইউজার আইডি:{" "}
                        <span className="text-red-600">*</span>{" "}
                    </label>
                    <FormFields.Text
                        register={register}
                        name={"mobile"}
                        placeholder={"01xxxxxxxxx"}
                        validations={{
                            pattern: /^(?:(?:\+|00)88|01)?\d{11}$/,
                            required: true,
                        }}
                        errors={errors}
                        errorMsg={"সঠিক ইউজার আইডি  আবশ্যক"}
                        width="w-[438px]"
                        isRequired={true}
                    />

                    <label className="text-blue-900 text-base font-medium mt-6 mb-2 block">
                        Password / পাসওয়ার্ড :{" "}
                        <span className="text-red-600">*</span>{" "}
                    </label>
                    <FormFields.Password
                        register={register}
                        name={"password"}
                        errors={errors}
                        errorMsg={
                            "পাসওয়ার্ডে সর্বনিম্ন ১টি বড় অক্ষর, ১টি ছোট অক্ষর, ১টি নম্বর ও ১টি বিশেষ ক্যারেক্টার থাকতে হবে"
                        }
                        placeholder={"******"}
                        width="w-[438px]"
                        isRequired={true}
                        validations={{
                            pattern:
                                /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^!&*])[A-Za-z0-9@#$%^!&*]{6,}$/,
                            required: true,
                        }}
                    />

                    <div className="mt-6">
                        <Buttons.Primary
                            label={"লগইন/Login"}
                            width={"w-full"}
                            radius={"rounded"}
                            type={"submit"}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LoginForm;
