import React from "react";
import Layouts from "../../layouts";
import { Outlet } from "react-router-dom";

const Card = () => {
    return (
        <Layouts.Secondary>
            <div className="pt-[88px] lg:pl-[280px] h-screen bg-neutral-50 overflow-y-auto">
                <Outlet />
            </div>
        </Layouts.Secondary>
    );
};

export default Card;
