import bn from "date-fns/locale/bn";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import Icons from "../../assets/Icons";
import Buttons from "../../components/Buttons";
import ImageUploader from "../../components/Common/ImageUploader";
import FormFields from "../../components/FormFields";
import { useCardContext } from "../../context/CardProvider";
import {
    dateDBStringToDate,
    dateFormatter,
    range,
    checkDobFormat,
} from "../../utils";
import { getMonth, getYear } from "date-fns";

const BeneficiaryUpdateForm = ({
    villages,
    singleUser,
    handleUpdate,
    handleImageUpload,
    isDetails,
    type,
    selectedImage,
    setSelectedImage,
}) => {
    const navigate = useNavigate();
    const { setSingleBeneficiaries } = useCardContext();

    const [data, setData] = useState({
        name: singleUser?.name ? singleUser.name : "",
        nid: singleUser?.nid ? singleUser.nid : "",
        fatherNID: singleUser?.fatherNID ? singleUser.fatherNID : "",
        fatherName: singleUser?.fatherName ? singleUser.fatherName : "",
        mobile: singleUser?.mobile ? singleUser.mobile : "",
        occupation: singleUser?.occupation ? singleUser.occupation : "",
        spouseNID: singleUser?.spouseNID ? singleUser.spouseNID : "",
        spouseName: singleUser?.spouseName ? singleUser?.spouseName : "",
        union: singleUser?.union ? singleUser.union : "",
        upazila: singleUser?.upazila ? singleUser.upazila : "",
        ward: singleUser?.ward ? singleUser.ward : "",
        dob: singleUser?.dob ? singleUser?.dob : "",
        village: singleUser?.village ? singleUser.village : "",
        image: singleUser?.image ? singleUser.image : "",
    });

    const years = range(1900, getYear(new Date()) + 1, 1);
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const [union, setUnion] = useState(null);

    const [errors, setErrors] = useState({
        name: "",
        nid: "",
    });

    // console.log(data);

    const customLocale = {
        ...bn, // Include the base Bengali locale
        options: { ...bn.options, weekStartsOn: 0 },
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value,
        });

        if (name === "name" && value === "") {
            setErrors((prev) => ({ ...prev, name: "উপকারভোগীর নাম আবশ্যক" }));
        } else if (name === "name" && value !== "") {
            setErrors((prev) => ({ ...prev, name: "" }));
        }

        if (name === "nid" && value === "") {
            setErrors((prev) => ({ ...prev, nid: "পরিচয় পত্র নং আবশ্যক" }));
        } else if (name === "nid" && value !== "") {
            setErrors((prev) => ({ ...prev, nid: "" }));
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();

        delete data._id;
        delete data.serial;
        delete data.district;
        delete data.upazila;
        delete data.editors;
        delete data.createdBy;
        delete data.createdAt;
        delete data.updatedAt;
        delete data.status;
        delete data.__v;
        delete data.isDeleted;

        if (data.name !== "" && data.nid !== "") {
            if (union) {
                data.union = union;
            }
            handleUpdate(data);
        }
        // console.log(data);
    };

    const handleBackNav = () => {
        setSingleBeneficiaries({});
        navigate(-1);
    };

    const handleImageEmpty = () => {
        setData((ps) => ({ ...data, image: "" }));
    };

    const handleUnion = (value) => {
        setUnion(value);
    };

    return (
        <form onSubmit={onSubmit} className="mt-9 mb-4 flex flex-col ">
            <div className="">
                <ImageUploader
                    defaultImage={data.image}
                    imageUpload={handleImageUpload}
                    create={false}
                    type={type}
                    singleUser={singleUser}
                    handleImageEmpty={handleImageEmpty}
                    isDetails={isDetails}
                    selectedImage={selectedImage}
                    setSelectedImage={setSelectedImage}
                />
                <div className="flex flex-col xs:flex-col sm:flex-row md:flex-row lg:flex-row flex-wrap gap-10 items-start justify-between w-full py-10">
                    <Box>
                        <h6 className="text-slate-800 font-medium text-base mb-2">
                            উপকারভোগীর নাম
                        </h6>
                        <FormFields.TextForUpdate
                            defaultValue={data.name}
                            name="name"
                            width="w-full"
                            onChange={handleChange}
                            // errorMsg={errors?.name}
                            // errorMsg="উপকারভোগীর নাম আবশ্যক"
                            errorMsg={errors.name}
                        />
                    </Box>
                    <Box>
                        <h6 className="text-slate-800 font-medium text-base mb-2">
                            জন্ম তারিখ
                        </h6>
                        <fieldset className="border-[1.5px] border-[#919EAB] border-opacity-[32%] rounded-sm pb-[.8rem] pt-4 px-3.5 flex items-center">
                            <DatePicker
                                locale={customLocale}
                                placeholderText="তারিখ" // Placeholder in Bengali
                                dateFormat="dd/MM/yyyy" // Customize date format if needed
                                className="text-base text-slate-800 leading-6 border-none outline-none w-[142px]"
                                onChange={(date) => {
                                    setData((ps) => ({
                                        ...ps,
                                        dob: dateFormatter(date, false),
                                    }));
                                }}
                                selected={
                                    checkDobFormat(data?.dob)
                                        ? new Date(dateDBStringToDate(data.dob))
                                        : null
                                }
                                maxDate={new Date()}
                                renderCustomHeader={({
                                    date,
                                    changeYear,
                                    changeMonth,
                                    decreaseMonth,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled,
                                }) => (
                                    <div
                                        style={{
                                            margin: 10,
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                        className="p-2 border-2 rounded-md"
                                    >
                                        <button
                                            onClick={decreaseMonth}
                                            disabled={prevMonthButtonDisabled}
                                            className="w-5 hover:bg-slate-300 rounded-full"
                                            type="button"
                                        >
                                            {"<"}
                                        </button>
                                        <select
                                            // value={getYear(date)}
                                            onChange={({ target: { value } }) =>
                                                changeYear(value)
                                            }
                                            defaultValue={getYear(
                                                new Date(
                                                    dateDBStringToDate(data.dob)
                                                )
                                            )}
                                            className="w-13"
                                        >
                                            {years.map((option) => (
                                                <option
                                                    key={option}
                                                    value={option}
                                                >
                                                    {option}
                                                </option>
                                            ))}
                                        </select>

                                        <select
                                            value={months[getMonth(date)]}
                                            onChange={({ target: { value } }) =>
                                                changeMonth(
                                                    months.indexOf(value)
                                                )
                                            }
                                            // className="border-2 w-full"
                                        >
                                            {months.map((option) => (
                                                <option
                                                    key={option}
                                                    value={option}
                                                >
                                                    {option}
                                                </option>
                                            ))}
                                        </select>

                                        <button
                                            onClick={increaseMonth}
                                            disabled={nextMonthButtonDisabled}
                                            className="w-5 hover:bg-slate-300 rounded-full"
                                            type="button"
                                        >
                                            {">"}
                                        </button>
                                    </div>
                                )}
                            />
                        </fieldset>
                    </Box>
                    <Box>
                        <h6 className="text-slate-800 font-medium text-base mb-2">
                            পরিচয় পত্র নং
                        </h6>
                        <FormFields.TextForUpdate
                            defaultValue={data.nid}
                            name="nid"
                            width="w-full"
                            onChange={handleChange}
                            errorMsg={errors.nid}
                        />
                    </Box>
                    <Box className=" justify-self-start">
                        <h6 className="text-slate-800 font-medium text-base mb-2 mt-6">
                            স্বামী / স্ত্রীর নাম
                        </h6>
                        <FormFields.TextForUpdate
                            defaultValue={data.spouseName}
                            name="spouseName"
                            width="w-full"
                            onChange={handleChange}
                        />
                    </Box>
                    <Box className=" justify-self-start">
                        <h6 className="text-slate-800 font-medium text-base mb-2 mt-6">
                            পিতার নাম
                        </h6>
                        <FormFields.TextForUpdate
                            value={data.fatherName}
                            name="fatherName"
                            width="w-full"
                            onChange={handleChange}
                        />
                    </Box>
                    <Box>
                        <h6 className="text-slate-800 font-medium text-base mb-2 mt-6">
                            পিতা পরিচয় পত্র নং
                        </h6>
                        <FormFields.TextForUpdate
                            defaultValue={data.fatherNID}
                            name="fatherNID"
                            width="w-full"
                            onChange={handleChange}
                        />
                    </Box>
                    <Box>
                        <h6 className="text-slate-800 font-medium text-base mb-2 mt-6">
                            স্বামীর / স্ত্রীর পরিচয় পত্র নং
                        </h6>
                        <FormFields.TextForUpdate
                            defaultValue={data.spouseNID}
                            name="spouseNID"
                            width="w-full"
                            onChange={handleChange}
                        />
                    </Box>
                    <Box>
                        <h6 className="text-slate-800 font-medium text-base mb-2 mt-6">
                            ঠিকানা
                        </h6>
                        <FormFields.TextForUpdate
                            defaultValue={data.village}
                            name="village"
                            width="w-full"
                            onChange={handleChange}
                        />
                    </Box>

                    <Box>
                        <h6 className="text-slate-800 font-medium text-base mb-2 mt-6">
                            পেশা
                        </h6>
                        <FormFields.TextForUpdate
                            defaultValue={data.occupation}
                            name="occupation"
                            width="w-full"
                            onChange={handleChange}
                        />
                    </Box>
                    <Box>
                        <h6 className="text-slate-800 font-medium text-base mb-2 mt-6">
                            মোবাইল
                        </h6>
                        <FormFields.TextForUpdate
                            defaultValue={data.mobile}
                            name="mobile"
                            width="w-full"
                            onChange={handleChange}
                        />
                    </Box>
                    <Box className=" justify-self-start">
                        <h6 className="text-slate-800 font-medium text-base mb-2 mt-6">
                            ওয়ার্ড
                        </h6>
                        <FormFields.TextForUpdate
                            defaultValue={data.ward}
                            name="ward"
                            width="w-full"
                            onChange={handleChange}
                        />
                    </Box>

                    <Box className="col-span-1 mt-6">
                        <h6 className="text-slate-800 font-medium text-base mb-2">
                            ইউনিয়ন সিলেক্ট করুন
                        </h6>
                        <FormFields.SelectField
                            data={villages ? villages : []}
                            placeholder="ইউনিয়ন সিলেক্ট করুন"
                            defaultValue={singleUser?.union}
                            handleSelectClick={handleUnion}
                            errors={errors}
                            errorMsg="ইউনিয়ন সিলেক্ট বাধ্যতামূলক"
                            dropdownPosition="top-[56px]"
                            name="union"
                            width="w-full"
                            paddingY="py-3.5"
                            paddingX="px-5"
                            border="border border-slate-300"
                            radius="rounded"
                            dropDownHeight="max-h-48"
                            // openOnce={openOnce}
                            isReadOnly={false}
                        />
                    </Box>
                </div>
                <div className="py-7 px-9 flex justify-end gap-3 border-t border-[#919EAB1F] border-opacity-20">
                    <Buttons.Outline
                        borderColor="border-slate-400"
                        label="ফেরত যান"
                        textColor="text-slate-600"
                        fontWeight="font-bold"
                        textSize="text-sm"
                        width="w-[120px]"
                        onClick={handleBackNav}
                    />

                    <Buttons.Primary
                        label="সেভ করুন"
                        icon={Icons.edit}
                        bgColor="bg-green-600"
                        radius="rounded-[2px]"
                        textColor="text-white"
                        textSize={"font-bold"}
                        width="w-[120px]"
                        type="submit"
                    />
                </div>
            </div>
        </form>
    );
};

export default BeneficiaryUpdateForm;

export const Box = ({ children, className }) => {
    return (
        <div
            className={`flex flex-col gap-2 w-[100%] sm:w-[100%] md:w-[29%] lg:w[30%] ${className}`}
        >
            {children}
        </div>
    );
};
