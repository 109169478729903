import axios from "axios";
import { DASHBOARD_STATISTICS_URL } from "../helpers/APIURL";

const getDashboardData = (token) => {
    const config = {
        headers: {
            authorization: token,
        },
    };
    return axios.get(DASHBOARD_STATISTICS_URL, config);
};

const DashboardService = {
    getDashboardData,
};

export default DashboardService;
