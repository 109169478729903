import React, { useEffect, useState } from "react";

import Icons from "../../assets/Icons";
import { withToast } from "../../hoc/withToast";
import BeneficiaryService from "../../services/BeneficiaryService";
import Loader from "./Loader";

const DistributionImageModal = ({
    setOpen,
    image,
    showToast,
    selectedItems,
    setSelectedItems,
    token,
}) => {
    // console.log(image);

    const [saveState, setSaveState] = useState(null);

    useEffect(() => {
        async function fetchData() {
            // You can await here

            try {
                const res = await BeneficiaryService.getDownlaodExcelFile(
                    token,
                    image
                );
                if (res) {
                    setSaveState(res);
                }
                // console.log(res);
            } catch (e) {
                console.log(e);
            }
        }
        fetchData();
        return () => {
            setSaveState(null);
        };
    }, [token, image]);
    // console.log(saveState);

    return (
        <div className="bg-white rounded-md ">
            <div className="p-4 bg-blue-950 flex items-center justify-between rounded-t-md">
                <p className="text-slate-50 text-base leading-6">
                    বিতরণকৃত উপকারভোগীর নথি
                </p>
                <button onClick={() => setOpen(false)}>
                    <img
                        src={Icons.cross_white}
                        alt="cross_white"
                        className="w-5 h-5"
                    />
                </button>
            </div>
            <div className="flex  justify-center items-center p-8 w-[35rem] h-[35rem]">
                {/* {saveState?.data?.url && (
                    <img
                        src={`${saveState?.data?.url}`}
                        className="w-96 h-96"
                    />
                )} */}
                {saveState?.data?.url ? (
                    <img
                        src={`${saveState?.data?.url}`}
                        alt="NID"
                        className="w-[30rem] h-[30rem] rounded-md"
                    />
                ) : (
                    <Loader />
                )}
            </div>
        </div>
    );
};

export default withToast(DistributionImageModal);
