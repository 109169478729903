import { USER_PROFILE_UPDATE, USER_PASSWORD_UPDATE } from "../helpers/APIURL";
import axios from "axios";

/*
  get user info, update the user info and update user password.
*/

const getUserProfileInfo = (token, id) => {
    const config = {
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
        params: {
            id,
        },
    };
    return axios.get(USER_PROFILE_UPDATE, config);
};

const updateUserInfo = (token, id, data) => {
    const config = {
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
        params: {
            id,
        },
    };
    return axios.patch(USER_PROFILE_UPDATE, data, config);
};

const updateUserPass = (token, data) => {
    const config = {
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
    };
    return axios.patch(USER_PASSWORD_UPDATE, data, config);
};

const ProfileService = { updateUserInfo, updateUserPass, getUserProfileInfo };
export default ProfileService;
