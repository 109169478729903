import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import Buttons from "../../components/Buttons";
import Icons from "../../assets/Icons";
import Filter from "../../components/Filter";
import { roleNameDropDown } from "../../libs/common";
import { useBeneficiaryContext } from "../../context/BeneficiaryProvider";
import TableHeader from "../../components/Common/TableHeader";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import Tooltip from "../../layouts/Tooltip";
import IconWithLabel from "../../components/Buttons/IconWithLabel";
import { useUserContext } from "../../context/UserProvider";
import Table from "../../components/Table";
import TableFooter from "../../components/Common/TableFooter";
import { USER_MANAGEMENT_RESET_PASSWORD_PATH } from "../../helpers/Slug";

const UserManageMentList = () => {
    const [activeTooltip, setActiveTooltip] = useState("");
    const [selectedItems, setSelectedItems] = useState([]);
    const [firstRender, setFirstRender] = useState(true);

    const { id } = useParams();
    const navigate = useNavigate();
    const {
        villages,
        getVillageData,
        // queryParams,
        // setQueryParams,
        // unionBeneficiaryParams,
        // setUnionBeneficiaryParams,
        // patchBeneficiaryRequestApproval,
    } = useBeneficiaryContext();

    const {
        user,
        getUsers,
        userList,
        usersTotalPages,
        userParams,
        setUserParams,
        deleteUsers,
    } = useUserContext();

    useEffect(() => {
        setActiveTooltip("");
    }, [navigate]);

    // const handleSendRequest = async (id) => {
    //     const res = await patchBeneficiaryRequestApproval(user.token, id);
    //     if (res === 200) {
    //         getUsers(
    //             user.token,
    //             unionBeneficiaryParams.page,
    //             unionBeneficiaryParams.limit,
    //             unionBeneficiaryParams
    //         );
    //         setSelectedItems([]);
    //     }
    // };

    const handleDelete = async (id) => {
        const res = await deleteUsers(user?.token, id);
        if (res === 200) {
            getUsers(user.token, userParams.page, userParams.limit, userParams);
            setSelectedItems([]);
        }
        setActiveTooltip("");
        // navigate(-1);
    };

    const renderActionComponents = (data) => {
        return (
            <>
                {/* <td className="py-3 flex justify-center w-full">
                    <div className="text-sm font-medium text-red-600 bg-red-100 px-2 py-1 rounded">
                        বাতিল
                    </div>
                </td> */}

                <td>
                    <div className="px-4 relative">
                        <button onClick={() => setActiveTooltip(data._id)}>
                            {/* {console.log(
                                "User id in render action %s",
                                data._id
                            )} */}
                            <EllipsisVerticalIcon className="w-8 h-8 text-slate-700" />
                        </button>
                        {activeTooltip === data._id && (
                            <Tooltip
                                top={
                                    userList
                                        .slice(-2)
                                        .some((item) => item._id === data._id)
                                        ? userList.length >= 3
                                            ? "-top-28"
                                            : "-top-[1.7rem]"
                                        : "-top-3"
                                }
                                right="right-14"
                                overlayVariant="transparent"
                                // overlayZIndex="z-40"
                                // popupZIndex="z-50"
                                align={
                                    userList
                                        .slice(-2)
                                        .some((item) => item._id === data._id)
                                        ? userList.length >= 3
                                            ? "bottom"
                                            : "top"
                                        : "top"
                                }
                                setOpen={setActiveTooltip}
                            >
                                <div className="p-2 bg-white rounded w-[220px]">
                                    <IconWithLabel
                                        icon={Icons.edit}
                                        label="এডিট"
                                        paddingY="py-2.5 hover:bg-gray-50"
                                        paddingX="px-2 hover:bg-gray-50"
                                        width="w-full"
                                        align="justify-start"
                                        onClick={() =>
                                            navigate(`update/${data._id}`)
                                        }
                                    />
                                    <IconWithLabel
                                        icon={Icons.password}
                                        label="পাসওয়ার্ড পরিবর্তন"
                                        paddingY="py-2.5 hover:bg-gray-50"
                                        paddingX="px-2 hover:bg-gray-50"
                                        width="w-full"
                                        align="justify-start"
                                        onClick={() => {
                                            navigate(
                                                `${USER_MANAGEMENT_RESET_PASSWORD_PATH}/${data._id}`
                                            );
                                        }}
                                    />
                                    <IconWithLabel
                                        icon={Icons.delete_icon}
                                        label="ডিলিট"
                                        paddingY="py-2.5 hover:bg-gray-50"
                                        paddingX="px-2 hover:bg-gray-50"
                                        width="w-full"
                                        align="justify-start"
                                        onClick={() => handleDelete(data._id)}
                                    />
                                </div>
                            </Tooltip>
                        )}
                    </div>
                </td>
            </>
        );
    };

    useEffect(() => {
        if (user && firstRender) {
            getVillageData(user.token);
            getUsers(user.token, userParams.page, userParams.limit, {
                page: 1,
                limit: 10,
                startDate: null,
                endDate: null,
                status: null,
                union: null,
                search: null,
            });
            setFirstRender(false);
            setUserParams({
                page: 1,
                limit: 10,
                startDate: null,
                endDate: null,
                status: null,
                union: null,
                search: null,
            });
        } else if (user) {
            getVillageData(user.token);
            getUsers(user.token, userParams.page, userParams.limit, userParams);
        }
    }, [
        user,
        userParams,
        getUsers,
        getVillageData,
        setUserParams,
        firstRender,
    ]);

    return (
        <>
            {id ? (
                <Outlet />
            ) : (
                <div className="py-12 px-10 flex items-center rounded flex-col">
                    <div>
                        <div className="py-9 w-full flex justify-start">
                            <Buttons.IconWithLabel
                                label="নতুন ব্যাবহারকারী তৈরি"
                                paddingX="px-3"
                                paddingY="py-2.5"
                                icon={Icons.add_white}
                                bgColor="bg-green-600"
                                radius="rounded-[2px]"
                                textColor="text-white"
                                textSize={"font-bold"}
                                onClick={() => navigate("/users/create")}
                            />
                        </div>
                        <div className="shadow-medium-gray rounded-lg overflow-hidden w-[350px] sm:w-[640px] md:w-[550] lg:w-[700px] xl:w-auto">
                            <Filter.Primary
                                firstSelectData={roleNameDropDown}
                                firstSelectLabel="রোল নাম"
                                secondSelectLabel="ঠিকানা"
                                secondSelectData={villages ? villages : []}
                                searchbarLabel="ব্যাবহারকারী সার্চ করুন"
                                setQueryParams={setUserParams}
                            />
                            <TableHeader
                                selectedItems={selectedItems}
                                setSelectedItems={setSelectedItems}
                                title={"সকল ব্যাবহারকারী লিস্ট"}
                                selectedTitle={"জন সিলেক্টেড"}
                            >
                                <>
                                    <Buttons.Primary
                                        label={"ডিলিট"}
                                        onClick={() => {
                                            handleDelete(selectedItems);
                                        }}
                                        paddingX="px-6"
                                        paddingY="py-2"
                                        fontWeight="font-bold"
                                        bgColor="bg-[#DC2626]"
                                    />
                                </>
                            </TableHeader>
                            <Table.Primary
                                // tableHeads={["উপকারভোগীর নাম", "পরিচয় পত্র নং", "ঠিকানা", "মোবাইল"]}
                                tableHeads={tableHeadsData}
                                // tableDataKeys={["name", "nid", "village", "mobile"]}
                                tableDataKeys={tableDataKeys}
                                // tableActionHeads={["কার্ড জেনারেট অনুরোধ অনুমোদন", ""]}
                                tableActionHeads={tableActionHeads}
                                tableData={userList}
                                tableActionComponents={renderActionComponents}
                                setSelectedItems={setSelectedItems}
                                selectedItems={selectedItems}
                                errorMessage="ব্যাবহারকারী খুঁজে পাওয়া যায়নি"
                                needMinHeight={true}
                            />
                            <TableFooter
                                params={userParams}
                                setParams={setUserParams}
                                pageSize={usersTotalPages}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default UserManageMentList;
const tableHeadsData = [
    {
        title: "ব্যাবহারকারী",
        align: "left",
        width: "w-[237px]",
    },
    // {
    //     title: "ইউজার আইডি",
    //     align: "left",
    //     width: "w-[237px]",
    // },
    // {
    //     title: "ই-মেইল",
    //     align: "left",
    //     width: "w-[237px]",
    // },
    {
        title: "রোল নাম",
        align: "left",
        width: "w-[237px]",
    },
    {
        title: "ঠিকানা",
        align: "left",
        width: "w-[237px]",
    },
    {
        title: "মোবাইল",
        align: "left",
        width: "w-[237px]",
    },
];

const tableDataKeys = [
    {
        title: "name",
        width: "w-[250px]",
    },
    // {
    //     title: "user_id",
    //     width: "w-[250px]",
    // },
    // {
    //     title: "email",
    //     width: "w-[250px]",
    // },
    {
        title: "role",
        width: "w-[250px]",
    },
    {
        title: "union",
        width: "w-[250px]",
    },
    {
        title: "mobile",
        width: "w-[250px]",
    },
];

const tableActionHeads = [
    {
        title: "",
        align: "center",
        width: "w-[72px]",
    },
];
