export const LOGIN_PATH = "/login";
export const DASHBOARD_PATH = "/dashboard";

//beneficiary

export const BENEFICIARIES_PATH = "/beneficiaries";
export const BENEFICIARIES_ALL_PATH = "all";
export const BENEFICIARIES_APPROVE_PATH = "approve";
export const BENEFICIARIES_APPROVED_PATH = "approved";
export const BENEFICIARIES_REJECTED_PATH = "rejected";
export const BENEFICIARIES_UPLOAD_EXCEL = "upload-excel";
export const BENEFICIARIES_FAILED_UPLOAD_EXCEL = "failed-upload-excel";

//Card

export const CARD_PATH = "/cards";
export const CARD_REQUEST_PATH = "requests";
export const CARD_REJECTED_PATH = "rejected";
export const CARD_GENERATED_PATH = "generated";
export const CARD_GENERATE_PATH = "generate";
export const CARD_APPROVED_PATH = "approved";

// Distribution management

export const DISTRIBUTION_SCHEDULE_PATH = "/distribution-schedule";
export const DISTRIBUTION_INFO = "list";
export const DISTRIBUTION_READ = "distributions";
export const DISTRIBUTION_NID = "distribution-with-nid";

//Report

export const REPORT_PATH = "/reports";
export const REPORT_GENERATE_PATH = "generate";
export const GENERATED_REPORTS_PATH = "generated-reports";

// BeneficiaryUnion

export const BENEFICIARY_UNION_PATH = "send-beneficiaries-for-approval";
export const CREATE_BENEFICIARY_UNION_PATH = "create";

//CardUnion
export const CARD_UNION_PATH = "send-card-generate-request";
//UserManagement
export const USER_MANAGEMENT_PATH = "/users";
export const USER_MANAGEMENT_CREATE_PATH = "create";
export const USER_MANAGEMENT_UPDATE_PATH = "update";
export const USER_MANAGEMENT_RESET_PASSWORD_PATH = "reset-password";

// Profile Settings
export const USER_PROFILE_SETTINGS = "/profile-settings";

export const CREATE_PATH = "create";
