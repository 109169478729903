import React from "react";
import { Circles } from "react-loader-spinner";

const Loader = () => {
    return (
        <div className="fixed top-0 left-0 z-50 w-full h-screen flex justify-center items-center bg-green-600 bg-opacity-[15%]">
            <Circles
                height="80"
                width="80"
                radius="9"
                color="green"
                ariaLabel="loading"
            />
        </div>
    );
};

export default Loader;
