import * as PATH from "../Slug";
import { Permission } from "../permissionStrings";
// import Icons from "../../assets/Icons";
import Icons from "../../assets/Icons";

export const profileSettingsManagement = [
    {
        name: "প্রোফাইল সেটিংস",
        icon: Icons.profile_settings,
        hoverIcon: Icons.active_profile_settings,
        current: false,
        href: PATH.USER_PROFILE_SETTINGS,
        hidden: false,
        permissions: [Permission.UPDATE_PROFILE, Permission.READ_PROFILE],
        children: [],
    },
];
