import Text from "./Text";
import Password from "./Password";
import LegendDateField from "./LegendDateField";
import LegendSelectField from "./LegendSelectField";
import SearchField from "./SearchField";
import LegendStatusField from "./LegendStatusField";
import DateField from "./datePicker";
import SelectField from "./SelectField";
import ModalSelectField from "./ModalSelectField";
import TextForUpdate from "./TextForUpdate";

const FormFields = {
    Text,
    Password,
    LegendDateField,
    LegendSelectField,
    SearchField,
    LegendStatusField,
    DateField,
    SelectField,
    ModalSelectField,
    TextForUpdate,
};

export default FormFields;
