import React from "react";
import { useNavigate } from "react-router-dom";
import Icons from "../../assets/Icons";

const DealerMessage = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        sessionStorage.removeItem("user");
        navigate("/login");
    };

    return (
        <div>
            {/* <Navbar.Primary /> */}
            <div className="flex flex-col items-center justify-center w-full h-[100vh] gap-2">
                <div className="p-5 bg-red-50 rounded-lg">
                    <p className="font-semibold">
                        দয়া করে আপনার অ্যাপ এর মাধ্যমে সিস্টেম ব্যবহার করুন
                    </p>
                </div>
                <button
                    className="py-3 px-6 flex items-start gap-x-2 mt-2 bg-green-50 rounded-lg"
                    onClick={handleLogout}
                >
                    <img src={Icons.logout} alt="logout" className="w-5 h-5" />
                    <p className="text-base text-slate-500 left-6">লগ আউট</p>
                </button>
            </div>
        </div>
    );
};

export default React.memo(DealerMessage);
