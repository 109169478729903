import bd_gov_logo from "./bd_gov_logo.png";
import tcb_logo from "./tcb_logo.png";
import close_eye from "./close_eye.svg";
import open_eye from "./open_eye.svg";
import open_eye_white from "./open_eye_white.svg";
import card_user_hover from "./card_user_hover.svg";
import card_user from "./card_user.svg";
import user_multiple_hover from "./user_multiple_hover.svg";
import user_multiple from "./user_multiple.svg";
import white_polygon from "./white_Polygon.svg";
import delete_icon from "./delete_icon.svg";
import edit from "./edit.svg";
import file from "./file.svg";
import cancel from "./cancel.svg";
import date from "./date.svg";
import search from "./search.svg";
import logout from "./logout.svg";
import download from "./download.svg";
import download_green from "./download_green.svg";
import upload from "./upload.svg";
import cross_white from "./cross_white.svg";
import add from "./add.svg";
import report from "./report.svg";
import activeReport from "./activeReport.svg";
import Dropdown from "./Dropdown.svg";
import add_white from "./add-white.svg";
import UserManagement from "./User.svg";
import activeUserManagement from "./activeUser.svg";
import password from "./password.svg";
import photo_upload from "./photo_upload.svg";
import profile_settings from "./profile_settings.svg";
import active_profile_settings from "./active_profile_settings.svg";
import dashboard from "./dashboard.svg";
import dashboard_green from "./dashboard_green.svg";
import dash_beneficiary from "./dash_beneficiary.svg";
import dash_dealer from "./dash_dealer.svg";
import dash_distribution from "./dash_distribution.svg";
import dash_union from "./dash_union.svg";
import dash_upazila from "./dash_upazila.svg";
import print from "./Print.svg";

const Icons = {
    active_profile_settings,
    password,
    activeUserManagement,
    UserManagement,
    add_white,
    bd_gov_logo,
    tcb_logo,
    close_eye,
    open_eye,
    open_eye_white,
    card_user,
    card_user_hover,
    user_multiple,
    user_multiple_hover,
    white_polygon,
    delete_icon,
    edit,
    file,
    cancel,
    date,
    search,
    logout,
    download,
    download_green,
    upload,
    cross_white,
    add,
    Dropdown,
    report,
    activeReport,
    photo_upload,
    profile_settings,
    dashboard,
    dashboard_green,
    dash_beneficiary,
    dash_dealer,
    dash_distribution,
    dash_union,
    dash_upazila,
    print,
};

export default Icons;
