import React, { useState } from "react";
import Toast from "../components/Notification/Toast";

export function withToast(WrappedComponent) {
    return function WithToast(props) {
        const [toast, setToast] = useState({
            show: false,
            message: "",
            isError: false,
        });

        // TODO: Investigate possibilities of re-renders

        const showToast = React.useCallback((message, isError = false) => {
            setToast({ show: true, message, isError });

            setTimeout(() => {
                setToast((currentToast) => {
                    return { ...currentToast, show: false };
                });
            }, 3000);
        }, []);

        return (
            <>
                <WrappedComponent {...props} showToast={showToast} />
                <Toast
                    {...toast}
                    setShow={(show) => setToast({ ...toast, show })}
                />
            </>
        );
    };
}
