import Icons from "../../assets/Icons";
import CardApprove from "../../pages/Card/CardApprove";
import CardGenerate from "../../pages/Card/CardGenerate";
import CardGenerated from "../../pages/Card/CardGenerated";
import CardRejected from "../../pages/Card/CardRejected";
import CardRequest from "../../pages/Card/CardRequest";
import { Permission } from "../permissionStrings";
import * as PATH from "../Slug";

export const cardManagement = [
    {
        name: "কার্ড ম্যানেজমেন্ট",
        icon: Icons.card_user,
        hoverIcon: Icons.card_user_hover,
        current: false,
        href: PATH.CARD_PATH,
        hidden: false,
        permissions: [Permission.ALL],
        children: [
            {
                name: "কার্ড জেনারেট অনুরোধ",
                href: PATH.CARD_UNION_PATH,
                // icon: PencilIcon,
                current: true,
                parent: "Blogs",
                hidden: true,
                component: CardGenerate,
                permissions: [Permission.SEND_GENERATE_REQUEST_CARD],
            },
            {
                name: "অনুরোধ অনুমোদন",
                href: PATH.CARD_REQUEST_PATH,
                // icon: PencilIcon,
                current: true,
                parent: "Blogs",
                hidden: true,
                component: CardRequest,
                permissions: [Permission.APPROVE_CARD],
            },
            {
                name: "অনুমোদনকৃত অনুরোধ",
                href: PATH.CARD_APPROVED_PATH,
                // icon: PencilIcon,
                current: true,
                parent: "Blogs",
                hidden: true,
                component: CardApprove,
                permissions: [Permission.REJECT_CARD],
            },
            {
                name: "বাতিলকৃত অনুরোধ",
                href: PATH.CARD_REJECTED_PATH,
                // icon: PencilIcon,
                current: true,
                parent: "Blogs",
                hidden: true,
                component: CardRejected,
                permissions: [Permission.APPROVE_CARD],
            },
            {
                name: "কার্ড জেনারেট",
                href: PATH.CARD_GENERATE_PATH,
                // icon: PencilIcon,
                current: true,
                parent: "Blogs",
                hidden: true,
                component: CardGenerate,
                permissions: [Permission.CREATE_CARD],
            },
            {
                name: "জেনারেটেড কার্ডস",
                href: PATH.CARD_GENERATED_PATH,
                // icon: PencilIcon,
                current: true,
                parent: "Blogs",
                hidden: true,
                component: CardGenerated,
                permissions: [Permission.READ_CARD],
            },
        ],
    },
];
