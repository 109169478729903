import React, { useState } from "react";
import Icons from "../../assets/Icons";
import { numberToBangla } from "../../utils";
import Layouts from "../../layouts";
import UploadModal from "./UploadModal";
import BeneficiaryExportModal from "./BeneficiaryExportModal";
import { useUserContext } from "../../context/UserProvider";
import { useBeneficiaryContext } from "../../context/BeneficiaryProvider";

const TableHeader = ({
    selectedItems,
    setSelectedItems,
    title,
    selectedTitle,
    isDataUpEx,
    children,
    showToast,
    getAllBeneficiariesList,
    queryParams,
    isBeneficiaries = false,
}) => {
    const [uploadOpen, setUploadOpen] = useState(false);
    const [exportOpen, setExportOpen] = useState(false);
    const [selectedType, setSelectedType] = useState("selected");

    const { user } = useUserContext();
    const { getBeneficiaryExport } = useBeneficiaryContext();

    const handleExportShowModal = () => {
        setExportOpen(!exportOpen);
        setSelectedType((prev) => ({
            type: "all",
        }));
    };

    const handleSubmit = async () => {
        await getBeneficiaryExport(user?.token, selectedItems, selectedType);
        setExportOpen(false);
    };

    return (
        <div className="bg-slate-100 py-3 px-6 ">
            <div className="flex justify-between items-center flex-col sm:flex-row">
                {selectedItems.length > 0 ? (
                    <div className="flex items-center gap-x-2">
                        <div className="flex items-center gap-x-2">
                            <button onClick={() => setSelectedItems([])}>
                                <img
                                    src={Icons.cancel}
                                    alt="cancel"
                                    className="w-5 h-5"
                                />
                            </button>
                            <h3 className="text-slate-800 text-sm font-semibold mt-1">
                                {numberToBangla(selectedItems.length)}{" "}
                                {selectedTitle}
                            </h3>
                            <div className="flex items-center ml-2 gap-x-4">
                                {children}
                            </div>
                        </div>
                    </div>
                ) : (
                    <h1 className="text-slate-800 text-sm font-semibold my-2.5">
                        {title}
                    </h1>
                )}
                {isDataUpEx && (
                    <div className="flex items-center gap-x-6">
                        <button
                            className="py-2.5 px-3 flex items-center gap-x-2"
                            onClick={() => setUploadOpen(!uploadOpen)}
                        >
                            <img
                                src={Icons.upload}
                                alt="upload"
                                className="w-5 h-5"
                            />
                            <p className="mb-0 mt-1 text-sm font-bold text-green-600 leading-[18px]">
                                উপকারভোগীর ডাটা আপলোড
                            </p>
                        </button>
                        {uploadOpen && (
                            <Layouts.Modal
                                overlayVariant="light-gray"
                                setOpen={setUploadOpen}
                            >
                                <UploadModal
                                    setOpen={setUploadOpen}
                                    showToast={showToast}
                                    getAllBeneficiariesList={
                                        getAllBeneficiariesList
                                    }
                                    queryParams={queryParams}
                                />
                            </Layouts.Modal>
                        )}
                        {isBeneficiaries && (
                            <>
                                {selectedItems.length > 0 && (
                                    <button
                                        onClick={() => setExportOpen(true)}
                                        className="py-2.5 px-3 flex items-center gap-x-2"
                                    >
                                        <img
                                            src={Icons.download_green}
                                            alt="upload"
                                            className="w-5 h-5"
                                        />
                                        <p className="mb-0 mt-1 text-sm font-bold text-green-600 leading-[18px]">
                                            উপকারভোগীর ডাটা এক্সপোর্ট
                                        </p>
                                    </button>
                                )}

                                {exportOpen && (
                                    <Layouts.Modal
                                        overlayVariant="light-gray"
                                        setOpen={handleExportShowModal}
                                    >
                                        <BeneficiaryExportModal
                                            setOpen={handleExportShowModal}
                                            handleSubmit={handleSubmit}
                                            setSelectedType={setSelectedType}
                                            selectedType={selectedType}
                                        />
                                    </Layouts.Modal>
                                )}
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default TableHeader;
