import React from "react";
import Buttons from "../../components/Buttons";
import { useBeneficiaryContext } from "../../context/BeneficiaryProvider";
import { useUserContext } from "../../context/UserProvider";
import { useNavigate } from "react-router-dom";

const SendToUpazila = ({ id }) => {
    const {
        getPendingBeneficiaries,
        unionBeneficiaryParams,
        patchBeneficiaryRequestApproval,
    } = useBeneficiaryContext();
    const { user } = useUserContext();
    const navigate = useNavigate();

    const handleSendRequest = async (id) => {
        const res = await patchBeneficiaryRequestApproval(user.token, id);
        if (res === 200) {
            getPendingBeneficiaries(
                user.token,
                unionBeneficiaryParams.page,
                unionBeneficiaryParams.limit,
                unionBeneficiaryParams
            );
            navigate(-1);
        }
    };

    return (
        <Buttons.Primary
            label={"উপজেলায় প্রেরন"}
            onClick={() => {
                handleSendRequest(id);
            }}
            paddingX="px-6"
            paddingY="py-2"
            fontWeight="font-bold"
        />
    );
};

export default SendToUpazila;
