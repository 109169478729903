import axios from "axios";
import {
    BENEFICIARIES_VILLAGE_URL,
    CARD_REQUEST_URL,
    CARD_REQUEST_APPROVE_URL,
    CARD_REQUEST_REJECT_URL,
    CARD_REQUEST_APPROVED_URL,
    CARD_REQUEST_REJECTED_URL,
    CARD_GENERATE_URL,
    BENEFICIARIES_URL,
    CARD_GENERATED_URL,
    BENEFICIARIES_UPLOAD_EXCEL_URL,
    CARD_DOWNLOAD_URL,
    AWAITING_CARD_GENERATE_REQUEST_URL,
    CARD_GENERATE_REQUEST_URL,
    CARD_PREVIEW_URL,
    REGENERATE_CARD,
} from "../helpers/APIURL";

const getVillages = (token) => {
    const config = {
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
    };
    return axios.get(BENEFICIARIES_VILLAGE_URL, config);
    // return villageData;
};

const getBeneficiariesList = (token, page, limit, queryParams) => {
    const config = {
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
        params: {
            page: page,
            limit: limit,
            ...(queryParams.status ? { status: queryParams.status } : {}),
            ...(queryParams.startDate
                ? { startDate: queryParams.startDate }
                : {}),
            ...(queryParams.endDate ? { endDate: queryParams.endDate } : {}),
            ...(queryParams.union ? { union: queryParams.union } : {}),
            ...(queryParams.search ? { search: queryParams.search } : {}),
        },
    };
    return axios.get(CARD_REQUEST_URL, config);
};

const postBeneficiariesListFile = (token, file) => {
    const config = {
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
    };
    return axios.post(BENEFICIARIES_UPLOAD_EXCEL_URL, file, config);
};

const getAllBeneficiariesList = (token, page, limit, queryParams) => {
    const config = {
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
        params: {
            page: page,
            limit: limit,
            ...(queryParams.status ? { status: queryParams.status } : {}),
            ...(queryParams.startDate
                ? { startDate: queryParams.startDate }
                : {}),
            ...(queryParams.endDate ? { endDate: queryParams.endDate } : {}),
            ...(queryParams.union ? { union: queryParams.union } : {}),
            ...(queryParams.search ? { search: queryParams.search } : {}),
        },
    };
    return axios.get(BENEFICIARIES_URL, config);
};

const getBeneficiariesById = (token, id) => {
    const config = {
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
        params: {
            id: id,
        },
    };
    return axios.get(BENEFICIARIES_URL, config);
};

const patchBeneficiaryApprove = (token, id) => {
    let formattedId = id;

    if (Array.isArray(id)) {
        formattedId = id.map((id) => `id=${id}`).join("&"); // Convert array to comma-separated string
        return axios.patch(
            `${CARD_REQUEST_APPROVE_URL}?${formattedId}`,
            {},
            {
                headers: {
                    Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
                },
            }
        );
    }

    const config = {
        params: {
            id: formattedId,
        },
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
    };
    return axios.patch(CARD_REQUEST_APPROVE_URL, {}, config);
};

const patchBeneficiaryReject = (token, id) => {
    let formattedId = id;

    if (Array.isArray(id)) {
        formattedId = id.map((id) => `id=${id}`).join("&"); // Convert array to comma-separated string
        return axios.patch(
            `${CARD_REQUEST_REJECT_URL}?${formattedId}`,
            {},
            {
                headers: {
                    Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
                },
            }
        );
    }

    const config = {
        params: {
            id: id,
        },
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
    };
    return axios.patch(CARD_REQUEST_REJECT_URL, {}, config);
};

//--------card approve part--------------//

const getBeneficiariesListApprove = (token, page, limit, queryParams) => {
    const config = {
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
        params: {
            page: page,
            limit: limit,
            ...(queryParams.status ? { status: queryParams.status } : {}),
            ...(queryParams.startDate
                ? { startDate: queryParams.startDate }
                : {}),
            ...(queryParams.endDate ? { endDate: queryParams.endDate } : {}),
            ...(queryParams.union ? { union: queryParams.union } : {}),
            ...(queryParams.search ? { search: queryParams.search } : {}),
        },
    };
    return axios.get(CARD_REQUEST_APPROVED_URL, config);
};

const getBeneficiariesApproveById = (token, id) => {
    const config = {
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
        params: {
            id: id,
        },
    };
    return axios.get(CARD_REQUEST_APPROVED_URL, config);
};

//--------card rejected part--------------//

const getBeneficiariesListRejected = (token, page, limit, queryParams) => {
    const config = {
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
        params: {
            page: page,
            limit: limit,
            ...(queryParams.status ? { status: queryParams.status } : {}),
            ...(queryParams.startDate
                ? { startDate: queryParams.startDate }
                : {}),
            ...(queryParams.endDate ? { endDate: queryParams.endDate } : {}),
            ...(queryParams.union ? { union: queryParams.union } : {}),
            ...(queryParams.search ? { search: queryParams.search } : {}),
        },
    };
    return axios.get(CARD_REQUEST_REJECTED_URL, config);
};

//--------card generate part--------------//

const postCardsForGenerate = (token, id) => {
    // let formattedId = id;
    let ids = [];
    if (Array.isArray(id)) {
        // formattedId = id.map((id) => `id=${id}`).join("&"); // Convert array to comma-separated string
        // return axios.post(
        //     `${CARD_GENERATE_URL}?${formattedId}`,
        //     {},
        //     {
        //         headers: {
        //             Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        //         },
        //     }
        // );
        ids = [...id];
    } else {
        ids = [id];
    }

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
    };
    return axios.post(`${CARD_GENERATE_URL}`, { ids }, config);
};

//--------card generated part--------------//

const getGeneratedCards = (token, page, limit, queryParams) => {
    const config = {
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
        params: {
            page: page,
            limit: limit,
            ...(queryParams.status ? { status: queryParams.status } : {}),
            ...(queryParams.startDate
                ? { startDate: queryParams.startDate }
                : {}),
            ...(queryParams.endDate ? { endDate: queryParams.endDate } : {}),
            ...(queryParams.union ? { union: queryParams.union } : {}),
            ...(queryParams.search ? { search: queryParams.search } : {}),
        },
    };
    return axios.get(CARD_GENERATED_URL, config);
};

const getAwaitingGenerateCardRequest = (token, page, limit, queryParams) => {
    const config = {
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
        params: {
            page: page,
            limit: limit,
            ...(queryParams.status ? { status: queryParams.status } : {}),
            ...(queryParams.startDate
                ? { startDate: queryParams.startDate }
                : {}),
            ...(queryParams.union ? { union: queryParams.union } : {}),
            ...(queryParams.search ? { search: queryParams.search } : {}),
        },
    };
    return axios.get(AWAITING_CARD_GENERATE_REQUEST_URL, config);
};

const patchCardGenerateRequest = (token, id) => {
    let formattedId = id;

    if (Array.isArray(id)) {
        formattedId = id.map((id) => `id=${id}`).join("&"); // Convert array to comma-separated string
        return axios.patch(
            `${CARD_GENERATE_REQUEST_URL}?${formattedId}`,
            {},
            {
                headers: {
                    Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
                },
            }
        );
    }
    const config = {
        params: {
            id: formattedId,
        },
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
    };
    return axios.patch(CARD_GENERATE_REQUEST_URL, {}, config);
};

const getDownloadedCard = (token, number) => {
    // console.log("number", number);
    let formattedId = number;
    if (Array.isArray(number)) {
        formattedId = number.map((number) => `number=${number}`).join("&"); // Convert array to comma-separated string
        return axios.get(`${CARD_DOWNLOAD_URL}?${formattedId}`, {
            responseType: "arraybuffer",
            headers: {
                Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
                "Content-Type": "application/zip",
            },
        });
    }

    const config = {
        params: {
            number: formattedId,
        },
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
    };
    return axios.get(CARD_DOWNLOAD_URL, config);
};

const regenerateCard = (ids, token) => {
    // let beneficiariesId = ids.map((number) => `id=${number}`).join("&");

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
    };

    return axios.post(`${REGENERATE_CARD}`, { ids: [...ids] }, config);
};

const getCardPreview = ({ number, token }) => {
    const config = {
        params: {
            number,
        },
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
    };
    return axios.get(CARD_PREVIEW_URL, config);
};

const CardService = {
    getVillages,
    getBeneficiariesList,
    getBeneficiariesById,
    patchBeneficiaryApprove,
    patchBeneficiaryReject,
    getBeneficiariesListApprove,
    getBeneficiariesApproveById,
    getBeneficiariesListRejected,
    postCardsForGenerate,
    getAllBeneficiariesList,
    getGeneratedCards,
    postBeneficiariesListFile,
    getDownloadedCard,
    getAwaitingGenerateCardRequest,
    patchCardGenerateRequest,
    getCardPreview,
    regenerateCard,
};

export default CardService;
