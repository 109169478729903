import { banglaToEnglish } from "../libs/common";
import ImageUploaderService from "../services/imageUploaderService";

export const checkPathnameWithRoute = (path, routePath, parent) => {
    const pathArray = path.split("/");

    if (parent) {
        if (
            pathArray.includes(
                parent.split("/").filter((item) => item !== "")[0]
            ) &&
            pathArray.includes(
                routePath.split("/").filter((item) => item !== "")[0]
            )
        )
            return true;
        else return false;
    } else {
        if (
            pathArray.includes(
                routePath.split("/").filter((item) => item !== "")[0]
            )
        )
            return true;
        else return false;
    }
};

export const hasPermission = (loggedInUserPermissions, permissions) => {
    if (!permissions) {
        return false;
    }

    if (permissions.includes("ALL")) {
        return true;
    }

    if (!loggedInUserPermissions) {
        return false;
    }

    let missingPermission = [];

    for (const permission of permissions) {
        if (!loggedInUserPermissions.includes(permission)) {
            missingPermission.push(permission);
        }
    }

    return missingPermission.length === 0 ? true : false;
};

/**
 * Converts DD/MM/YYYY to MM/DD/YYYY
 *
 * @param {String} dateString
 * @returns {String} Formatted date string
 */
export var dateDBStringToDate = (dateString) => {
    const splittedString = dateString.split("/");

    return `${splittedString[1]}/${splittedString[0]}/${splittedString[2]}`;
};

/**
 * Check if provided date matches required pattern
 *
 * @param {String} dob
 * @returns {Boolean}
 */
export const checkDobFormat = (dob) => {
    // let pattern = /^(\d{1,2}\-\d{1,2}\-\d{4})$/;
    let pattern = /^(\d{1,2}\/\d{1,2}\/\d{4})$/;

    return pattern.test(dob);
};

/**
 * Converts Date or Date string to DD/MM/YYYY or YYYY-MM-DD format on demand
 *
 * @param {Date} date The date to be formatted
 * @param {Boolean} isFilter Flag to determine if the date is for a filter
 * @returns {String} Formatted date string
 */
export const dateFormatter = (date, isFilter = true) => {
    const parsedDate = new Date(date);

    // Get year, month, and day components
    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
    const day = String(parsedDate.getDate()).padStart(2, "0");

    // Format is chosen based on isFilter
    const formattedDate = isFilter
        ? `${year}-${month}-${day}`
        : `${day}/${month}/${year}`;

    return formattedDate;
};

export const numberToBangla = (number) => {
    const banglaNumerals = ["০", "১", "২", "৩", "৪", "৫", "৬", "৭", "৮", "৯"];
    const numString = number.toString();
    let banglaNumber = "";

    for (let i = 0; i < numString.length; i++) {
        const digit = parseInt(numString[i]);
        if (!isNaN(digit)) {
            banglaNumber += banglaNumerals[digit];
        } else {
            // If the character is not a digit, leave it unchanged
            banglaNumber += numString[i];
        }
    }

    return banglaNumber;
};

// export const generateQueryParamsArray = (value) => {
//     const arrayAsString = value.join(",");
// };

export const translateBNToEN = (value) => {
    const data = banglaToEnglish[value];
    if (data) {
        return data;
    } else {
        return value;
    }
};

export const translateENToBN = (value) => {
    for (const key in banglaToEnglish) {
        if (banglaToEnglish[key] === value) {
            return key;
        }
    }
    return value;
};

export const removeEmptyProperties = (obj) => {
    for (const key in obj) {
        if (
            obj.hasOwnProperty(key) &&
            (obj[key] === null || obj[key] === undefined || obj[key] === "")
        ) {
            delete obj[key];
        }
    }
    return obj;
};

export const generatePageTitle = (path) => {
    const pathArray = path.split("/");
    const title = pathArray[1];
    // console.log(title, "path");
    const titleBN = translateENToBN(title);
    return titleBN;
};

export const getImage = async (token, imageLink) => {
    try {
        const res = await ImageUploaderService.getImage(token, imageLink);
        if (res) {
            return res.data.url;
        }
    } catch (e) {
        // console.log(e);
    }
};

// this function select a range of years.
export const range = (start, end, step = 1) => {
    const result = [];
    if (step === 0) {
        // Prevent infinite loop
        throw new Error("Step cannot be zero.");
    }
    if (step > 0) {
        for (let i = start; i < end; i += step) {
            result.push(i);
        }
    } else {
        for (let i = start; i > end; i += step) {
            result.push(i);
        }
    }
    return result;
};
