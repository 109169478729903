"use client";
import { saveAs } from "file-saver";
import React, { createContext, useCallback, useContext, useState } from "react";
import { CARD_DOWNLOAD_URL } from "../helpers/APIURL";
import { withToast } from "../hoc/withToast";
import CardService from "../services/CardService";
import { useUserContext } from "./UserProvider";

const CardContext = createContext({});
export const useCardContext = () => {
    return useContext(CardContext);
};
const CardProvider = ({ showToast, children }) => {
    const { setLoader } = useUserContext();
    const [villages, setVillages] = useState([]);
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [allBeneficiaries, setAllBeneficiaries] = useState([]);
    const [cardApproveBeneficiaries, setCardApproveBeneficiaries] = useState(
        []
    );
    const [generatedCards, setGeneratedCards] = useState([]);
    const [cardRejectedBeneficiaries, setCardRejectedBeneficiaries] = useState(
        []
    );
    const [allBeneficiariesTotal, setAllBeneficiariesTotal] = useState(null);
    const [totalPages, setTotalPage] = useState(null);
    const [approveBeneficiariesTotalPage, setApproveBeneficiariesTotalPage] =
        useState(null);
    const [rejectedBeneficiariesTotalPage, setRejectedBeneficiariesTotalPage] =
        useState(null);
    const [generatedCardstotalPages, setGeneratedCardstotalPages] =
        useState(null);
    const [singleBeneficiaries, setSingleBeneficiaries] = useState({});

    const [cardPreviewImage, setCardPreviewImage] = useState(null);

    const [requestParams, setRequestParams] = useState({
        page: 1,
        limit: 10,
        startDate: null,
        endDate: null,
        status: null,
        union: null,
        search: null,
    });
    const [rejectedParams, setRejectedParams] = useState({
        page: 1,
        limit: 10,
        startDate: null,
        endDate: null,
        status: null,
        union: null,
        search: null,
    });
    const [generatedParams, setGeneratedParams] = useState({
        page: 1,
        limit: 10,
        startDate: null,
        endDate: null,
        status: null,
        union: null,
        search: null,
    });
    const [generateParams, setGenerateParams] = useState({
        page: 1,
        limit: 10,
        startDate: null,
        endDate: null,
        status: null,
        union: null,
        search: null,
    });
    const [approveParams, setApproveParams] = useState({
        page: 1,
        limit: 10,
        startDate: null,
        endDate: null,
        status: null,
        union: null,
        search: null,
    });

    const [generateRequestParams, setGenerateRequestParams] = useState({
        page: 1,
        limit: 10,
        startDate: null,
        endDate: null,
        status: null,
        union: null,
        search: null,
    });

    const [awaitingGenerateCardRequests, setAwaitingGenerateCardRequest] =
        useState([]);
    const [
        awaitingGenerateCardRequestsTotalPages,
        setAwaitingGenerateCardRequestsTotalPages,
    ] = useState(null);

    // const { user } = useUserContext();

    const getVillageData = React.useCallback(
        async (token) => {
            try {
                setLoader(true);
                const res = await CardService.getVillages(token);
                setVillages(res.data.values);
                setLoader(false);
            } catch (error) {
                setLoader(false);
            }
        },
        [setLoader]
    );

    //--------card beneficiaries part--------------//

    const getAllBeneficiariesList = React.useCallback(
        async (token, page, limit, queryParams) => {
            try {
                setLoader(true);
                const res = await CardService.getAllBeneficiariesList(
                    token,
                    page,
                    limit,
                    queryParams
                );

                setAllBeneficiaries(res.data.beneficiaries);
                setAllBeneficiariesTotal(res.data.totalPages);
                setLoader(false);
            } catch (error) {
                setLoader(false);
                showToast(error.response.data.message, true);
                setAllBeneficiaries([]);
                if (error.response.status === 404) {
                    setAllBeneficiaries([]);
                }
                // console.log(error);
            }
        },
        [setLoader, showToast]
    );

    const postBeneficiariesListFile = async (token, file) => {
        try {
            setLoader(true);
            const res = await CardService.postBeneficiariesListFile(
                token,
                file
            );
            if (res.status) {
                setLoader(false);
                return { status: res.status, message: res.data.message };
            }
        } catch (error) {
            setLoader(false);
            if (error.response.status === 404) {
                setAllBeneficiaries([]);
            }
        }
    };

    //--------card request part--------------//

    const getBeneficiaries = useCallback(
        async (token, page, limit, queryParams) => {
            try {
                setLoader(true);
                const res = await CardService.getBeneficiariesList(
                    token,
                    page,
                    limit,
                    queryParams
                );
                setBeneficiaries(res.data.beneficiaries);
                setTotalPage(res.data.totalPages);
                setLoader(false);
            } catch (error) {
                setBeneficiaries([]);
                setLoader(false);
                showToast(error.response.data.message, true);
            }
        },
        [setLoader, showToast]
    );

    const getBeneficiariesById = useCallback(
        async (token, id) => {
            try {
                setLoader(true);
                const res = await CardService.getBeneficiariesById(token, id);
                // setSingleBeneficiaries(res.data.beneficiary);
                if (res) {
                    setLoader(false);
                    return res.data.beneficiary;
                }
            } catch (error) {
                setLoader(false);
            }
        },
        [setLoader]
    );

    const patchBeneficiaryApprove = async (token, id) => {
        try {
            setLoader(true);
            const res = await CardService.patchBeneficiaryApprove(token, id);
            if (res.status) {
                setLoader(false);
                return res.status;
            }
        } catch (error) {
            setLoader(false);
        }
    };

    const patchBeneficiaryReject = async (token, id) => {
        try {
            setLoader(true);
            const res = await CardService.patchBeneficiaryReject(token, id);
            if (res.status) {
                setLoader(false);
                return res.status;
            }
        } catch (error) {
            setLoader(false);
        }
    };

    //--------card approve part--------------//

    const getBeneficiariesListApprove = useCallback(
        async (token, page, limit, queryParams) => {
            try {
                setLoader(true);
                const res = await CardService.getBeneficiariesListApprove(
                    token,
                    page,
                    limit,
                    queryParams
                );
                setCardApproveBeneficiaries(res.data.beneficiaries);
                setApproveBeneficiariesTotalPage(res.data.totalPages);
                setLoader(false);
            } catch (error) {
                setLoader(false);
                if (error.response.status === 404) {
                    setCardApproveBeneficiaries([]);
                }
                showToast(error.response.data.message, true);
            }
        },
        [setLoader, showToast]
    );

    const getBeneficiariesApproveById = async (token, id) => {
        try {
            setLoader(true);
            const res = await CardService.getBeneficiariesApproveById(
                token,
                id
            );
            setSingleBeneficiaries(res.data.beneficiary);
            setLoader(false);
        } catch (error) {
            setLoader(false);
        }
    };

    //--------card rejected part--------------//

    const getBeneficiariesListRejected = useCallback(
        async (token, page, limit, queryParams) => {
            try {
                setLoader(true);
                const res = await CardService.getBeneficiariesListRejected(
                    token,
                    page,
                    limit,
                    queryParams
                );
                setCardRejectedBeneficiaries(res.data.beneficiaries);
                setRejectedBeneficiariesTotalPage(res.data.totalPages);
                setLoader(false);
            } catch (error) {
                setLoader(false);
                if (error.response.status === 404) {
                    setCardRejectedBeneficiaries([]);
                }
                showToast(error.response.data.message, true);
            }
        },
        [setLoader, showToast]
    );

    //--------card generate part--------------//

    const postCardsForGenerate = async (token, id) => {
        try {
            setLoader(true);
            const res = await CardService.postCardsForGenerate(token, id);
            if (res.status) {
                setLoader(false);
                return res.status;
            }
        } catch (error) {
            setLoader(false);
        }
    };

    const getCardPreview = useCallback(
        async (token, id) => {
            try {
                setLoader(true);
                const res = await CardService.getCardPreview({
                    token,
                    number: id,
                });
                setCardPreviewImage(res.data.url);
                if (res.status) {
                    setLoader(false);
                    return res.status;
                }
            } catch (error) {
                setLoader(false);
            }
        },
        [setLoader]
    );

    const getGeneratedCards = useCallback(
        async (token, page, limit, queryParams) => {
            try {
                setLoader(true);
                const res = await CardService.getGeneratedCards(
                    token,
                    page,
                    limit,
                    queryParams
                );
                setGeneratedCards(res.data.cards);
                setGeneratedCardstotalPages(res.data.totalPages);
                setLoader(false);
            } catch (error) {
                setLoader(false);
                if (error.response.status === 404) {
                    setGeneratedCards([]);
                }
                showToast(error.response.data.message, true);
            }
        },
        [setLoader, showToast]
    );

    const getAwaitingGenerateCardRequest = async (
        token,
        page,
        limit,
        queryParams
    ) => {
        try {
            setLoader(true);
            const res = await CardService.getAwaitingGenerateCardRequest(
                token,
                page,
                limit,
                queryParams
            );
            setAwaitingGenerateCardRequest(res.data.cards);
            setAwaitingGenerateCardRequestsTotalPages(res.data.totalPages);
            setLoader(false);
        } catch (error) {
            setLoader(false);
            showToast(error.response.data.message, true);
        }
    };

    const patchCardGenerateRequest = async (token, id) => {
        try {
            setLoader(true);
            const res = await CardService.patchCardGenerateRequest(token, id);
            if (res.status) {
                setLoader(false);
                showToast(res.data.message);
                return res.status;
            }
        } catch (error) {
            setLoader(false);
        }
    };

    const getDownloadedCard = async (token, number, type) => {
        // const response = await CardService.getDownloadedCard(token, number);
        // Create a Blob URL for the blob data
        // let blob = new Blob([response.data]);
        let url = `${CARD_DOWNLOAD_URL}`;
        let bodyStructure;

        if (Array.isArray(number)) {
            bodyStructure = [...number];
        } else {
            bodyStructure = [number];
        }

        const fileName = "id_cards.zip";
        // ES7
        try {
            setLoader(true);
            const res = await fetch(url, {
                method: "POST",
                body:
                    type === "all"
                        ? JSON.stringify({
                              all: true,
                          })
                        : JSON.stringify({
                              numbers: bodyStructure,
                          }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            });
            const blob = await res.blob();
            saveAs(blob, fileName);
            setLoader(false);
        } catch (error) {
            setLoader(false);
        }

        // // ES6
        // fetch(url)
        //     .then((res) => res.blob())
        //     .then((blob) => saveAs(blob, fileName));

        // // ES5
        // fetch(url)
        //     .then(function (res) {
        //         return res.blob();
        //     })
        //     .then(function (blob) {
        //         saveAs(blob, fileName);
        //     });
    };

    const regenerateCards = async (ids, token) => {
        try {
            setLoader(true);
            const res = await CardService.regenerateCard(ids, token);
            if (res.status === 200) {
                setLoader(false);
                showToast(res.data.message);
                return res.status;
            }
        } catch (error) {
            setLoader(false);
        }
    };

    return (
        <CardContext.Provider
            value={{
                getVillageData,
                villages,
                getBeneficiaries,
                beneficiaries,
                totalPages,
                getBeneficiariesById,
                singleBeneficiaries,
                setSingleBeneficiaries,
                requestParams,
                setRequestParams,
                patchBeneficiaryApprove,
                patchBeneficiaryReject,
                cardApproveBeneficiaries,
                getBeneficiariesListApprove,
                approveBeneficiariesTotalPage,
                getBeneficiariesApproveById,
                getBeneficiariesListRejected,
                cardRejectedBeneficiaries,
                rejectedBeneficiariesTotalPage,
                postCardsForGenerate,
                allBeneficiaries,
                allBeneficiariesTotal,
                getAllBeneficiariesList,
                getGeneratedCards,
                generatedCards,
                generatedCardstotalPages,
                postBeneficiariesListFile,
                getDownloadedCard,
                rejectedParams,
                setRejectedParams,
                generatedParams,
                setGeneratedParams,
                generateParams,
                setGenerateParams,
                approveParams,
                setApproveParams,
                setLoader,
                getAwaitingGenerateCardRequest,
                awaitingGenerateCardRequests,
                setAwaitingGenerateCardRequest,
                awaitingGenerateCardRequestsTotalPages,
                setAwaitingGenerateCardRequestsTotalPages,
                patchCardGenerateRequest,
                setGenerateRequestParams,
                generateRequestParams,
                getCardPreview,
                cardPreviewImage,
                setCardPreviewImage,
                regenerateCards,
            }}
        >
            {children}
        </CardContext.Provider>
    );
};

export default withToast(CardProvider);
