import React from "react";
import TableHeader from "../../components/Common/TableHeader";
import Buttons from "../../components/Buttons";
import Table from "../../components/Table";
import TableFooter from "../../components/Common/TableFooter";

import { useREportContext } from "../../context/ReportPRovider";
import { useUserContext } from "../../context/UserProvider";
import { useBeneficiaryContext } from "../../context/BeneficiaryProvider";

const GeneratedReports = () => {
    const { user } = useUserContext();
    const {
        generatedReportsParams,
        totalGeneratedReports,
        setGeneratedReportsParams,
        getAllGeneratedReportsList,
        generatedFileList,
    } = useREportContext();
    const { getDownloadFiles } = useBeneficiaryContext();

    const [restructureGeneratedList, setRestructureGeneratedList] =
        React.useState([]);

    React.useEffect(() => {
        !!getAllGeneratedReportsList &&
            getAllGeneratedReportsList(
                user.token,
                generatedReportsParams.page,
                generatedReportsParams.limit
            );
    }, [generatedReportsParams, getAllGeneratedReportsList, user.token]);

    const transformedArray = React.useCallback(
        () =>
            generatedFileList?.map((item) => ({
                _id: item._id,
                file: item.file,
                newFileName: item.file.split("/").pop(), // Extract the filename
                createdAt: new Date(item.createdAt).toLocaleDateString("en-US"), // Reformat the date
                createdBy: item.createdBy,
            })),
        [generatedFileList]
    );

    React.useEffect(() => {
        setRestructureGeneratedList(transformedArray());
    }, [generatedFileList, transformedArray]);

    const handleDownload = (fileLink) => {
        getDownloadFiles(user.token, fileLink);
    };

    const renderActionComponents = (data) => {
        return (
            <>
                <td className="py-3 pl-4 mx-auto flex flex-row items-center justify-center w-full  ">
                    <div>
                        <Buttons.Primary
                            label={"ডাউনলোড"}
                            onClick={() => {
                                handleDownload(data.file);
                            }}
                            paddingX="px-7"
                            paddingY="py-2"
                            fontWeight="font-bold"
                        />
                    </div>
                </td>
            </>
        );
    };

    return (
        <div className="py-12 px-10 flex items-center justify-start flex-col shadow-medium-gray rounded-lg">
            <div className="shadow-medium-gray rounded-lg  overflow-hidden w-[350px] sm:w-[640px] md:w-[550] lg:w-[700px] xl:w-auto">
                <TableHeader
                    selectedItems={[]}
                    title={"সকল তৈরিকৃত ফাইলের লিস্ট"}
                >
                    <>
                        <Buttons.Primary
                            label={"অনুমোদন"}
                            onClick={() => {
                                // handleApprove(selectedItems);
                            }}
                            paddingX="px-7"
                            paddingY="py-2"
                            fontWeight="font-bold"
                        />
                    </>
                </TableHeader>
                <Table.Primary
                    // tableHeads={["উপকারভোগীর নাম", "পরিচয় পত্র নং", "ঠিকানা", "মোবাইল"]}
                    tableHeads={tableHeadsData}
                    // tableDataKeys={["name", "nid", "village", "mobile"]}
                    tableDataKeys={tableDataKeys}
                    // tableActionHeads={["কার্ড জেনারেট অনুরোধ অনুমোদন", ""]}
                    tableActionHeads={tableActionHeads}
                    tableData={restructureGeneratedList}
                    tableActionComponents={renderActionComponents}
                    selectedItems={[]}
                    isCheckbox={false}
                />
                <TableFooter
                    params={generatedReportsParams}
                    setParams={setGeneratedReportsParams}
                    pageSize={totalGeneratedReports}
                />
            </div>
        </div>
    );
};

export default GeneratedReports;

const tableHeadsData = [
    {
        title: "ফাইলের নাম ",
        align: "left",
        width: "w-[300px]",
    },
    {
        title: "ফাইল তৈরিকারির নাম",
        align: "left",
        width: "w-[300px]",
    },
    {
        title: "সময়",
        align: "left",
        width: "w-[150px]",
    },
];

const tableDataKeys = [
    {
        title: "newFileName",
        width: "w-[158px]",
    },
    {
        title: "createdBy",
        width: "w-[158px]",
    },
    {
        title: "createdAt",
        width: "w-[200px]",
    },
];

const tableActionHeads = [
    {
        title: "ডাউনলোড",
        align: "left",
        width: "w-[210px]",
    },
];
