import React, { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import bn from "date-fns/locale/bn";
import Icons from "../../assets/Icons";
import { dateFormatter } from "../../utils";

const LegendDateField = ({
    setQueryParams,
    legendTitle,
    startDate,
    futureDateSelectable = false,
}) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const inputRef = useRef(null);
    const today = new Date();
    const maxSelectableDate = today;

    const customLocale = {
        ...bn, // Include the base Bengali locale
        options: { ...bn.options, weekStartsOn: 0 },
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        const formatDate = dateFormatter(date);

        setQueryParams((prev) => {
            let updatedStartDate = prev.startDate;
            let updatedEndDate = prev.endDate;

            if (!startDate) {
                updatedEndDate = formatDate;
                // if (prev.startDate && prev.startDate !== formatDate) {
                //     updatedEndDate = formatDate;
                // } else {
                //     updatedEndDate = null;
                // }
            } else {
                updatedStartDate = formatDate;

                // if (prev.endDate === formatDate) {
                //     updatedEndDate = null;
                // }
                // updatedStartDate = formatDate;
            }

            return {
                ...prev,
                startDate: updatedStartDate,
                endDate: updatedEndDate,
                page: 1,
                limit: 10,
            };
            // return { ...prev, startDate: startDate ? formatDate : prev.startDate, endDate: startDate === false ? formatDate : prev.endDate, page: 1, limit: 10 };
        });
    };
    const handleInputChange = () => {
        if (inputRef) {
            inputRef.current.setOpen(true);
        }
    };

    const clearDate = () => {
        setSelectedDate(null);
        if (startDate) {
            setQueryParams((prev) => ({
                ...prev,
                startDate: null,
                page: 1,
                limit: 10,
            }));
        } else {
            setQueryParams((prev) => ({
                ...prev,
                endDate: null,
                page: 1,
                limit: 10,
            }));
        }
    };

    return (
        <fieldset className="border-[1.5px] border-[#919EAB] border-opacity-[32%] rounded-lg pb-4 pt-2 px-3.5 flex items-center justify-between">
            <legend className="text-[#919EAB] text-xs font-medium">
                {legendTitle}
            </legend>
            {
                <DatePicker
                    ref={inputRef}
                    selected={selectedDate}
                    onChange={(date) => handleDateChange(date)}
                    locale={customLocale}
                    placeholderText="তারিখ" // Placeholder in Bengali
                    dateFormat="dd/MM/yyyy" // Customize date format if needed
                    maxDate={futureDateSelectable ? null : maxSelectableDate}
                    className="text-base text-slate-800 leading-6 border-none outline-none w-[142px]"
                />
            }
            {selectedDate ? (
                <button onClick={clearDate}>
                    <img src={Icons.cancel} alt="cross" className="w-5 h-5" />
                </button>
            ) : (
                <button onClick={handleInputChange}>
                    <img src={Icons.date} alt="date" className="w-5 h-5" />
                </button>
            )}
        </fieldset>
    );
};

export default LegendDateField;
