import axios from "axios";
import { GENERATED_REPORTS_LIST_URL } from "../helpers/APIURL";

const getDownloadedReport = (token, url, queryParams) => {
    const config = {
        params: queryParams,

        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
    };
    return axios.get(url, config);
};

const getAllGeneratedReports = (token, page, limit) => {
    const config = {
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
        params: {
            page,
            limit,
        },
    };
    return axios.get(GENERATED_REPORTS_LIST_URL, config);
};

const ReportService = {
    getDownloadedReport,
    getAllGeneratedReports,
};

export default ReportService;
