import React from "react";
import { useCardContext } from "../../context/CardProvider";

import Icons from "../../assets/Icons";
import Buttons from "../Buttons";
import { withToast } from "../../hoc/withToast";

const GeneratedCardModal = ({
    setOpen,
    showToast,
    selectedItems,
    setSelectedItems,
    setSelectedItemsId,
    token,
}) => {
    const { regenerateCards } = useCardContext();

    // async function onSubmitButton(data) {
    //     await getDownloadedCard(token, selectedItems, data.type);
    //     setOpen(false);
    //     setSelectedItems([]);
    // }

    async function handleRegenerateCard() {
        const res = await regenerateCards(selectedItems, token);

        if (res === 200) {
            setOpen(false);
            setSelectedItems([]);
            setSelectedItemsId([]);
        }
        // console.log(selectedItems, "data");
        // setSelectedItems([]);
        // setSelectedItemsId([]);
    }

    return (
        <div className="w-[402px] rounded overflow-hidden bg-white">
            <div className="p-4 bg-blue-950 flex items-center justify-between">
                <p className="text-slate-50 text-base leading-6">
                    রিজেনারেট কার্ড
                </p>
                <button onClick={() => setOpen(false)}>
                    <img
                        src={Icons.cross_white}
                        alt="cross_white"
                        className="w-5 h-5"
                    />
                </button>
            </div>
            <div className="p-4">
                <p className="text-blue-950 text-sm font-semibold leading-[22px]">
                    আপনি কি রিজেনারেট করতে চান ?
                </p>
                <div className="mt-3 flex flex-col items-start gap-0">
                    <div className="mt-3 flex flex-row items-center justify-center gap-2"></div>
                    <div className="mt-3 flex flex-row items-center justify-center gap-2"></div>
                </div>
            </div>
            <div className="p-4 flex justify-end items-center gap-x-4">
                <Buttons.Outline
                    label={"বাতিল"}
                    onClick={() => {
                        setOpen(false);
                        setSelectedItems([]);
                        setSelectedItemsId([]);
                    }}
                    paddingX="px-6"
                    paddingY="py-2"
                    fontWeight="font-bold"
                    textColor={"text-slate-600"}
                    borderColor={"border-slate-400"}
                />
                <Buttons.Primary
                    label={"রিজেনারেট"}
                    // isDisabled={loading}
                    onClick={handleRegenerateCard}
                    paddingX="px-6"
                    paddingY="py-2"
                    fontWeight="font-bold"
                />
            </div>
        </div>
    );
};

export default withToast(GeneratedCardModal);
