import React from "react";
import DatePicker from "react-datepicker";
import { Controller } from "react-hook-form";

const DateField = ({
    defaultValue,
    placeholder,
    register,
    name,
    width = "w-full",
    errors,
    errorMsg,
    isReadOnly,
    validations,
    // Controller,
    control,
}) => {
    const maxSelectedDate = new Date();
    return (
        <Controller
            control={control}
            // as={DatePicker}
            valueName={name}
            render={({ onChange, value }) => {
                <DatePicker maxDate={maxSelectedDate} />;
            }}
        >
            {/* <input
                defaultValue={defaultValue}
                readOnly={isReadOnly ? true : false}
                type="date" // Change the input type to "date"
                {...register(name, { ...validations })}
                placeholder={placeholder}
                className={`text-base font-medium py-3.5 px-5 rounded-sm border ${width} text-slate-800 outline-blue-600 outline-1 border border-slate-300 bg-white`}
            />
            {errors[name] && (
                <span className="text-xs flex items-center mt-3 text-red-600">
                    <ExclamationCircleIcon className="w-4 h-4 mr-1.5" />{" "}
                    <span className="mt-0.5">{errorMsg?.message}</span>
                </span>
            )} */}
        </Controller>
    );
};

export default DateField;
