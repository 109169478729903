import { LOGIN_USER_URL } from "../helpers/APIURL";
import axios from "axios";

const loginUser = (data) => {
  return axios.post(LOGIN_USER_URL, data);
};

const UserService = { loginUser };

export default UserService;
