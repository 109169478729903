import { ChevronDownIcon } from "@heroicons/react/20/solid";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Icons from "../../assets/Icons";
import { useUserContext } from "../../context/UserProvider";
import sidebarMenu from "../../helpers/routes";
import { withToast } from "../../hoc/withToast";
import { checkPathnameWithRoute, hasPermission } from "../../utils";

const SideDrawer = ({ showToast }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { user, logoutUser } = useUserContext();

    const handleLogout = async () => {
        await logoutUser();
        navigate("/login");
    };

    const PANEL_NAMES = {
        "Super Admin": "সুপার",
        "Upazila Admin": "উপজেলা",
        "Union Admin": "ইউনিয়ন",
    };

    const CARD_CHILD_START = {
        "Super Admin": 1,
        "Upazila Admin": 1,
        "Union Admin": 0,
    };

    return (
        <div className="hidden lg:block fixed top-0 left-0 w-[280px] lg:h-screen bg-blue-50 z-30">
            <div className="flex flex-col justify-between h-full">
                <div>
                    <div className="py-5 px-6 bg-blue-950 flex items-center gap-x-4">
                        <img
                            src={Icons.bd_gov_logo}
                            alt="bd_gov_logo"
                            className="w-12 h-12"
                        />
                        <h1 className="text-lg font-bold text-white">
                            {`${PANEL_NAMES[user?.user?.role]} এডমিন প্যানেল`}
                        </h1>
                    </div>
                    <div className="flex flex-col">
                        {sidebarMenu.map((item, index) => (
                            <div key={index}>
                                {hasPermission(
                                    user && user.permissions,
                                    item.permissions
                                ) &&
                                    item.children.length > 0 && (
                                        <button
                                            onClick={() => {
                                                // handleActiveTab(item.name);

                                                navigate(
                                                    item.children.length > 0
                                                        ? item.href +
                                                              "/" +
                                                              `${
                                                                  item.name ===
                                                                  "কার্ড ম্যানেজমেন্ট"
                                                                      ? item
                                                                            .children[
                                                                            CARD_CHILD_START[
                                                                                user
                                                                                    ?.user
                                                                                    ?.role
                                                                            ]
                                                                        ].href
                                                                      : item
                                                                            .children[0]
                                                                            .href
                                                              }`
                                                        : item.href
                                                );
                                            }}
                                            className={`w-full px-6 py-3 flex items-center justify-between ${
                                                checkPathnameWithRoute(
                                                    location.pathname,
                                                    item.href
                                                )
                                                    ? "bg-green-100"
                                                    : ""
                                            }`}
                                        >
                                            <div className="flex items-center gap-x-2">
                                                <img
                                                    src={
                                                        checkPathnameWithRoute(
                                                            location.pathname,
                                                            item.href
                                                        )
                                                            ? item.hoverIcon
                                                            : item.icon
                                                    }
                                                    alt="beneficiary_logo"
                                                    className="w-5 h-5 mb-1"
                                                />
                                                <span
                                                    className={`text-base leading-6  ${
                                                        checkPathnameWithRoute(
                                                            location.pathname,
                                                            item.href
                                                        )
                                                            ? "text-green-700"
                                                            : "text-slate-500"
                                                    }`}
                                                >
                                                    {item.name}
                                                </span>
                                            </div>
                                            <ChevronDownIcon
                                                className={`w-5 h-5 m-1.5 ${
                                                    checkPathnameWithRoute(
                                                        location.pathname,
                                                        item.href
                                                    )
                                                        ? "rotate-180"
                                                        : "rotate-0"
                                                } ${
                                                    checkPathnameWithRoute(
                                                        location.pathname,
                                                        item.href
                                                    )
                                                        ? "text-green-700"
                                                        : "text-slate-500"
                                                }`}
                                            />
                                        </button>
                                    )}

                                {hasPermission(
                                    user && user.permissions,
                                    item.permissions
                                ) &&
                                    item.children.length === 0 && (
                                        <button
                                            onClick={() => {
                                                // handleActiveTab(item.name);

                                                navigate(
                                                    item.children.length > 0
                                                        ? item.href +
                                                              "/" +
                                                              item.children[0]
                                                                  .href
                                                        : item.href
                                                );
                                            }}
                                            className={`w-full px-6 py-3 flex items-center justify-between ${
                                                checkPathnameWithRoute(
                                                    location.pathname,
                                                    item.href
                                                )
                                                    ? "bg-green-100"
                                                    : ""
                                            }`}
                                        >
                                            <div className="flex items-center gap-x-2">
                                                <img
                                                    src={
                                                        checkPathnameWithRoute(
                                                            location.pathname,
                                                            item.href
                                                        )
                                                            ? item.hoverIcon
                                                            : item.icon
                                                    }
                                                    alt="beneficiary_logo"
                                                    className="w-5 h-5 mb-1"
                                                />
                                                <span
                                                    className={`text-base leading-6  ${
                                                        checkPathnameWithRoute(
                                                            location.pathname,
                                                            item.href
                                                        )
                                                            ? "text-green-700"
                                                            : "text-slate-500"
                                                    }`}
                                                >
                                                    {item.name}
                                                </span>
                                            </div>
                                            {/* <ChevronDownIcon
                                                className={`w-5 h-5 m-1.5 ${
                                                    checkPathnameWithRoute(
                                                        location.pathname,
                                                        item.href
                                                    )
                                                        ? "rotate-180"
                                                        : "rotate-0"
                                                } ${
                                                    checkPathnameWithRoute(
                                                        location.pathname,
                                                        item.href
                                                    )
                                                        ? "text-green-700"
                                                        : "text-slate-500"
                                                }`}
                                            /> */}
                                        </button>
                                    )}
                                {item.children.length > 0 &&
                                    checkPathnameWithRoute(
                                        location.pathname,
                                        item.href
                                    ) && (
                                        <div className="flex flex-col">
                                            {item.children.map(
                                                (subItem, index) => (
                                                    <div key={index}>
                                                        {hasPermission(
                                                            user &&
                                                                user?.permissions,
                                                            subItem.permissions
                                                        ) && (
                                                            <Link
                                                                to={
                                                                    subItem.href
                                                                }
                                                                className={`w-full pl-9 pr-3 py-3 flex items-center gap-x-4`}
                                                            >
                                                                <div
                                                                    className={` rounded-full m-2 duration-300 ${
                                                                        checkPathnameWithRoute(
                                                                            location.pathname,
                                                                            subItem.href,
                                                                            item.href
                                                                        )
                                                                            ? "w-2 h-2 bg-green-600"
                                                                            : "w-1 h-1 bg-slate-500"
                                                                    }`}
                                                                />
                                                                <span
                                                                    className={`text-sm duration-300 ${
                                                                        checkPathnameWithRoute(
                                                                            location.pathname,
                                                                            subItem.href,
                                                                            item.href
                                                                        )
                                                                            ? "text-green-600 font-semibold"
                                                                            : "text-slate-500 font-normal"
                                                                    }`}
                                                                >
                                                                    {
                                                                        subItem.name
                                                                    }
                                                                </span>
                                                            </Link>
                                                        )}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    )}
                            </div>
                        ))}
                        <button
                            className="py-3 px-6 w-full flex items-start gap-x-2 mt-2"
                            onClick={handleLogout}
                        >
                            <img
                                src={Icons.logout}
                                alt="logout"
                                className="w-5 h-5"
                            />
                            <p className="text-base text-slate-500 left-6">
                                লগ আউট
                            </p>
                        </button>
                    </div>
                </div>
                <p className="hidden lg:flex w-full justify-center items-center p-6 text-xs font-medium text-neutral-400">
                    Powered by ZAAG Systems Ltd.
                </p>
            </div>
        </div>
    );
};

export default withToast(SideDrawer);
