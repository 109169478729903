import React, { useRef, useEffect, useCallback } from "react";
import Buttons from "../Buttons";
import Icons from "../../assets/Icons";

import { useUserContext } from "../../context/UserProvider";
import { useImageContext } from "../../context/imageUploaderProvider";

import { getImage } from "../../utils";
import { withToast } from "../../hoc/withToast";
import ApproveBeneficiaries from "../../sections/BeneficiariesDetails/ApproveBeneficiaries";
import AllBeneficiariesActions from "../../sections/BeneficiariesDetails/AllBeneficiariesActions";
import ApprovedBeneficiaries from "../../sections/BeneficiariesDetails/ApprovedBeneficiaries";
import RejectedBeneficiaries from "../../sections/BeneficiariesDetails/RejectedBeneficiaries";
import SendToUpazila from "../../sections/BeneficiariesDetails/SendToUpazila";
import RequestCard from "../../sections/BeneficiariesDetails/RequestCard";
import ApprovedCard from "../../sections/BeneficiariesDetails/ApprovedCard";
import RejectedCard from "../../sections/BeneficiariesDetails/RejectedCard";
import GenerateCard from "../../sections/BeneficiariesDetails/GenerateCard";

/**
 *  Pass props for upload image (Create and Update) both of them.
 *  handleUpload function for upload.
 *  Image upload has 2 state. create state amd edit state.
 *  depending this state ui and functionality will change.
 */

const ImageUploader = ({ showToast, defaultImage, create, type, singleUser, isDetails, handleImageEmpty, selectedImage, setSelectedImage }) => {
    const { user, setUserUpdateInfo } = useUserContext();
    const { getImageUploaderResponse, setImageUploaderResponse } = useImageContext();

    const imageRef = useRef(null);

    // const [selectedImage, setSelectedImage] = React.useState({
    //     preview: "",
    //     raw: "",
    // });
    const [isHovered, setIsHovered] = React.useState(false);
    const [previewImage, setPreviewImage] = React.useState(null);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file && file.size <= 3 * 1024 * 1024) {
            setSelectedImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
            });
        } else {
            showToast("অনুগ্রহ করে 3MB এর কম এবং jpg, png, jpeg, svg ফটো নির্বাচন করুন৷", true);
        }
    };

    const handleDeleteImage = () => {
        handleImageEmpty && handleImageEmpty();
        if (getImageUploaderResponse === null) {
            setUserUpdateInfo((ps) => ({ ...ps, image: "" }));
        } else {
            setImageUploaderResponse(null);
            setSelectedImage({
                preview: "",
                raw: "",
            });
            imageRef.current.value = null;
        }
    };

    const handlePreviewImage = useCallback(async () => {
        const data = await getImage(user.token, defaultImage);
        setPreviewImage(data);
    }, [user.token, defaultImage, setPreviewImage]);

    useEffect(() => {
        if (defaultImage) {
            handlePreviewImage();
        }
    }, [defaultImage, handlePreviewImage]);

    const variants = {
        allBeneficiariesActions: <AllBeneficiariesActions data={singleUser} />,
        approveBeneficiaries: <ApproveBeneficiaries id={singleUser?._id} />,
        approvedBeneficiaries: (
            <ApprovedBeneficiaries
                data={singleUser}
                id={singleUser?._id}
            />
        ),
        rejectedBeneficiaries: (
            <RejectedBeneficiaries
                data={singleUser}
                id={singleUser?._id}
            />
        ),
        sendToUpazila: <SendToUpazila id={singleUser?._id} />,
        requestCard: <RequestCard id={singleUser?._id} />,
        approvedCard: (
            <ApprovedCard
                data={singleUser}
                id={singleUser?._id}
            />
        ),
        rejectedCard: (
            <RejectedCard
                data={singleUser}
                id={singleUser?._id}
            />
        ),
        generateCard: <GenerateCard id={singleUser?._id} />,
    };

    return (
        <div className={`p-6 ${create ? "" : "bg-slate-100"} rounded flex flex-col-reverse md:flex-row items-start md:justify-between gap-4`}>
            {isDetails ? (
                <div>
                    <img
                        src={`${previewImage ? previewImage : Icons.UserManagement}`}
                        alt="Selected Image2"
                        className="w-32 h-32 rounded-full"
                    />
                </div>
            ) : (
                <div>
                    <div className="flex flex-col w-full md:flex-row gap-5 items-center md:items-end">
                        <div className="flex flex-col gap-3 items-center">
                            <label htmlFor="upload-button">
                                <div
                                    className="relative p-2 rounded-full border border-neutral-300 border-dashed hover:cursor-pointer"
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}>
                                    {selectedImage.preview ? (
                                        <img
                                            src={selectedImage.preview}
                                            alt="Selected Image3"
                                            className="w-32 h-32 rounded-full"
                                        />
                                    ) : defaultImage !== "" ? (
                                        <>
                                            <img
                                                src={`${previewImage}`}
                                                alt=""
                                                className="w-32 h-32 rounded-full"
                                            />
                                            {(isHovered || (!defaultImage && !previewImage)) && <ImageUploaderOverlay />}
                                        </>
                                    ) : (
                                        // <CustomImage
                                        //     imageSrc={defaultImage}
                                        //     authToken={user?.token}
                                        // />
                                        <div className="w-32 h-32 rounded-full bg-neutral-100 flex flex-col justify-center items-center gap-2">
                                            <img
                                                src={Icons.photo_upload}
                                                alt="date"
                                                className="w-8 h-8 text-white"
                                            />
                                            <h1 className="text-neutral-700 text-sm">Upload photo</h1>
                                        </div>
                                    )}
                                </div>
                            </label>
                            <h1 className="text-center text-[#637381] font-normal text-sm">
                                Allowed *.jpeg, *.jpg, *.png,
                                <br /> Max size of 3.1 MB
                            </h1>
                        </div>
                        {defaultImage ? (
                            <Buttons.Outline
                                label="ডিলিট ফটো"
                                paddingX="px-7"
                                paddingY="py-3"
                                fontWeight="font-bold"
                                onClick={handleDeleteImage}
                            />
                        ) : null}
                    </div>
                    <input
                        type="file"
                        ref={imageRef}
                        accept=".jpeg, .jpg, .png"
                        id="upload-button"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                    />
                </div>
            )}
            {!create && <>{variants[type]}</>}
        </div>
    );
};

export default withToast(ImageUploader);

const ImageUploaderOverlay = () => {
    return (
        <div className="bg-slate-100 bg-opacity-50 w-full h-full rounded-full flex justify-center items-center absolute top-0 left-0 z-20">
            <img
                src={Icons.photo_upload}
                alt="upload"
                className="w-8 h-8 text-white"
            />
        </div>
    );
};
