import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import SecondaryTableHeader from "../../components/Common/SecondaryTableHeader";
import { useBeneficiaryContext } from "../../context/BeneficiaryProvider";
import { useUserContext } from "../../context/UserProvider";
import { useImageContext } from "../../context/imageUploaderProvider";
import { withToast } from "../../hoc/withToast";
import UserForm from "../../sections/UserManagement/UserForm";

const EditUser = ({ showToast }) => {
    const { id } = useParams();
    const { user, getUsersById, updateUser } = useUserContext();
    const { villages, getVillageData } = useBeneficiaryContext();
    const { imageUpload } = useImageContext();
    const navigate = useNavigate();
    const [selectedImage, setSelectedImage] = React.useState({
        preview: "",
        raw: "",
    });

    const [singleUser, setSingleUser] = useState({});

    const handleGetData = useCallback(async () => {
        if (id && user) {
            const response = await getUsersById(user.token, id);
            setSingleUser(response);
        }
    }, [getUsersById, id, user]);

    useEffect(() => {
        getVillageData(user.token);
        handleGetData();
    }, [getVillageData, user.token, id, user, handleGetData]);

    const handleImageUploader = useCallback(
        async (image) => {
            const res = await imageUpload(user.token, image);
            return res;
        },
        [imageUpload, user.token]
    );

    const handleSubmit = async (data) => {
        let payload = { ...data };

        if (selectedImage.raw) {
            try {
                const res = await handleImageUploader(selectedImage.raw);

                if (res.data.key) {
                    const uploadedImageURL = res.data.key;

                    payload = { ...data, image: uploadedImageURL };
                }
            } catch (error) {
                showToast("Image upload is failed.");
            }
        } else {
            payload = { ...data, image: singleUser.image };
        }

        try {
            const response = await updateUser(user.token, payload, id);

            if (response === 200) {
                navigate(-1);
            }

            showToast("আপডেট সম্পন্ন হয়েছে");
        } catch (error) {
            console.error(error);

            showToast("আপডেট সম্পন্ন হয়নি");
        }
    };

    const handleImageEmpty = () => {
        setSingleUser((ps) => ({ ...ps, image: "" }));
    };

    return (
        <div className="flex flex-col items-center px-10 py-p">
            <div className="rounded-lg">
                <SecondaryTableHeader title="ব্যাবহারকারীর তথ্য" />
                <UserForm
                    villages={villages}
                    singleUser={singleUser ? singleUser : undefined}
                    create={false}
                    handleImageUpload={handleImageUploader}
                    handleSubmitData={handleSubmit}
                    selectedImage={selectedImage}
                    setSelectedImage={setSelectedImage}
                    handleImageEmpty={handleImageEmpty}
                />
            </div>
        </div>
    );
};

export default withToast(EditUser);
