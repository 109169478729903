import React from "react";

const Primary = () => {
    return (
        <div className="p-6 bg-blue-950 flex items-center justify-between fixed bottom-0 left-0 w-full">
            <p className="text-white text-sm text-center w-full">
                © টিসিবি ম্যানেজমেন্ট সিস্টেম, v1.0 | Powered by ZAAG Systems
                Ltd.
            </p>
        </div>
    );
};

export default Primary;
