"use client";

import { createContext, useCallback, useContext, useState } from "react";
import { useUserContext } from "./UserProvider";

import { withToast } from "../hoc/withToast";
import ReportService from "../services/ReportService";

const ReportContext = createContext({});

export const useREportContext = () => {
    return useContext(ReportContext);
};

const ReportProvider = ({ children, showToast }) => {
    const { setLoader } = useUserContext();
    const [generatedFileList, setGeneratedFileList] = useState([]);

    const [generatedReportsParams, setGeneratedReportsParams] = useState({
        page: 1,
        limit: 10,
    });

    const [totalGeneratedReports, setTotalGeneratedReports] = useState(null);

    const getDownloadedReport = async (token, url, queryParams) => {
        try {
            setLoader(true);
            const response = await ReportService.getDownloadedReport(
                token,
                url,
                queryParams
            );

            // Create a download link
            const a = document.createElement("a");
            a.href = response.data.url;
            a.download = "report.xlsx"; // Specify the desired file name
            a.style.display = "none";

            // Simulate a click on the download link
            document.body.appendChild(a);
            a.click();

            // Cleanup
            document.body.removeChild(a);

            showToast(response.data.message);
            setLoader(false);
            return response.status;
        } catch (error) {
            showToast(error.response.data.message);
            setLoader(false);
        }
    };

    const getAllGeneratedReportsList = useCallback(
        async (token, page, limit) => {
            try {
                setLoader(true);
                const res = await ReportService.getAllGeneratedReports(
                    token,
                    page,
                    limit
                );
                if (res.status) {
                    setGeneratedFileList(res.data.reports);
                    setTotalGeneratedReports(res.data.totalPages);
                }
            } catch (e) {
                console.log(e);
            } finally {
                setLoader(false);
            }
        },
        [setLoader]
    );

    const value = {
        generatedReportsParams,
        totalGeneratedReports,
        generatedFileList,
        getAllGeneratedReportsList,
        getDownloadedReport,
        setGeneratedReportsParams,
    };

    return (
        <ReportContext.Provider value={value}>
            {children}
        </ReportContext.Provider>
    );
};

export default withToast(ReportProvider);
