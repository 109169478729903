import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import bn from "date-fns/locale/bn";
import { useParams } from "react-router-dom";

import FormFields from "../../components/FormFields";
import { Box } from "../../sections/BeneficiaryForm.jsx";
import Buttons from "../../components/Buttons";
import Icons from "../../assets/Icons";

import { useBeneficiaryContext } from "../../context/BeneficiaryProvider";
import { useUserContext } from "../../context/UserProvider";
import { useDistributionContext } from "../../context/distributionProvider";
import {
    dateDBStringToDate,
    dateFormatter,
    removeEmptyProperties,
} from "../../utils";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

const CreateDistributionSchedule = ({ isDetails = false, create }) => {
    const navigate = useNavigate();
    const [union, setUnion] = useState();
    const [dealer, setDealer] = useState();
    let { id } = useParams();

    const { user } = useUserContext();
    const { getVillageData, villages } = useBeneficiaryContext();
    const {
        getDealerList,
        dealerList,
        createDistributionSchedule,
        singleDistribution,
        getSingleDistribution,
        updateDistributionSchedule,
        handleGetAllDistributionList,
    } = useDistributionContext();

    const [selectedDate, setSeletedData] = useState(
        dateFormatter(new Date(), false)
    );

    // console.log(isDetails, "------------------");

    // If true this means the operation for create. else form operation for update;
    const handleIsCreate = useCallback(() => {
        if (id === undefined) return true;
        else return false;
    }, [id]);

    useEffect(() => {
        if (!handleIsCreate()) {
            getSingleDistribution(user.token, id);
        } else if (!id) {
            // console.log("hello", selectedDate);
            // setSeletedData(format(new Date(), "dd/MM/yyyy"));
            // setSeletedData(null);
        }
    }, [getSingleDistribution, handleIsCreate, id, user.token]);

    const {
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    useEffect(() => {
        getVillageData(user.token);
        getDealerList(user.token);
    }, [getDealerList, getVillageData, user.token]);

    const handleUnion = (value) => {
        setUnion(value);
    };

    const handleFormatDealerList = () => {
        return dealerList?.map((obj) => obj.name);
    };

    const handleSelectDealer = (value) => {
        const filteredObject = dealerList.find((obj) => obj.name === value);
        if (filteredObject) {
            const { _id } = filteredObject;
            setDealer(_id);
            return _id;
        }
        return;
    };

    // console.log(handleIsCreate());
    // const handleFormatDate = (date) => {
    //     const parsedDate = new Date(date);

    //     // Get year, month, and day components
    //     const year = parsedDate.getFullYear();
    //     const month = String(parsedDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
    //     const day = String(parsedDate.getDate()).padStart(2, "0");

    //     // Create the desired format (DD/MM/YYYY)
    //     const formattedDate = `${day}/${month}/${year}`;
    //     return formattedDate;
    // };

    const onSubmit = async (data) => {
        // console.log(selectedDate, "<---------------date");

        data.dealerId = dealer;
        data.union = union;

        if (handleIsCreate()) {
            data.date = selectedDate;
            const res = await createDistributionSchedule(
                user.token,
                removeEmptyProperties(data)
            );
            reset();
            if (res) {
                handleGetAllDistributionList(user.token, 1, 10, {
                    page: 1,
                    limit: 10,
                    startDate: null,
                    status: null,
                    union: null,
                    search: null,
                });
                navigate(-1);
            }
        } else if (!isDetails) {
            // console.log("duckeche");
            if (singleDistribution.date !== selectedDate) {
                data.date = selectedDate;
            }
            // console.log(selectedDate, "----");
            // console.log("heeloo", removeEmptyProperties(data));
            // for (const key in data) {
            //     if (data[key] === undefined || "NaN/NaN/NaN") {
            //         delete data[key];
            //     }
            // }
            const res = await updateDistributionSchedule(
                user.token,
                id,
                removeEmptyProperties(data)
            );
            if (res) {
                handleGetAllDistributionList(user.token, 1, 10, {
                    page: 1,
                    limit: 10,
                    startDate: null,
                    status: null,
                    union: null,
                    search: null,
                });
                navigate("/distribution-schedule/list");
            }
        } else {
            navigate(`/distribution-schedule/update/${id}`);
        }
    };

    useEffect(() => {
        if (singleDistribution.date && !handleIsCreate()) {
            setSeletedData(singleDistribution.date);
        }
    }, [singleDistribution, handleIsCreate]);

    const customLocale = {
        ...bn, // Include the base Bengali locale
        options: { ...bn.options, weekStartsOn: 0 },
    };

    // const handleDateChange = (date) => {
    //     const newdate = dateFormatter(date, false);
    //     setSeletedData(newdate);
    // };

    return (
        <div className="rounded-lg overflow-hidden bg-white shadow-light-gray  mx-auto mt-10 h-[70vh] w-[95%] sm:w-[98%] md:w-[80%]">
            <div className="py-4 px-6 bg-blue-100">
                <p className="text-base text-blue-950">
                    বিতরণ সময়সূচীর বিস্তারিত
                </p>
            </div>
            {/* --------Create Form ----------- */}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col p-8 gap-8 items-end">
                    <div className="flex flex-col sm:flex-col md:flex-row items-center justify-between w-full mt-5">
                        <Box>
                            <h6 className="text-slate-800 font-medium text-base mb-2">
                                ডিলার সিলেক্ট করুন
                            </h6>
                            <FormFields.SelectField
                                data={
                                    dealerList ? handleFormatDealerList() : []
                                }
                                readOnly={isDetails}
                                placeholder="ডিলার সিলেক্ট করুন"
                                defaultValue={
                                    handleIsCreate()
                                        ? ""
                                        : singleDistribution?.dealerName
                                }
                                handleSelectClick={handleSelectDealer}
                                // errors={errors}
                                name="union"
                                width="w-full"
                                paddingY="py-3.5"
                                paddingX="px-5"
                                border="border border-slate-300"
                                radius="rounded"
                                overlayZIndex={10000}
                                dropdownPosition="top-14"
                            />
                        </Box>
                        <Box>
                            <h6 className="text-slate-800 font-medium text-base mb-2">
                                ইউনিয়ন সিলেক্ট করুন
                            </h6>
                            <FormFields.SelectField
                                data={villages ? villages : []}
                                placeholder="ইউনিয়ন সিলেক্ট করুন"
                                readOnly={isDetails}
                                defaultValue={
                                    handleIsCreate()
                                        ? ""
                                        : singleDistribution?.union
                                }
                                handleSelectClick={handleUnion}
                                // errors={errors}
                                name="union"
                                width="w-full"
                                paddingY="py-3.5"
                                paddingX="px-5"
                                border="border border-slate-300"
                                radius="rounded"
                                overlayZIndex={10000}
                                // isReadOnly={
                                //     create ? !create : getEdit ? !getEdit : true
                                // }
                                dropdownPosition="top-14"
                            />
                        </Box>

                        <Box>
                            <h6 className="text-slate-800 font-medium text-base mb-2 ">
                                বিতরণের তারিখ
                            </h6>

                            <fieldset className="border-[1.5px] border-[#919EAB] border-opacity-[32%] rounded-sm pb-[.8rem] pt-4 px-3.5 flex items-center">
                                <DatePicker
                                    locale={customLocale}
                                    placeholderText="তারিখ" // Placeholder in Bengali
                                    dateFormat="dd/MM/yyyy" // Customize date format if needed
                                    // maxDate={maxSelectedDate}
                                    readOnly={isDetails}
                                    className="text-base text-slate-800 leading-6 border-none outline-none w-[142px]"
                                    onChange={(date) =>
                                        setSeletedData(
                                            dateFormatter(date, false)
                                        )
                                    }
                                    selected={
                                        selectedDate &&
                                        new Date(
                                            dateDBStringToDate(selectedDate)
                                        )
                                    }
                                />
                            </fieldset>
                            <span
                                className={`text-xs flex items-center mt-1 text-red-600 ${
                                    errors.dob ? "visible" : "invisible"
                                }`}
                            >
                                <ExclamationCircleIcon className="w-4 h-4 mr-1.5" />{" "}
                                <span className="mt-0.5">
                                    বিতরণের তারিখ আবশ্যক
                                </span>
                            </span>
                            {/* <Controller
                                control={control}
                                // defaultValue={singleDistribution.dateInISO}
                                name="date"
                                render={({ field }) => (
                                    <>
                                        <fieldset className="border-[1.5px] border-[#919EAB] border-opacity-[32%] rounded-sm pb-[.8rem] pt-4 px-3.5 flex items-center">
                                            <DatePicker
                                                locale={customLocale}
                                                readOnly={isDetails}
                                                placeholderText="তারিখ" // Placeholder in Bengali
                                                dateFormat="dd/MM/yyyy" // Customize date format if needed
                                                className="text-base text-slate-800 leading-6 border-none outline-none w-[142px]"
                                                onChange={(date) => {
                                                    handleDateChange(date);
                                                    field.onChange(date);
                                                }}
                                                selected={
                                                    // handleIsCreate()
                                                    //     ? field.value
                                                    //     : selectedDate
                                                    //     ? parse(
                                                    //           selectedDate,
                                                    //           "dd/mm/yyyy",
                                                    //           new Date()
                                                    //       )
                                                    //     : field.value
                                                    selectedDate
                                                        ? parse(
                                                              selectedDate,
                                                              "dd/mm/yyyy",
                                                              new Date()
                                                          )
                                                        : field.value
                                                }
                                            />
                                        </fieldset>
                                        <span
                                            className={`text-xs flex items-center mt-1 text-red-600 ${
                                                errors.dob
                                                    ? "visible"
                                                    : "invisible"
                                            }`}
                                        >
                                            <ExclamationCircleIcon className="w-4 h-4 mr-1.5" />{" "}
                                            <span className="mt-0.5">
                                                জন্ম তারিখ আবশ্যক
                                            </span>
                                        </span>
                                    </>
                                )}
                            /> */}
                        </Box>
                    </div>
                    <div className="flex flex-row gap-3">
                        <Buttons.Outline
                            borderColor="border-slate-400"
                            label="ফেরত যান"
                            textColor="text-slate-600"
                            fontWeight="font-bold"
                            textSize="text-sm"
                            paddingY="py-2.5"
                            paddingX="px-7"
                            onClick={() => navigate(-1)}
                        />
                        {create ? (
                            <Buttons.IconWithLabel
                                label="তৈরি করুন"
                                paddingX="px-3"
                                paddingY="py-2.5"
                                icon={Icons.add_white}
                                bgColor="bg-green-600"
                                radius="rounded-[2px]"
                                textColor="text-white"
                                textSize={"font-bold"}
                                type="submit"
                            />
                        ) : isDetails ? (
                            <Buttons.Primary
                                label="এডিট করুন"
                                paddingX="px-3"
                                paddingY="py-2.5"
                                width="w-[120px]"
                                // icon={Icons.add_white}
                                bgColor="bg-green-600"
                                radius="rounded-[2px]"
                                textColor="text-white"
                                textSize={"font-bold"}
                                // type="button"
                                // onClick={() => {
                                //     navigate(
                                //         `/distribution-schedule/update/${id}`
                                //     );
                                // }}
                            />
                        ) : (
                            <Buttons.Primary
                                label="আপডেট করুন"
                                paddingX="px-3"
                                paddingY="py-2.5"
                                width="w-[120px]"
                                bgColor="bg-green-600"
                                radius="rounded-[2px]"
                                textColor="text-white"
                                textSize={"font-bold"}
                                type="submit"
                            />
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
};

export default CreateDistributionSchedule;
