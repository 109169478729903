import Overlay from "../components/Common/Overlay";
import React from "react";

const Modal = ({
    overlayVariant,
    overlayZIndex,
    modalZIndex,
    children,
    setOpen,
}) => {
    return (
        <div
            className={`fixed animate-fadeIn top-0 left-0 w-full h-screen flex items-center justify-center z-40`}
        >
            <Overlay
                variant={overlayVariant}
                zIndex={overlayZIndex}
                setOpen={setOpen}
            />
            <div className={`${modalZIndex ? modalZIndex : "z-20"}`}>
                {children}
            </div>
        </div>
    );
};

export default Modal;
