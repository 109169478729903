import axios from "axios";
import webpfy from "webpfy";
import { GET_IMAGE, IMAGE_UPLODER_URL } from "../helpers/APIURL";

const uploadImage = async (token, image) => {
    // Convert Image to webp
    const quality = 75;
    const options = {
        image,
        quality,
    };
    const imageToWebp = await webpfy(options);

    const config = {
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
    };

    const formData = new FormData();
    formData.append("image", imageToWebp.webpBlob, imageToWebp.fileName);

    return axios.post(IMAGE_UPLODER_URL, formData, config);
};

const getImage = async (token, imageLink) => {
    const config = {
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
        params: {
            key: imageLink,
        },
    };

    return axios.get(`${GET_IMAGE}/url`, config);
};

const ImageUploaderService = { uploadImage, getImage };

export default ImageUploaderService;
