import React, { useState } from "react";
import Tooltip from "../../layouts/Tooltip";
import Icons from "../../assets/Icons";

const LegendSelectField = ({ data, label, name, setQueryParams }) => {
    const [selected, setSelected] = useState("");
    const [open, setOpen] = useState(false);

    const handleSelectClick = (value) => {
        setSelected(value);
        if (value === "") {
            setQueryParams((prev) => ({
                ...prev,
                [name]: null,
                page: 1,
                limit: 10,
            }));
        } else {
            setQueryParams((prev) => ({
                ...prev,
                [name]: value,
                page: 1,
                limit: 10,
            }));
        }
        setOpen(!open);
    };
    return (
        <div className="relative">
            <fieldset className="border-[1.5px] border-[#919EAB] border-opacity-[32%] rounded-lg pb-4 pt-2 px-3.5 flex items-center">
                <legend className="text-[#919EAB] text-xs font-medium">
                    {label}
                </legend>
                <input
                    type="text"
                    readOnly
                    value={selected}
                    placeholder="সিলেক্ট করুণ"
                    onClick={() => setOpen(!open)}
                    className="text-base text-slate-800 leading-6 border-none outline-none w-[142px]"
                />
                {selected && (
                    <button
                        className="p-1"
                        onClick={() => handleSelectClick("")}
                    >
                        <img
                            src={Icons.cancel}
                            alt=""
                            className="rounded-full"
                        />
                    </button>
                )}
            </fieldset>
            {open && (
                <Tooltip
                    top="top-[70px]"
                    right=""
                    overlayVariant="transparent"
                    setOpen={setOpen}
                    isIndicator={false}
                    frameWidth={"w-full"}
                >
                    <div className="p-2 bg-white rounded shadow-light-gray flex flex-col w-full max-h-[17rem] overflow-auto">
                        {data.map((item, index) => (
                            <button
                                key={index}
                                className="py-3 px-2 text-gray-700 text-sm bg-white hover:bg-gray-50 rounded text-left"
                                onClick={() => handleSelectClick(item)}
                            >
                                {item}
                            </button>
                        ))}
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

export default LegendSelectField;
