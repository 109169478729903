import Icons from "../../assets/Icons";
import * as PATH from "../Slug";
import { Permission } from "../permissionStrings";

export const userManagement = [
    {
        name: "ব্যাবহারকারী ম্যানেজমেন্ট",
        icon: Icons.UserManagement,
        hoverIcon: Icons.activeUserManagement,
        current: false,
        href: PATH.USER_MANAGEMENT_PATH,
        hidden: false,
        permissions: [
            Permission.READ_USER,
            Permission.CREATE_USER,
            Permission.UPDATE_USER,
        ],
        children: [],
    },
];
