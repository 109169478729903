import Icons from "../../assets/Icons";
import ReportGenerate from "../../pages/Report/ReportGenerate";
import GeneratedReports from "../../pages/Report/GeneratedReports";
import * as PATH from "../Slug";
import { Permission } from "../permissionStrings";

export const reportManagement = [
    {
        name: "রিপোর্ট ম্যানেজমেন্ট",
        icon: Icons.report,
        hoverIcon: Icons.activeReport,
        current: false,
        href: PATH.REPORT_PATH,
        hidden: false,
        permissions: [Permission.READ_REPORT],
        children: [
            {
                name: "রিপোর্ট জেনারেট",
                href: PATH.REPORT_GENERATE_PATH,
                // icon: PencilIcon,
                current: true,
                parent: "Blogs",
                hidden: true,
                component: ReportGenerate,
                permissions: [Permission.READ_REPORT, Permission.CREATE_REPORT],
            },
            {
                name: "জেনারেটেড রিপোর্টস",
                href: PATH.GENERATED_REPORTS_PATH,
                // icon: PencilIcon,
                current: true,
                parent: "Blogs",
                hidden: true,
                component: GeneratedReports,
                permissions: [Permission.READ_REPORT],
            },
        ],
    },
];
