import React from "react";
import { useUserContext } from "../../context/UserProvider";
import { approvedStatusWithEN } from "../../libs/common";
import Buttons from "../../components/Buttons";
import { useBeneficiaryContext } from "../../context/BeneficiaryProvider";
import { useNavigate } from "react-router-dom";

const ApprovedBeneficiaries = ({ data, id }) => {
    const { user } = useUserContext();
    const navigate = useNavigate();
    const {
        getApprovedBeneficiaries,
        approveBeneficiaryParams,
        rejectBeneficiaries,
    } = useBeneficiaryContext();

    const handleReject = async (id) => {
        const res = await rejectBeneficiaries(user.token, id);
        if (res === 200) {
            getApprovedBeneficiaries(
                user.token,
                approveBeneficiaryParams.page,
                approveBeneficiaryParams.limit,
                approveBeneficiaryParams
            );
            navigate(-1);
        }
    };

    return (
        <div className="flex flex-col md:flex-row items-start md:items-center gap-x-4">
            <div className="flex items-center gap-x-2">
                <h1 className="text-gray-800 text-sm font-semibold">
                    অনুমোদন স্ট্যাটাস:
                </h1>
                <div
                    className={`text-sm whitespace-nowrap font-medium ${
                        approvedStatusWithEN.find(
                            (item) => item.name_en === data.status
                        )?.color
                    } px-2 py-1 rounded`}
                >
                    {
                        approvedStatusWithEN.find(
                            (item) => item.name_en === data.status
                        )?.name_bn
                    }
                </div>
            </div>
            <Buttons.Primary
                label="বাতিল"
                paddingX="px-7"
                paddingY="py-2"
                bgColor="bg-[#DC2626]"
                width="w-[110px]"
                fontWeight="font-bold"
                onClick={() => handleReject(id)}
            />
        </div>
    );
};

export default ApprovedBeneficiaries;
