import React from "react";
import Buttons from "../../components/Buttons";
import { useUserContext } from "../../context/UserProvider";
import { useBeneficiaryContext } from "../../context/BeneficiaryProvider";
import { useNavigate } from "react-router-dom";

const ApproveBeneficiaries = ({ id }) => {
    const { user } = useUserContext();
    const navigate = useNavigate();
    const {
        approveBeneficiaries,
        getAwaitingApprovalBeneficiaries,
        approveBeneficiaryParams,
        rejectBeneficiaries,
    } = useBeneficiaryContext();

    const handleApprove = async (id) => {
        const res = await approveBeneficiaries(user.token, id);
        if (res === 200) {
            getAwaitingApprovalBeneficiaries(
                user.token,
                approveBeneficiaryParams.page,
                approveBeneficiaryParams.limit,
                approveBeneficiaryParams
            );
            navigate(-1);
        }
    };

    const handleReject = async (id) => {
        const res = await rejectBeneficiaries(user.token, id);
        if (res === 200) {
            getAwaitingApprovalBeneficiaries(
                user.token,
                approveBeneficiaryParams.page,
                approveBeneficiaryParams.limit,
                approveBeneficiaryParams
            );
            navigate(-1);
        }
    };
    return (
        <div className="flex flex-col sm:flex-row gap-y-2 items-center gap-x-4">
            <Buttons.Outline
                label="বাতিল"
                paddingX="px-7"
                paddingY="py-3"
                width="w-[110px]"
                fontWeight="font-bold"
                onClick={() => handleReject(id)}
            />
            <Buttons.Primary
                label="অনুমোদন"
                paddingX="px-7"
                paddingY="py-3"
                width="w-[110px]"
                fontWeight="font-bold"
                type="button"
                onClick={() => handleApprove(id)}
            />
        </div>
    );
};

export default ApproveBeneficiaries;
