import React, { useEffect } from "react";
import SecondaryTableHeader from "../../components/Common/SecondaryTableHeader";
import Buttons from "../../components/Buttons";
import Icons from "../../assets/Icons";
import { useNavigate, useParams } from "react-router-dom";
import { useCardContext } from "../../context/CardProvider";
import { useUserContext } from "../../context/UserProvider";

const CardPreview = () => {
    const { id } = useParams();
    const { user } = useUserContext();
    const {
        getCardPreview,
        cardPreviewImage,
        getDownloadedCard,
        setCardPreviewImage,
    } = useCardContext();
    const navigate = useNavigate();

    const handleGenerate = (id) => {
        getDownloadedCard(user.token, id);
    };

    useEffect(() => {
        setCardPreviewImage("");
    }, [setCardPreviewImage]);

    useEffect(() => {
        getCardPreview(user?.token, id);
    }, [getCardPreview, id, user?.token]);

    return (
        <div className="w-full flex justify-center">
            <div className="w-[1080px] rounded-lg overflow-hidden">
                <SecondaryTableHeader title="ব্যাবহারকারীর কার্ড" />
                <div className="flex w-full justify-center px-6 py-10 h-[30rem]">
                    {cardPreviewImage && (
                        <img src={cardPreviewImage} alt="card" />
                    )}
                </div>
                <div className="flex w-full justify-end py-4 px-5">
                    <div className="flex gap-x-4 py-3 px-4">
                        <Buttons.Outline
                            borderColor="border-slate-400"
                            label="ফেরত যান"
                            borderWidth="border-[1.5px]"
                            fontWeight="font-bold"
                            radius="rounded-sm"
                            textSize="text-sm"
                            textColor="text-slate-600"
                            width="w-[120px]"
                            paddingX="px-6"
                            paddingY="py-2.5"
                            onClick={() => navigate(-1)}
                        />
                        <Buttons.IconWithLabel
                            bgColor="bg-green-600"
                            label="ডাউনলোড"
                            icon={Icons.download}
                            fontWeight="font-bold"
                            radius="rounded-sm"
                            textSize="text-sm"
                            textColor="text-white"
                            width="w-[120px]"
                            paddingX="px-6"
                            paddingY="py-2.5"
                            gap="gap-x-2"
                            onClick={() => handleGenerate(id)}
                        />
                        {/* <Buttons.IconWithLabel
                            bgColor="bg-blue-700"
                            label="প্রিন্ট দিন"
                            icon={Icons.print}
                            fontWeight="font-bold"
                            radius="rounded-sm"
                            textSize="text-sm"
                            textColor="text-white"
                            width="w-[120px]"
                            paddingX="px-6"
                            paddingY="py-2.5"
                            gap="gap-x-2"
                        /> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardPreview;
