export const Permission = {
    //ALL
    ALL: "ALL",

    // Beneficiary Module
    CREATE_BENEFICIARY: "CREATE_BENEFICIARY",
    READ_BENEFICIARY: "READ_BENEFICIARY",
    UPDATE_BENEFICIARY: "UPDATE_BENEFICIARY",
    DELETE_BENEFICIARY: "DELETE_BENEFICIARY",
    HARD_DELETE_BENEFICIARY: "HARD_DELETE_BENEFICIARY",
    APPROVE_BENEFICIARY: "APPROVE_BENEFICIARY",
    REJECT_BENEFICIARY: "REJECT_BENEFICIARY",
    SEND_TO_UPAZILLA_BENEFICIARY: "SEND_TO_UPAZILLA_BENEFICIARY",
    EXPORT_BENEFICIARY: "EXPORT_BENEFICIARY",

    // Card Module
    CREATE_CARD: "CREATE_CARD",
    READ_CARD: "READ_CARD",
    UPDATE_CARD: "UPDATE_CARD",
    DELETE_CARD: "DELETE_CARD",
    HARD_DELETE_CARD: "HARD_DELETE_CARD",
    APPROVE_CARD: "APPROVE_CARD",
    REJECT_CARD: "REJECT_CARD",
    SEND_GENERATE_REQUEST_CARD: "SEND_GENERATE_REQUEST_CARD",
    REGENERATE_CARD: "REGENERATE_CARD",

    // Distribution Module
    CREATE_DISTRIBUTION: "CREATE_DISTRIBUTION",
    READ_DISTRIBUTION: "READ_DISTRIBUTION",
    UPDATE_DISTRIBUTION: "UPDATE_DISTRIBUTION",
    DELETE_DISTRIBUTION: "DELETE_DISTRIBUTION",
    HARD_DELETE_DISTRIBUTION: "HARD_DELETE_DISTRIBUTION",

    // Distribution_Schedule Module
    CREATE_DISTRIBUTION_SCHEDULE: "CREATE_DISTRIBUTION_SCHEDULE",
    READ_DISTRIBUTION_SCHEDULE: "READ_DISTRIBUTION_SCHEDULE",
    UPDATE_DISTRIBUTION_SCHEDULE: "UPDATE_DISTRIBUTION_SCHEDULE",
    DELETE_DISTRIBUTION_SCHEDULE: "DELETE_DISTRIBUTION_SCHEDULE",
    HARD_DELETE_DISTRIBUTION_SCHEDULE: "HARD_DELETE_DISTRIBUTION_SCHEDULE",

    // Profile Module
    CREATE_PROFILE: "CREATE_PROFILE",
    READ_PROFILE: "READ_PROFILE",
    UPDATE_PROFILE: "UPDATE_PROFILE",
    DELETE_PROFILE: "DELETE_PROFILE",
    HARD_DELETE_PROFILE: "HARD_DELETE_PROFILE",

    // Report Module
    CREATE_REPORT: "CREATE_REPORT",
    READ_REPORT: "READ_REPORT",
    UPDATE_REPORT: "UPDATE_REPORT",
    DELETE_REPORT: "DELETE_REPORT",
    HARD_DELETE_REPORT: "HARD_DELETE_REPORT",
    READ_AREA_WISE_REPORT: "READ_AREA_WISE_REPORT",

    // Role Module
    CREATE_ROLE: "CREATE_ROLE",
    READ_ROLE: "READ_ROLE",
    UPDATE_ROLE: "UPDATE_ROLE",
    DELETE_ROLE: "DELETE_ROLE",
    HARD_DELETE_ROLE: "HARD_DELETE_ROLE",

    // Settings Module
    CREATE_SETTINGS: "CREATE_SETTINGS",
    READ_SETTINGS: "READ_SETTINGS",
    UPDATE_SETTINGS: "UPDATE_SETTINGS",
    DELETE_SETTINGS: "DELETE_SETTINGS",
    HARD_DELETE_SETTINGS: "HARD_DELETE_SETTINGS",

    // Statistics Module
    CREATE_STATISTICS: "CREATE_STATISTICS",
    READ_STATISTICS: "READ_STATISTICS",
    UPDATE_STATISTICS: "UPDATE_STATISTICS",
    DELETE_STATISTICS: "DELETE_STATISTICS",
    HARD_DELETE_STATISTICS: "HARD_DELETE_STATISTICS",

    // User Module
    CREATE_USER: "CREATE_USER",
    READ_USER: "READ_USER",
    UPDATE_USER: "UPDATE_USER",
    DELETE_USER: "DELETE_USER",
    HARD_DELETE_USER: "HARD_DELETE_USER",
};
