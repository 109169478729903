import {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
} from "@heroicons/react/20/solid";
import React from "react";
import ReactPaginate from "react-paginate";

const Primary = ({ pageSize, setParams, params }) => {
    const handlePageClick = (event) => {
        // setPage(event.selected + 1);
        setParams((prev) => ({ ...prev, page: event.selected + 1 }));
    };
    return (
        <div className="">
            {/* <button>prev</button>
            <button>next</button> */}
            {pageSize && (
                <ReactPaginate
                    breakLabel="..."
                    nextLabel={
                        <button className="text-lg px-3 py-[9px] bg-slate-100 rounded">
                            <ChevronDoubleRightIcon className="w-5 h-5 text-slate-800" />
                        </button>
                    }
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={pageSize}
                    previousLabel={
                        <button className="text-lg px-3 py-[9px] bg-slate-100 rounded">
                            <ChevronDoubleLeftIcon className="w-5 h-5 text-slate-800" />
                        </button>
                    }
                    renderOnZeroPageCount={null}
                    className="flex gap-x-3 items-center"
                    activeLinkClassName="px-3 pt-2 pb-1.5 rounded bg-blue-200"
                    // disabledLinkClassName="p-3 bg-blue-500"
                    // disabledClassName="p-3 bg-blue-500"
                    // nextClassName="p-3 bg-blue-500"
                    // previousClassName="p-3 bg-gray-500"
                    pageClassName="text-slate-800"
                    forcePage={params.page - 1}
                />
            )}
        </div>
    );
};

export default Primary;
