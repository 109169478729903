import React from "react";

const Outline = ({
  label,
  paddingY,
  paddingX,
  width,
  radius,
  borderWidth,
  borderColor,
  textColor,
  fontWeight,
  textSize,
  type,
  ...rest
}) => {
  return (
    <button
      {...rest}
      type={type ? type : "button"}
      className={`${paddingY ? paddingY : "py-4"} ${paddingX} ${width} ${
        borderWidth ? borderWidth : "border-[1.5px]"
      } ${borderColor ? borderColor : "border-red-600"} ${
        radius ? radius : "rounded-sm"
      } ${textColor ? textColor : "text-red-600"} ${fontWeight} ${
        textSize ? textSize : "text-base"
      }`}
    >
      {label}
    </button>
  );
};

export default Outline;
