import React, { useEffect, useState } from "react";
import Filter from "../../components/Filter";
import { useDistributionContext } from "../../context/distributionProvider";
import { useBeneficiaryContext } from "../../context/BeneficiaryProvider";
import { useUserContext } from "../../context/UserProvider";
import TableHeader from "../../components/Common/TableHeader";
import Table from "../../components/Table";
import Buttons from "../../components/Buttons";
import TableFooter from "../../components/Common/TableFooter";

const DistributionRead = () => {
    const { user } = useUserContext();
    const {
        distributionRead,
        distributionReadParams,
        setDistributionReadParams,
        distributionReadTotal,
        handleDistributionRead,
    } = useDistributionContext();

    const { getVillageData, villages } = useBeneficiaryContext();
    const [firstRender, setFirstRender] = useState(true);

    useEffect(() => {
        if (user && firstRender) {
            getVillageData(user.token);
            handleDistributionRead(
                user.token,
                distributionReadParams.page,
                distributionReadParams.limit,
                distributionReadParams
            );
            setFirstRender(false);
            setDistributionReadParams({
                page: 1,
                limit: 10,
                startDate: null,
                endDate: null,
                status: null,
                union: null,
                search: null,
            });
        } else {
            getVillageData(user.token);
            handleDistributionRead(
                user.token,
                distributionReadParams.page,
                distributionReadParams.limit,
                distributionReadParams
            );
        }
    }, [
        distributionReadParams,
        setDistributionReadParams,
        firstRender,
        getVillageData,
        handleDistributionRead,
        user,
    ]);

    const renderActionComponents = (data) => {
        return (
            <>
                {/* <td>
                    <div className="px-4  relative">
                        <button onClick={() => setActiveTooltip(data._id)}>
                            <EllipsisVerticalIcon className="w-8 h-8 text-slate-700" />
                        </button>
                        {activeTooltip === data._id && (
                            <Tooltip
                                top={
                                    getAllDistributionList
                                        .slice(-2)
                                        .some((item) => item._id === data._id)
                                        ? getAllDistributionList.length >= 3
                                            ? "-top-28"
                                            : "-top-[1.5rem]"
                                        : "-top-3"
                                }
                                right="right-14"
                                overlayVariant="transparent"
                                setOpen={setActiveTooltip}
                                align={
                                    getAllDistributionList
                                        .slice(-2)
                                        .some((item) => item._id === data._id)
                                        ? getAllDistributionList.length >= 3
                                            ? "bottom"
                                            : "top"
                                        : "top"
                                }
                            >
                                <div className="p-2 bg-white rounded w-[220px]">
                                    <IconWithLabel
                                        icon={Icons.file}
                                        label="বিস্তারিত দেখুন"
                                        paddingY="py-2.5"
                                        // paddingX="px-5"
                                        width="w-[180px]"
                                        onClick={() =>
                                            navigate(
                                                `/distribution-schedule/${data._id}`
                                            )
                                        }
                                    />
                                    <IconWithLabel
                                        icon={Icons.edit}
                                        label="পরিবর্তন করুন "
                                        paddingY="py-2.5 hover:bg-gray-50"
                                        // paddingX="px-5"
                                        width="w-[180px]"
                                        onClick={() =>
                                            navigate(
                                                `/distribution-schedule/update/${data._id}`
                                            )
                                        }
                                    />
                                    <IconWithLabel
                                        icon={Icons.delete_icon}
                                        label="ডিলিট করুন"
                                        paddingY="py-2.5 hover:bg-gray-50"
                                        // paddingX="px-2 hover:bg-gray-50"
                                        width="w-[180px]"
                                        onClick={() => {
                                            handleDeleteDistributionSchedule(
                                                data._id
                                            );
                                        }}
                                    />
                                </div>
                            </Tooltip>
                        )}
                    </div>
                </td> */}
            </>
        );
    };

    return (
        <div className="">
            <div className=" bg-neutral-00 overflow-y-auto py-12 px-10 flex items-center flex-col ">
                <div className="py-12 px-10 flex items-center flex-col">
                    <div className="shadow-medium-gray rounded-lg overflow-hidden ">
                        {/* <div className="p-6 bg-white flex gap-x-4 items-center justify-between">
                <div className="flex flex-row gap-3">
                    <FormFields.LegendDateField
                        setQueryParams={setDistributionParams}
                    />
                    <FormFields.LegendSelectField
                        data={[]}
                        label={"ঠিকানা"}
                        setQueryParams={setDistributionParams}
                        name="union"
                    />
                </div>
                <div className="w-[60%]">
                    <FormFields.SearchField
                        placeholder="উপকারভোগী সার্চ করুন"
                        setQueryParams={setDistributionParams}
                    />
                </div>
            </div> */}

                        <Filter.Primary
                            // firstSelectData={approvedStatusDropdown}
                            // firstSelectLabel="অনুমোদন স্ট্যাটাস"
                            secondSelectLabel="ঠিকানা"
                            secondSelectData={villages ? villages : []}
                            setQueryParams={setDistributionReadParams}
                            futureDateSelectable={true}
                        />

                        <TableHeader
                            selectedItems={[]}
                            // setSelectedItems={setSelectedItems}
                            title={"সকল বিতরণের লিস্ট"}
                            selectedTitle={"বিতরণ সিলেঙ্কট করা হয়েছে"}
                        >
                            <>
                                <Buttons.Primary
                                    label={"ডিলিট"}
                                    onClick={() => {}}
                                    paddingX="px-7"
                                    paddingY="py-2"
                                    fontWeight="font-bold"
                                    bgColor="bg-red-500"
                                />
                            </>
                        </TableHeader>

                        <Table.Primary
                            // tableHeads={["উপকারভোগীর নাম", "পরিচয় পত্র নং", "ঠিকানা", "মোবাইল"]}
                            tableHeads={tableHeadsData}
                            // tableDataKeys={["name", "nid", "village", "mobile"]}
                            tableDataKeys={tableDataKeys}
                            //   // tableActionHeads={["কার্ড জেনারেট অনুরোধ অনুমোদন", ""]}
                            tableActionHeads={[]}
                            tableData={distributionRead || []}
                            tableActionComponents={renderActionComponents}
                            // setSelectedItems={setSelectedItems}
                            // selectedItems={selectedItems}
                            dataLength={distributionRead?.length}
                            needMinHeight={true}
                            isCheckbox={false}
                        />
                        <TableFooter
                            params={distributionReadParams}
                            setParams={setDistributionReadParams}
                            pageSize={distributionReadTotal}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DistributionRead;

const tableHeadsData = [
    {
        title: "উপকারভোগীর নাম",
        align: "left",
        width: "w-[158px]",
    },
    {
        title: "ডিলারের নাম",
        align: "left",
        width: "w-[158px]",
    },
    {
        title: " ডিলারের নাম্বার",
        align: "left",
        width: "w-[158px]",
    },
    {
        title: "তারিখ",
        align: "left",
        width: "w-[158px]",
    },
    {
        title: "ইউনিয়নের নাম ",
        align: "left",
        width: "w-[158px]",
    },
    {
        title: "উপকারভোগীর নাম্বার",
        align: "left",
        width: "w-[158px]",
    },
];

const tableDataKeys = [
    {
        title: "beneficiaryName",
        width: "w-[158px]",
    },
    {
        title: "dealerName",
        width: "w-[158px]",
    },
    {
        title: "dealerMobile",
        width: "w-[158px]",
    },
    {
        title: "date",
        width: "w-[150px]",
    },
    {
        title: "union",
        width: "w-[158px]",
    },
    {
        title: "beneficiaryNumber",
        width: "w-[150px]",
    },
];
