import React, { useEffect, useState } from "react";

import { useBeneficiaryContext } from "../../context/BeneficiaryProvider";
import { useUserContext } from "../../context/UserProvider";
import TableHeader from "../../components/Common/TableHeader";
import Buttons from "../../components/Buttons";
import Table from "../../components/Table";
import TableFooter from "../../components/Common/TableFooter";

const BeneficiariesUploadExcel = () => {
    const {
        successfullyUploadedExcel,
        successfulUploadedExcelTotalPages,
        getSuccessfullyUplodedExcelFiles,
        successfullyUploadedExcelParams,
        setSuccessfullyUploadedExcelParams,
        getDownloadFiles,
    } = useBeneficiaryContext();
    const { user } = useUserContext();
    const [
        changeSuccessfullyUploadedExcel,
        setchangeSuccessfullyUploadedExcel,
    ] = useState([]);

    useEffect(() => {
        setSuccessfullyUploadedExcelParams({ page: 1, limit: 10 });
    }, [setSuccessfullyUploadedExcelParams]);

    useEffect(() => {
        getSuccessfullyUplodedExcelFiles(
            user.token,
            successfullyUploadedExcelParams.page,
            successfullyUploadedExcelParams.limit
        );
    }, [
        user,
        successfullyUploadedExcelParams,
        getSuccessfullyUplodedExcelFiles,
    ]);

    useEffect(() => {
        const transformedArray = successfullyUploadedExcel?.map((item) => ({
            _id: item._id,
            file: item.file,
            newFileName: item.file.split("/").pop(), // Extract the filename
            createdAt: new Date(item.createdAt).toLocaleDateString("en-US"), // Reformat the date
            createdBy: item.createdBy,
        }));
        setchangeSuccessfullyUploadedExcel(transformedArray);
    }, [successfullyUploadedExcel]);

    const handleDownload = (fileLink) => {
        getDownloadFiles(user.token, fileLink);
    };

    const renderActionComponents = (data) => {
        return (
            <>
                <td className="py-3 pl-4 mx-auto flex flex-row items-center justify-center w-full ">
                    <div>
                        <Buttons.Primary
                            label={"ডাউনলোড"}
                            onClick={() => {
                                handleDownload(data.file);
                            }}
                            paddingX="px-7"
                            paddingY="py-2"
                            fontWeight="font-bold"
                        />
                    </div>
                </td>
            </>
        );
    };

    return (
        <div className="py-12 px-10 flex items-center justify-center flex-col shadow-medium-gray rounded-lg ">
            <div className="shadow-medium-gray rounded-lg  overflow-hidden w-[350px] sm:w-[640px] md:w-[550] lg:w-[700px] xl:w-auto">
                <TableHeader
                    selectedItems={[]}
                    title={"সকল আপলোডকৃত এক্সেল লিস্ট"}
                >
                    <>
                        <Buttons.Primary
                            label={"অনুমোদন"}
                            onClick={() => {
                                // handleApprove(selectedItems);
                            }}
                            paddingX="px-7"
                            paddingY="py-2"
                            fontWeight="font-bold"
                        />
                    </>
                </TableHeader>
                <Table.Primary
                    // tableHeads={["উপকারভোগীর নাম", "পরিচয় পত্র নং", "ঠিকানা", "মোবাইল"]}
                    tableHeads={tableHeadsData}
                    // tableDataKeys={["name", "nid", "village", "mobile"]}
                    tableDataKeys={tableDataKeys}
                    // tableActionHeads={["কার্ড জেনারেট অনুরোধ অনুমোদন", ""]}
                    tableActionHeads={tableActionHeads}
                    tableData={changeSuccessfullyUploadedExcel}
                    tableActionComponents={renderActionComponents}
                    selectedItems={[]}
                    isCheckbox={false}
                />
                <TableFooter
                    params={successfullyUploadedExcelParams}
                    setParams={setSuccessfullyUploadedExcelParams}
                    pageSize={successfulUploadedExcelTotalPages}
                />
            </div>
        </div>
    );
};

export default BeneficiariesUploadExcel;

const tableHeadsData = [
    {
        title: "আপলোড কারির  নাম",
        align: "left",
        width: "w-[158px]",
    },
    {
        title: "ফাইলের  নাম ",
        align: "left",
        width: "w-[158px]",
    },
    {
        title: "সময়",
        align: "left",
        width: "w-[158px]",
    },
];

const tableDataKeys = [
    {
        title: "createdBy",
        width: "w-[158px]",
    },
    {
        title: "newFileName",
        width: "w-[158px]",
    },
    {
        title: "createdAt",
        width: "w-[200px]",
    },
];

const tableActionHeads = [
    {
        title: "ডাউনলোড ",
        align: "left",
        width: "w-[210px]",
    },
];
