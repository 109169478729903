import React from "react";
import Layouts from "../../layouts";
import { useDashboardContext } from "../../context/DashboardProvider";
import { useUserContext } from "../../context/UserProvider";
import Icons from "../../assets/Icons";

const Dashboard = () => {
    const { getDashboardData, dashboardData } = useDashboardContext();
    const { user } = useUserContext();

    React.useEffect(() => {
        if (user) {
            getDashboardData(user?.token);
        }
    }, [user, getDashboardData]);

    const dataList = [
        {
            _id: 1,
            title: "মোট ইউনিয়ন",
            icon: Icons.dash_union,
            total: dashboardData?.union,
        },
        {
            _id: 2,
            title: "মোট উপকারভোগী",
            icon: Icons.dash_beneficiary,
            total: dashboardData?.beneficiary,
        },
        {
            _id: 3,
            title: "মোট ডিলার",
            icon: Icons.dash_dealer,
            total: dashboardData?.dealer,
        },
        {
            _id: 4,
            title: "মোট বিতরণ সংখ্যা",
            icon: Icons.dash_distribution,
            total: dashboardData?.distribution,
        },
        {
            _id: 5,
            title: "মোট উপজেলা",
            icon: Icons.dash_upazila,
            total: dashboardData?.upazila,
        },
    ];

    return (
        <Layouts.Secondary>
            <div className="pt-[88px] lg:pl-[280px] h-screen bg-neutral-50 overflow-y-auto">
                <div className="py-12 px-10 grid 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-2  sm:grid-cols-2 gap-6">
                    {dataList.map((data) => (
                        <Card key={data._id} data={data} />
                    ))}
                </div>
            </div>
        </Layouts.Secondary>
    );
};

export default Dashboard;

const Card = ({ data }) => {
    return (
        <>
            {data.total >= 0 && (
                <div className="p-6 rounded-lg shadow-sm flex items-start justify-between w-full bg-white">
                    <div>
                        <h3 className="text-base font-semibold text-slate-700">
                            {data.title}
                        </h3>
                        <h2 className="text-5xl font-bold text-blue-950 leading-[64px] mt-1">
                            {data.total}
                        </h2>
                    </div>
                    <img src={data.icon} alt="dashboard-icon" className="" />
                </div>
            )}
        </>
    );
};
