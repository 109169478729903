import { Permission } from "../permissionStrings";
import * as PATH from "../Slug";
import Icons from "../../assets/Icons";
import DistributionRead from "../../pages/distribution/DistributionRead";
import DistributionWithNid from "../../pages/distribution/DistributionWithNid";

export const distributionManagement = [
    {
        name: "বিতরণ ম্যানেজমেন্ট",
        icon: Icons.card_user,
        hoverIcon: Icons.card_user_hover,
        current: false,
        href: `${PATH.DISTRIBUTION_SCHEDULE_PATH}`,
        hidden: false,
        permissions: [
            Permission.READ_DISTRIBUTION_SCHEDULE,
            Permission.CREATE_DISTRIBUTION_SCHEDULE,
        ],
        children: [
            {
                name: "সময়সূচী",
                href: PATH.DISTRIBUTION_INFO,
                // icon: PencilIcon,
                current: true,
                parent: "Blogs",
                hidden: true,
                component: DistributionRead,
                permissions: [Permission.CREATE_DISTRIBUTION_SCHEDULE],
            },
            {
                name: "বিতরণ সমূহ",
                href: PATH.DISTRIBUTION_READ,
                // icon: PencilIcon,
                current: true,
                parent: "Blogs",
                hidden: true,
                component: DistributionRead,
                permissions: [Permission.READ_DISTRIBUTION],
            },
            {
                name: "বিতরণ সমূহ (এনআইডি)",
                href: PATH.DISTRIBUTION_NID,
                // icon: PencilIcon,
                current: true,
                parent: "Blogs",
                hidden: true,
                component: DistributionWithNid,
                permissions: [Permission.READ_DISTRIBUTION],
            },
            //   {
            //     name: "বাতিলকৃত অনুরোধ",
            //     href: PATH.CARD_REJECTED_PATH,
            //     // icon: PencilIcon,
            //     current: true,
            //     parent: "Blogs",
            //     hidden: true,
            //     component: CardRejected,
            //     permissions: [Permission.READ_BENEFICIARY, Permission.APPROVE_CARD],
            //   },
            //   {
            //     name: "কার্ড জেনারেট",
            //     href: PATH.CARD_GENERATE_PATH,
            //     // icon: PencilIcon,
            //     current: true,
            //     parent: "Blogs",
            //     hidden: true,
            //     component: CardGenerate,
            //     permissions: [Permission.READ_BENEFICIARY, Permission.CREATE_CARD],
            //   },
            //   {
            //     name: "জেনারেটেড কার্ডস",
            //     href: PATH.CARD_GENERATED_PATH,
            //     // icon: PencilIcon,
            //     current: true,
            //     parent: "Blogs",
            //     hidden: true,
            //     component: CardGenerated,
            //     permissions: [Permission.READ_BENEFICIARY],
            //   },
        ],
    },
];
