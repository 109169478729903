"use client";
import React, { createContext, useCallback, useContext, useState } from "react";
import {
    BENEFICIARIES_APPROVED_URL,
    BENEFICIARIES_AWAITING_APPROVAL,
    BENEFICIARIES_PENDING_URL,
    BENEFICIARIES_REJECTED_URL,
} from "../helpers/APIURL";
import { withToast } from "../hoc/withToast";
import BeneficiaryService from "../services/BeneficiaryService";
import { useUserContext } from "./UserProvider";

const BeneficiaryContext = createContext({});
export const useBeneficiaryContext = () => {
    return useContext(BeneficiaryContext);
};

const BeneficiaryProvider = ({ showToast, children }) => {
    const { setLoader } = useUserContext();

    const [villages, setVillages] = useState([]);
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [allBeneficiaries, setAllBeneficiaries] = useState([]);
    const [approvedBeneficiaries, setApprovedBeneficiaries] = useState([]);
    const [rejectedBeneficiaries, setRejectedBeneficiaries] = useState([]);
    const [awaitingApprovalBeneficiaries, setAwaitingApprovalBeneficiaries] =
        useState([]);
    const [allBeneficiariesTotal, setAllBeneficiariesTotal] = useState(null);
    const [totalPages, setTotalPage] = useState(null);
    const [approveBeneficiariesTotalPage, setApproveBeneficiariesTotalPage] =
        useState(null);
    const [rejectedBeneficiariesTotalPage, setRejectedBeneficiariesTotalPage] =
        useState(null);
    const [pendingBeneficiaries, setPendingBeneficiaries] = useState([]);
    const [pendingBeneficiariesTotalPages, setPendingBeneficiariesTotalPages] =
        useState(null);
    const [
        awaitingApprovalBeneficiariesTotalPages,
        setAwaitingApprovalBeneficiariesTotalPages,
    ] = useState(null);

    const [failedExcelData, setFailedExcelData] = useState([]);
    const [failedExcelTotal, setFailedExcelTotal] = useState(null);

    const [singleBeneficiaries, setSingleBeneficiaries] = useState({});
    const [successfullyUploadedExcel, setSuccessfullyUploadedExcel] = useState(
        []
    );
    const [
        successfulUploadedExcelTotalPages,
        setSuccessfulUploadedExcelTotalPages,
    ] = useState();

    const [allBeneficiaryParams, setAllBeneficiaryParams] = useState({
        page: 1,
        limit: 10,
        startDate: null,
        endDate: null,
        status: null,
        union: null,
        search: null,
    });
    const [approveBeneficiaryParams, setApproveBeneficiaryParams] = useState({
        page: 1,
        limit: 10,
        startDate: null,
        endDate: null,
        status: null,
        union: null,
        search: null,
    });
    const [approvedBeneficiaryParams, setApprovedBeneficiaryParams] = useState({
        page: 1,
        limit: 10,
        startDate: null,
        endDate: null,
        status: null,
        union: null,
        search: null,
    });
    const [rejectedBeneficiaryParams, setRejectedBeneficiaryParams] = useState({
        page: 1,
        limit: 10,
        startDate: null,
        endDate: null,
        status: null,
        union: null,
        search: null,
    });
    const [unionBeneficiaryParams, setUnionBeneficiaryParams] = useState({
        page: 1,
        limit: 10,
        startDate: null,
        endDate: null,
        status: null,
        union: null,
        search: null,
    });
    const [
        successfullyUploadedExcelParams,
        setSuccessfullyUploadedExcelParams,
    ] = useState({
        page: 1,
        limit: 10,
    });

    const [failedExcelParams, setFailedExcelParams] = useState({
        page: 1,
        limit: 10,
    });

    const getVillageData = React.useCallback(
        async (token) => {
            try {
                setLoader(true);
                const res = await BeneficiaryService.getVillages(token);
                setVillages(res.data.values);
                setLoader(false);
            } catch (error) {
                setLoader(false);
            }
        },
        [setLoader]
    );

    const getAllBeneficiaries = React.useCallback(
        async (token, page, limit, queryParams) => {
            try {
                setLoader(true);
                const res = await BeneficiaryService.getBeneficiaries({
                    token,
                    page,
                    limit,
                    queryParams,
                });
                setAllBeneficiaries(res.data.beneficiaries);
                setAllBeneficiariesTotal(res.data.totalPages);
                setLoader(false);
            } catch (error) {
                setLoader(false);
            }
        },
        [setLoader]
    );

    const uploadBeneficiaries = async (token, file) => {
        try {
            setLoader(true);

            const res = await BeneficiaryService.uploadBeneficiaries(
                token,
                file
            );

            if (res.status) {
                setLoader(false);
                return { status: res.status, message: res.data.message };
            }
        } catch (error) {
            setLoader(false);
        }
    };

    const getBeneficiaries = React.useCallback(
        async (token, page, limit, queryParams) => {
            try {
                setLoader(true);
                const res = await BeneficiaryService.getBeneficiaries({
                    token,
                    page,
                    limit,
                    queryParams,
                });
                setBeneficiaries(res.data.beneficiaries);
                setTotalPage(res.data.totalPages);
                setLoader(false);
            } catch (error) {
                setLoader(false);
                if (error.response.status === 404) {
                    setBeneficiaries([]);
                }
            }
        },
        [setLoader]
    );

    const getBeneficiariesById = async (token, id) => {
        try {
            setLoader(true);
            const res = await BeneficiaryService.getBeneficiariesById(
                token,
                id
            );
            setSingleBeneficiaries(res.data.beneficiary);
            setLoader(false);
        } catch (error) {
            setLoader(false);
        }
    };

    const approveBeneficiaries = async (token, id) => {
        try {
            setLoader(true);
            const res = await BeneficiaryService.approveBeneficiaries(
                token,
                id
            );
            if (res.status) {
                setLoader(false);
                return res.status;
            }
        } catch (error) {
            setLoader(false);
        }
    };

    const rejectBeneficiaries = async (token, id) => {
        try {
            setLoader(true);
            const res = await BeneficiaryService.rejectBeneficiaries(token, id);
            if (res.status) {
                setLoader(false);
                return res.status;
            }
        } catch (error) {
            setLoader(false);
        }
    };

    const getPendingBeneficiaries = React.useCallback(
        async (token, page, limit, queryParams) => {
            try {
                setLoader(true);
                const res = await BeneficiaryService.getBeneficiaries({
                    token,
                    page,
                    limit,
                    queryParams,
                    url: BENEFICIARIES_PENDING_URL,
                });
                setPendingBeneficiaries(res.data.beneficiaries);
                setPendingBeneficiariesTotalPages(res.data.totalPages);
                setLoader(false);
            } catch (error) {
                setLoader(false);
                if (error.response.status === 404) {
                    setPendingBeneficiaries([]);
                }
                showToast(error.response.data.message, true);
            }
        },
        [setLoader, showToast]
    );

    const getAwaitingApprovalBeneficiaries = React.useCallback(
        async (token, page, limit, queryParams) => {
            try {
                setLoader(true);
                const res = await BeneficiaryService.getBeneficiaries({
                    token,
                    page,
                    limit,
                    queryParams,
                    url: BENEFICIARIES_AWAITING_APPROVAL,
                });
                setAwaitingApprovalBeneficiaries(res.data.beneficiaries);
                setAwaitingApprovalBeneficiariesTotalPages(res.data.totalPages);
                setLoader(false);
            } catch (error) {
                setLoader(false);
                showToast(error.response.data.message, true);
                if (error.response.status === 404) {
                    setAwaitingApprovalBeneficiaries([]);
                }
            }
        },
        [setLoader, showToast]
    );

    const getApprovedBeneficiaries = React.useCallback(
        async (token, page, limit, queryParams) => {
            try {
                setLoader(true);
                const res = await BeneficiaryService.getBeneficiaries({
                    token,
                    page,
                    limit,
                    queryParams,
                    url: BENEFICIARIES_APPROVED_URL,
                });
                setApprovedBeneficiaries(res.data.beneficiaries);
                setApproveBeneficiariesTotalPage(res.data.totalPages);
                setLoader(false);
            } catch (error) {
                setLoader(false);
                if (error.response.status === 404) {
                    setApprovedBeneficiaries([]);
                }
                showToast(error.response.data.message, true);
            }
        },
        [setLoader, showToast]
    );

    const approveBeneficiaryById = async (token, id) => {
        try {
            setLoader(true);
            const res = await BeneficiaryService.approveBeneficiaryById(
                token,
                id
            );
            setSingleBeneficiaries(res.data.beneficiary);
            setLoader(false);
        } catch (error) {
            setLoader(false);
        }
    };

    const getRejectedBeneficiaries = React.useCallback(
        async (token, page, limit, queryParams) => {
            try {
                setLoader(true);
                const res = await BeneficiaryService.getBeneficiaries({
                    token,
                    page,
                    limit,
                    queryParams,
                    url: BENEFICIARIES_REJECTED_URL,
                });
                setRejectedBeneficiaries(res.data.beneficiaries);
                setRejectedBeneficiariesTotalPage(res.data.totalPages);
                setLoader(false);
            } catch (error) {
                setLoader(false);
                if (error.response.status === 404) {
                    setRejectedBeneficiaries([]);
                }
                showToast(error.response.data.message, true);
            }
        },
        [setLoader, showToast]
    );

    const patchBeneficiaryRequestApproval = async (token, id) => {
        try {
            setLoader(true);
            const res =
                await BeneficiaryService.patchBeneficiaryRequestApproval(
                    token,
                    id
                );
            if (res.status) {
                setLoader(false);
                showToast(res.data.message);
                return res.status;
            }
        } catch (error) {
            setLoader(false);
        }
    };

    const createBeneficiary = async (token, data) => {
        // console.log("call from context");
        try {
            setLoader(true);
            const res = await BeneficiaryService.createBeneficiary(token, data);
            if (res.status) {
                setLoader(false);
                showToast(res.data.message);
                return res.status;
            }
        } catch (error) {
            setLoader(false);
            showToast(error.response.data.message);
        }
    };

    const updateBeneficiary = async (token, data, id) => {
        try {
            setLoader(true);
            const res = await BeneficiaryService.updateBeneficiary(
                token,
                data,
                id
            );
            if (res.status) {
                setLoader(false);
                showToast(res?.data?.message);
                return res.status;
            }
        } catch (e) {
            showToast(e?.response?.data?.message);
        } finally {
            setLoader(false);
        }
    };

    const getFailedUploadedExcelFiles = React.useCallback(
        async (token, page, limit) => {
            try {
                setLoader(true);
                const res =
                    await BeneficiaryService.getFailedUploadedExcelFiles(
                        token,
                        page,
                        limit
                    );
                setFailedExcelData(res.data.files);
                setFailedExcelTotal(res.data.totalPages);
                setLoader(false);
            } catch (error) {
                setLoader(false);
                showToast(error.response.data.message, true);
            }
        },
        [setLoader, showToast]
    );
    const getSuccessfullyUplodedExcelFiles = useCallback(
        async (token, page, limit) => {
            try {
                setLoader(true);
                const res =
                    await BeneficiaryService.getSuccessfullyUploadedExcelFiles(
                        token,
                        page,
                        limit
                    );
                if (res.status) {
                    setLoader(false);
                    // console.log(res);
                    setSuccessfullyUploadedExcel(res?.data.files);
                    setSuccessfulUploadedExcelTotalPages(res?.data.totalPages);
                }
            } catch (e) {
            } finally {
                setLoader(false);
            }
        },
        [
            setLoader,
            setSuccessfullyUploadedExcel,
            setSuccessfulUploadedExcelTotalPages,
        ]
    );

    const getDownloadFiles = async (token, fileLink) => {
        try {
            const res = await BeneficiaryService.getDownlaodExcelFile(
                token,
                fileLink
            );
            if (res.status) {
                const downloadAnchor = document.createElement("a");
                downloadAnchor.href = res.data.url;
                downloadAnchor.download = "downloaded_file.ext";
                downloadAnchor.style.display = "none";
                document.body.appendChild(downloadAnchor);
                downloadAnchor.click();
                document.body.removeChild(downloadAnchor);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getBeneficiaryExport = async (token, selectedItems, selectedType) => {
        try {
            setLoader(true);
            const response = await BeneficiaryService.getBeneficiaryExport(
                token,
                selectedItems,
                selectedType
            );

            // Create a download link
            const a = document.createElement("a");
            a.href = response.data.url;
            a.download = "report.xlsx"; // Specify the desired file name
            a.style.display = "none";

            // Simulate a click on the download link
            document.body.appendChild(a);
            a.click();

            // Cleanup
            document.body.removeChild(a);

            showToast(response.data.message);
            setLoader(false);
            return response.status;
        } catch (error) {
            showToast(error.response.data.message);
            setLoader(false);
        }
    };

    return (
        <BeneficiaryContext.Provider
            value={{
                getVillageData,
                villages,
                getBeneficiaries,
                beneficiaries,
                totalPages,
                getBeneficiariesById,
                singleBeneficiaries,
                setSingleBeneficiaries,
                allBeneficiaryParams,
                setAllBeneficiaryParams,
                approveBeneficiaries,
                rejectBeneficiaries,
                approvedBeneficiaries,
                getApprovedBeneficiaries,
                getPendingBeneficiaries,
                approveBeneficiariesTotalPage,
                approveBeneficiaryById,
                getRejectedBeneficiaries,
                rejectedBeneficiaries,
                rejectedBeneficiariesTotalPage,
                allBeneficiaries,
                allBeneficiariesTotal,
                pendingBeneficiaries,
                pendingBeneficiariesTotalPages,
                getAllBeneficiaries,
                uploadBeneficiaries,
                setLoader,
                approveBeneficiaryParams,
                setApproveBeneficiaryParams,
                approvedBeneficiaryParams,
                setApprovedBeneficiaryParams,
                rejectedBeneficiaryParams,
                setRejectedBeneficiaryParams,
                patchBeneficiaryRequestApproval,
                awaitingApprovalBeneficiaries,
                setAwaitingApprovalBeneficiaries,
                getAwaitingApprovalBeneficiaries,
                awaitingApprovalBeneficiariesTotalPages,
                setAwaitingApprovalBeneficiariesTotalPages,
                unionBeneficiaryParams,
                setUnionBeneficiaryParams,
                createBeneficiary,
                updateBeneficiary,
                setFailedExcelParams,
                failedExcelParams,
                getFailedUploadedExcelFiles,
                failedExcelData,
                failedExcelTotal,
                successfullyUploadedExcelParams,
                setSuccessfullyUploadedExcelParams,
                successfullyUploadedExcel,
                setSuccessfullyUploadedExcel,
                getSuccessfullyUplodedExcelFiles,
                getDownloadFiles,
                successfulUploadedExcelTotalPages,
                setAllBeneficiaries,
                getBeneficiaryExport,
            }}
        >
            {children}
        </BeneficiaryContext.Provider>
    );
};

export default withToast(BeneficiaryProvider);
