import { Outlet, useParams } from "react-router-dom";
import Buttons from "../../components/Buttons";
import Layouts from "../../layouts";
import UnionUpazillaModal from "../../components/Common/UnionUpazillaModal";
import { useState } from "react";
import { useUserContext } from "../../context/UserProvider";
import { useREportContext } from "../../context/ReportPRovider";
import {
    APPROVED_BENEFICIARIES_REPORT_DOWNLOAD_URL,
    BENEFICIARIES_REPORT_DOWNLOAD_URL,
    DEALERS_REPORT_DOWNLOAD_URL,
    DISTRIBUTION_REPORT_DOWNLOAD_URL,
    DISTRIBUTION_SCHEDULE_REPORT_DOWNLOAD_URL,
    GENERATED_CARD_REPORT_DOWNLOAD_URL,
} from "../../helpers/APIURL";

const ReportGenerate = () => {
    const { id } = useParams();
    const { user } = useUserContext();
    const { getDownloadedReport } = useREportContext();

    const [showModal, setShowModal] = useState(false);
    const [activeUrl, setActiveUrl] = useState("");
    const [selectedItems, setSelectedItems] = useState({
        area: "all",
        fields: [],
        format: "xlsx",
        reportType: "",
        range: "daily",
        union: "",
        upozila: "রায়পুরা",
    });

    const handleShowModal = () => {
        setShowModal(!showModal);
        setSelectedItems((prev) => ({
            area: "all",
            fields: [],
            format: "xlsx",
            reportType: "",
            range: "daily",
            union: "",
            upozila: "রায়পুরা",
        }));
    };

    const handleGenerate = (title, url) => {
        setShowModal(true);
        setSelectedItems((prevSelectedItems) => ({
            ...prevSelectedItems,
            reportType: title,
        }));
        setActiveUrl(url);
    };

    const handleSubmit = async (selectedItems) => {
        const params = {};
        params.range = selectedItems.range;
        if (selectedItems.area === "all" || selectedItems.area === "upazila") {
            params.upazila = "রায়পুরা";
        } else if (selectedItems.area === "union") {
            params.union = selectedItems.union;
        }
        const res = await getDownloadedReport(user.token, activeUrl, params);
        if (res === 200) {
            setShowModal(false);
            setShowModal(false);
        }
    };

    return (
        <>
            {id ? (
                <Outlet />
            ) : (
                <div className="w-full flex justify-center items-center">
                    <div className="w-[1032px] py-12 px-10 flex items-center flex-col">
                        <div className="w-full bg-slate-100 py-3 px-6">
                            <div className="flex justify-between items-center">
                                <h1 className="text-slate-800 text-sm font-semibold my-3">
                                    সকল রিপোর্ট লিস্ট
                                </h1>
                            </div>
                        </div>
                        <div className="w-full bg-white">
                            {data.map((item, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        className="py-[18px] px-6 flex justify-between items-center"
                                    >
                                        <h1 className="text-blue-900 text-sm font-normal leading-[22px]">
                                            {item.title}
                                        </h1>
                                        <Buttons.Primary
                                            label="রিপোর্ট জেনারেট"
                                            paddingX="px-6"
                                            paddingY="py-2"
                                            textSize="text-[13px]"
                                            fontWeight="font-bold"
                                            onClick={() =>
                                                handleGenerate(
                                                    item.title,
                                                    item.url
                                                )
                                            }
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}

            {showModal && (
                <Layouts.Modal
                    overlayVariant="light-gray"
                    setOpen={handleShowModal}
                >
                    <UnionUpazillaModal
                        setOpen={handleShowModal}
                        handleSubmit={handleSubmit}
                        setSelectedItems={setSelectedItems}
                        selectedItems={selectedItems}
                    />
                </Layouts.Modal>
            )}
        </>
    );
};

export default ReportGenerate;

const data = [
    { title: "উপকারভোগীর রিপোর্ট", url: BENEFICIARIES_REPORT_DOWNLOAD_URL },
    {
        title: "অনুমোদিত উপকারভোগীর রিপোর্ট",
        url: APPROVED_BENEFICIARIES_REPORT_DOWNLOAD_URL,
    },
    {
        title: "জেনারেটেড কার্ডস রিপোর্ট",
        url: GENERATED_CARD_REPORT_DOWNLOAD_URL,
    },
    { title: "ডিলার রিপোর্ট", url: DEALERS_REPORT_DOWNLOAD_URL },
    { title: "বিতরন রিপোর্ট", url: DISTRIBUTION_REPORT_DOWNLOAD_URL },
    { title: "বিতরন সময়সূচী ", url: DISTRIBUTION_SCHEDULE_REPORT_DOWNLOAD_URL },
];
