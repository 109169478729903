import React from "react";

const NoDataFound = ({ message = "এই মুহূর্তে কোনো ডেটা সংরক্ষিত নেই" }) => {
    return (
        <div className="w-full py-12 bg-[#EFF9F9] text-green-600 text-lg font-medium flex justify-center">
            {message}
        </div>
    );
};

export default NoDataFound;
