import React, { createContext, useContext, useState } from "react";
import { withToast } from "../hoc/withToast";
import ImageUploaderService from "../services/imageUploaderService";

const ImageContext = createContext({});
export const useImageContext = () => {
    return useContext(ImageContext);
};

const ImageProvider = ({ showToast, children }) => {
    const [getImageUploaderResponse, setImageUploaderResponse] = useState(null);
    const [disableButton, setDisableButton] = useState(false);

    const imageUpload = async (token, image) => {
        setDisableButton(true);
        try {
            const res = await ImageUploaderService.uploadImage(token, image);
            if (res) {
                setImageUploaderResponse(res.data.key);
                showToast(res.data.message);
                setDisableButton(false);
            }
            return res;
        } catch (e) {
            showToast("Something went wrong !");
        } finally {
            setDisableButton(false);
        }
    };

    const values = {
        getImageUploaderResponse,
        setImageUploaderResponse,
        imageUpload,
        disableButton,
    };
    return (
        <ImageContext.Provider value={values}>{children}</ImageContext.Provider>
    );
};
export default withToast(ImageProvider);
