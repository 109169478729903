import React from "react";
import Icons from "../../assets/Icons";

const Secondary = () => {
    return (
        <div className="py-6 px-20 bg-blue-950 flex items-center justify-between">
            <div className="flex items-center gap-x-2.5">
                <img
                    src={Icons.bd_gov_logo}
                    alt="bd_gov_logo"
                    className="w-20 h-20"
                />
                <div className="flex flex-col items-center">
                    <h1 className="text-xl font-bold text-white">
                        উপজেলা প্রশাসন
                    </h1>
                    <h1 className="text-lg font-bold text-white">
                        রায়পুরা,নরসিংদি
                    </h1>
                </div>
            </div>

            <div className="flex items-center gap-x-2.5">
                <div>
                    <h1 className="text-xl font-bold text-white w-72 text-right">
                        টিসিবি ম্যানেজমেন্ট সিস্টেম
                    </h1>
                </div>
                <img
                    src={Icons.tcb_logo}
                    alt="bd_gov_logo"
                    className="w-20 h-20"
                />
            </div>
        </div>
    );
};

export default Secondary;
