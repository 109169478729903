import React, { useState, useEffect } from "react";
import TableHeader from "../../components/Common/TableHeader";
import Table from "../../components/Table";
import TableFooter from "../../components/Common/TableFooter";
import Tooltip from "../../layouts/Tooltip";
import IconWithLabel from "../../components/Buttons/IconWithLabel";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import Icons from "../../assets/Icons";
import { useUserContext } from "../../context/UserProvider";
import { useDistributionContext } from "../../context/distributionProvider";
import { useNavigate } from "react-router-dom";
import Buttons from "../../components/Buttons";
import { useBeneficiaryContext } from "../../context/BeneficiaryProvider";
import Filter from "../../components/Filter";

const DistributionInfo = () => {
    const navigate = useNavigate();
    const {
        getAllDistributionList,
        distributionParams,
        setDistributionParams,
        handleGetAllDistributionList,
        getAllDistributionListTotal,
        deleteDistributionSchedule,
    } = useDistributionContext();
    const { getVillageData, villages } = useBeneficiaryContext();

    const { user } = useUserContext();

    const [selectedItems, setSelectedItems] = useState([]);
    const [activeTooltip, setActiveTooltip] = useState("");
    const [firstRender, setFirstRender] = useState(true);

    useEffect(() => {
        setDistributionParams({
            page: 1,
            limit: 10,
            startDate: null,
            endDate: null,
            status: null,
            union: null,
            search: null,
        });
    }, [setDistributionParams]);

    useEffect(() => {
        if (user && firstRender) {
            getVillageData(user.token);
            handleGetAllDistributionList(
                user.token,
                distributionParams.page,
                distributionParams.limit,
                {
                    page: 1,
                    limit: 10,
                    startDate: null,
                    endDate: null,
                    status: null,
                    union: null,
                    search: null,
                }
            );
            setFirstRender(false);
            setDistributionParams({
                page: 1,
                limit: 10,
                startDate: null,
                endDate: null,
                status: null,
                union: null,
                search: null,
            });
        } else if (user) {
            getVillageData(user.token);
            handleGetAllDistributionList(
                user.token,
                distributionParams.page,
                distributionParams.limit,
                distributionParams
            );
        }
    }, [
        user,
        distributionParams,
        handleGetAllDistributionList,
        firstRender,
        setDistributionParams,
        getVillageData,
    ]);

    useEffect(() => {
        setActiveTooltip("");
    }, [navigate]);

    const handleDeleteDistributionSchedule = async (id) => {
        const response = await deleteDistributionSchedule(user.token, id);

        if (response === 200) {
            handleGetAllDistributionList(
                user.token,
                distributionParams.page,
                distributionParams.limit,
                distributionParams
            );
            setSelectedItems([]);
        }
    };

    const renderActionComponents = (data) => {
        return (
            <>
                <td>
                    <div className="px-4  relative">
                        <button onClick={() => setActiveTooltip(data._id)}>
                            <EllipsisVerticalIcon className="w-8 h-8 text-slate-700" />
                        </button>
                        {activeTooltip === data._id && (
                            <Tooltip
                                top={
                                    getAllDistributionList
                                        .slice(-2)
                                        .some((item) => item._id === data._id)
                                        ? getAllDistributionList.length >= 3
                                            ? "-top-28"
                                            : "-top-[1.5rem]"
                                        : "-top-3"
                                }
                                right="right-14"
                                overlayVariant="transparent"
                                setOpen={setActiveTooltip}
                                align={
                                    getAllDistributionList
                                        .slice(-2)
                                        .some((item) => item._id === data._id)
                                        ? getAllDistributionList.length >= 3
                                            ? "bottom"
                                            : "top"
                                        : "top"
                                }
                            >
                                <div className="p-2 bg-white rounded w-[220px]">
                                    <IconWithLabel
                                        icon={Icons.file}
                                        label="বিস্তারিত দেখুন"
                                        paddingY="py-2.5"
                                        // paddingX="px-5"
                                        width="w-[180px]"
                                        onClick={() =>
                                            navigate(
                                                `/distribution-schedule/${data._id}`
                                            )
                                        }
                                    />
                                    <IconWithLabel
                                        icon={Icons.edit}
                                        label="পরিবর্তন করুন "
                                        paddingY="py-2.5 hover:bg-gray-50"
                                        // paddingX="px-5"
                                        width="w-[180px]"
                                        onClick={() =>
                                            navigate(
                                                `/distribution-schedule/update/${data._id}`
                                            )
                                        }
                                    />
                                    <IconWithLabel
                                        icon={Icons.delete_icon}
                                        label="ডিলিট করুন"
                                        paddingY="py-2.5 hover:bg-gray-50"
                                        // paddingX="px-2 hover:bg-gray-50"
                                        width="w-[180px]"
                                        onClick={() => {
                                            handleDeleteDistributionSchedule(
                                                data._id
                                            );
                                        }}
                                    />
                                </div>
                            </Tooltip>
                        )}
                    </div>
                </td>
            </>
        );
    };

    return (
        <div>
            <div className=" bg-neutral-00 overflow-y-auto py-12 px-10 flex items-center flex-col ">
                <div className="py-12 px-10 flex items-center flex-col">
                    <div className="py-2 w-full flex justify-start">
                        <Buttons.IconWithLabel
                            label="নতুন বিতরণ তৈরি"
                            paddingX="px-3"
                            paddingY="py-2.5"
                            icon={Icons.add_white}
                            bgColor="bg-green-600"
                            radius="rounded-[2px]"
                            textColor="text-white"
                            textSize={"font-bold"}
                            onClick={() =>
                                navigate("/distribution-schedule/create")
                            }
                        />
                    </div>
                    <div className="shadow-medium-gray rounded-lg overflow-hidden ">
                        {/* <div className="p-6 bg-white flex gap-x-4 items-center justify-between">
                            <div className="flex flex-row gap-3">
                                <FormFields.LegendDateField
                                    setQueryParams={setDistributionParams}
                                />
                                <FormFields.LegendSelectField
                                    data={[]}
                                    label={"ঠিকানা"}
                                    setQueryParams={setDistributionParams}
                                    name="union"
                                />
                            </div>
                            <div className="w-[60%]">
                                <FormFields.SearchField
                                    placeholder="উপকারভোগী সার্চ করুন"
                                    setQueryParams={setDistributionParams}
                                />
                            </div>
                        </div> */}

                        <Filter.Primary
                            // firstSelectData={approvedStatusDropdown}
                            // firstSelectLabel="অনুমোদন স্ট্যাটাস"
                            secondSelectLabel="ঠিকানা"
                            secondSelectData={villages ? villages : []}
                            setQueryParams={setDistributionParams}
                            futureDateSelectable={true}
                        />

                        <TableHeader
                            selectedItems={selectedItems}
                            setSelectedItems={setSelectedItems}
                            title={"সকল বিতরণের লিস্ট"}
                            selectedTitle={"বিতরণ সিলেঙ্কট করা হয়েছে"}
                        >
                            <>
                                <Buttons.Primary
                                    label={"ডিলিট"}
                                    onClick={() => {
                                        handleDeleteDistributionSchedule(
                                            selectedItems
                                        );
                                    }}
                                    paddingX="px-7"
                                    paddingY="py-2"
                                    fontWeight="font-bold"
                                    bgColor="bg-red-500"
                                />
                            </>
                        </TableHeader>

                        <Table.Primary
                            // tableHeads={["উপকারভোগীর নাম", "পরিচয় পত্র নং", "ঠিকানা", "মোবাইল"]}
                            tableHeads={tableHeadsData}
                            //   tableDataKeys={["name", "nid", "village", "mobile"]}
                            tableDataKeys={tableDataKeys}
                            //   // tableActionHeads={["কার্ড জেনারেট অনুরোধ অনুমোদন", ""]}
                            tableActionHeads={tableActionHeads}
                            tableData={getAllDistributionList || []}
                            tableActionComponents={renderActionComponents}
                            setSelectedItems={setSelectedItems}
                            selectedItems={selectedItems}
                            dataLength={getAllDistributionList?.length}
                            needMinHeight={true}
                        />
                        <TableFooter
                            params={distributionParams}
                            setParams={setDistributionParams}
                            pageSize={getAllDistributionListTotal}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DistributionInfo;

const tableHeadsData = [
    {
        title: "ডিলারের নাম",
        align: "left",
        width: "w-[25%]",
    },
    {
        title: "মোবাইল",
        align: "left",
        width: "w-[25%]",
    },
    {
        title: "বিতরণের ইউনিয়ন",
        align: "left",
        width: "w-[25%]",
    },
    {
        title: "বিতরণের তারিখ",
        align: "left",
        width: "w-[25%]",
    },
];

const tableDataKeys = [
    {
        title: "dealerName",
        width: "w-[250px]",
    },
    {
        title: "dealerMobile",
        width: "w-[250px]",
    },
    {
        title: "union",
        width: "w-[250px]",
    },
    {
        title: "date",
        width: "w-[250px]",
    },
];

const tableActionHeads = [
    {
        title: "",
        align: "center",
        width: "w-16",
    },
];
