import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import bn from "date-fns/locale/bn";
import FormFields from "../../components/FormFields";
import Buttons from "../../components/Buttons";
import { useCardContext } from "../../context/CardProvider";
import Icons from "../../assets/Icons";
import ImageUploader from "../../components/Common/ImageUploader";
import { removeEmptyProperties, range, checkDobFormat } from "../../utils";
import { parse } from "date-fns";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { getMonth, getYear } from "date-fns";

const BeneficiaryForm = ({
    villages,
    singleUser,
    create,
    handleCreate,
    handleUpdate,
    isAllBeneficiaries,
    isDetails,
    type,
    selectedImage,
    setSelectedImage,
}) => {
    const navigate = useNavigate();
    const getEdit = false;
    const [getSaveEdit, setSaveEdit] = useState(false);
    const [union, setUnion] = useState(null);
    const [openOnce, setOpenOnce] = useState(null);
    const [isUnionReset, setIsUnionReset] = useState(false);

    const { setSingleBeneficiaries } = useCardContext();
    // for image uploader.
    // const [selectedImage, setSelectedImage] = React.useState({
    //     preview: "",
    //     raw: "",
    // });

    const years = range(1900, getYear(new Date()) + 1, 1);
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const customLocale = {
        ...bn, // Include the base Bengali locale
        options: { ...bn.options, weekStartsOn: 0 },
    };

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm();

    // console.log("user-----------", singleUser);

    const onSubmit = async (data) => {
        if (create) {
            if (union) {
                data.union = union;
                await handleCreate(data);
                setUnion(null);
                setIsUnionReset(true);
                reset();
            } else {
                errors.union = true;
                setOpenOnce(true);
            }
        }
        // --- use Extra state for prevent unnecessary call --- //
        if (getEdit && getSaveEdit) {
            await handleUpdate(removeEmptyProperties(data));
        }
    };

    const handleBackNav = () => {
        setSingleBeneficiaries({});
        navigate(-1);
    };

    const handleUnion = (value) => {
        setUnion(value);
    };

    return (
        <>
            <ImageUploader
                defaultImage={singleUser?.image}
                create={create}
                type={type}
                singleUser={singleUser}
                isDetails={isDetails}
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
            />
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="mt-9 mb-4 flex flex-col w-full"
            >
                <div className="w-full">
                    <div className="flex flex-col xs:flex-col sm:flex-row md:flex-row lg:flex-row flex-wrap gap-10 items-start justify-between w-full py-10">
                        <Box>
                            <h6 className="text-slate-800 font-medium text-base mb-2">
                                উপকারভোগীর নাম
                            </h6>
                            <FormFields.Text
                                register={register}
                                defaultValue={create ? "" : singleUser?.name}
                                errors={errors}
                                name="name"
                                width="w-full"
                                isReadOnly={
                                    create
                                        ? !create
                                        : !isDetails || getEdit
                                        ? false
                                        : true
                                }
                                validations={{
                                    required: true,
                                    pattern: { message: "required" },
                                }}
                                // errorMsg={errors?.name}
                                errorMsg="উপকারভোগীর নাম আবশ্যক"
                            />
                        </Box>
                        <Box>
                            <h6 className="text-slate-800 font-medium text-base mb-2">
                                জন্ম তারিখ
                            </h6>
                            <Controller
                                control={control}
                                name="dob"
                                defaultValue={
                                    checkDobFormat(singleUser?.dob)
                                        ? singleUser?.dob
                                        : null
                                }
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => (
                                    <>
                                        <fieldset className="border-[1.5px] border-[#919EAB] border-opacity-[32%] rounded-sm pb-[.8rem] pt-4 px-3.5">
                                            <DatePicker
                                                locale={customLocale}
                                                placeholderText="তারিখ" // Placeholder in Bengali
                                                dateFormat="dd/MM/yyyy" // Customize date format if needed
                                                className="text-base text-slate-800 leading-6 border-none outline-none w-[142px]"
                                                onChange={(date) =>
                                                    field.onChange(date)
                                                }
                                                maxDate={new Date()}
                                                selected={
                                                    singleUser?.dob &&
                                                    checkDobFormat(
                                                        singleUser?.dob
                                                    )
                                                        ? parse(
                                                              singleUser?.dob,
                                                              "dd/MM/yyyy",
                                                              new Date()
                                                          )
                                                        : field.value
                                                }
                                                readOnly={
                                                    create
                                                        ? !create
                                                        : !isDetails || getEdit
                                                        ? false
                                                        : true
                                                }
                                                renderCustomHeader={({
                                                    date,
                                                    changeYear,
                                                    changeMonth,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled,
                                                }) => (
                                                    <div
                                                        style={{
                                                            margin: 10,
                                                            display: "flex",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                        className="p-2 border-2 rounded-md"
                                                    >
                                                        <button
                                                            onClick={
                                                                decreaseMonth
                                                            }
                                                            disabled={
                                                                prevMonthButtonDisabled
                                                            }
                                                            className="w-5 hover:bg-slate-300 rounded-full"
                                                            type="button"
                                                        >
                                                            {"<"}
                                                        </button>
                                                        <select
                                                            value={getYear(
                                                                date
                                                            )}
                                                            onChange={({
                                                                target: {
                                                                    value,
                                                                },
                                                            }) =>
                                                                changeYear(
                                                                    value
                                                                )
                                                            }
                                                        >
                                                            {years.map(
                                                                (option) => (
                                                                    <option
                                                                        key={
                                                                            option
                                                                        }
                                                                        value={
                                                                            option
                                                                        }
                                                                    >
                                                                        {option}
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>

                                                        <select
                                                            value={
                                                                months[
                                                                    getMonth(
                                                                        date
                                                                    )
                                                                ]
                                                            }
                                                            onChange={({
                                                                target: {
                                                                    value,
                                                                },
                                                            }) =>
                                                                changeMonth(
                                                                    months.indexOf(
                                                                        value
                                                                    )
                                                                )
                                                            }
                                                            className="w-min"
                                                        >
                                                            {months.map(
                                                                (option) => (
                                                                    <option
                                                                        key={
                                                                            option
                                                                        }
                                                                        value={
                                                                            option
                                                                        }
                                                                    >
                                                                        {option}
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>

                                                        <button
                                                            onClick={
                                                                increaseMonth
                                                            }
                                                            disabled={
                                                                nextMonthButtonDisabled
                                                            }
                                                            className="w-5 hover:bg-slate-300 rounded-full"
                                                            type="button"
                                                        >
                                                            {">"}
                                                        </button>
                                                    </div>
                                                )}
                                            />
                                        </fieldset>
                                        <span
                                            className={`text-xs flex items-center mt-1 text-red-600 ${
                                                errors.dob
                                                    ? "visible"
                                                    : "invisible"
                                            }`}
                                        >
                                            <ExclamationCircleIcon className="w-4 h-4 mr-1.5" />{" "}
                                            <span className="mt-0.5">
                                                জন্ম তারিখ আবশ্যক
                                            </span>
                                        </span>
                                    </>
                                )}
                            />
                            {/* <span
                            className={`text-xs flex items-center mt-3 text-red-600 ${
                                errors[name] ? "visible" : "invisible"
                            }`}
                        >
                            <ExclamationCircleIcon className="w-4 h-4 mr-1.5" />{" "}
                            <span className="mt-0.5">{errorMsg}</span>
                        </span> */}
                        </Box>
                        <Box>
                            <h6 className="text-slate-800 font-medium text-base mb-2">
                                পরিচয় পত্র নং
                            </h6>
                            <FormFields.Text
                                register={register}
                                defaultValue={create ? "" : singleUser?.nid}
                                errors={errors}
                                name="nid"
                                width="w-full"
                                isReadOnly={
                                    create
                                        ? !create
                                        : !isDetails || getEdit
                                        ? false
                                        : true
                                }
                                validations={{
                                    required: true,
                                }}
                                errorMsg="উপকারভোগীর পরিচয় পত্র নং আবশ্যক"
                            />
                        </Box>
                        <Box className=" justify-self-start">
                            <h6 className="text-slate-800 font-medium text-base mb-2 mt-6">
                                স্বামী / স্ত্রীর নাম
                            </h6>
                            <FormFields.Text
                                register={register}
                                defaultValue={
                                    create ? null : singleUser?.spouseName
                                }
                                errors={errors}
                                name="spouseName"
                                width="w-full"
                                isReadOnly={
                                    create
                                        ? !create
                                        : !isDetails || getEdit
                                        ? false
                                        : true
                                }
                            />
                        </Box>
                        <Box className=" justify-self-start">
                            <h6 className="text-slate-800 font-medium text-base mb-2 mt-6">
                                পিতার নাম
                            </h6>
                            <FormFields.Text
                                register={register}
                                defaultValue={
                                    create ? null : singleUser?.fatherName
                                }
                                errors={errors}
                                name="fatherName"
                                width="w-full"
                                isReadOnly={
                                    create
                                        ? !create
                                        : !isDetails || getEdit
                                        ? false
                                        : true
                                }
                            />
                        </Box>
                        <Box>
                            <h6 className="text-slate-800 font-medium text-base mb-2 mt-6">
                                পিতা পরিচয় পত্র নং
                            </h6>
                            <FormFields.Text
                                register={register}
                                defaultValue={
                                    create ? "" : singleUser?.fatherNID
                                }
                                errors={errors}
                                name="fatherNID"
                                width="w-full"
                                isReadOnly={
                                    create
                                        ? !create
                                        : !isDetails || getEdit
                                        ? false
                                        : true
                                }
                            />
                        </Box>
                        <Box>
                            <h6 className="text-slate-800 font-medium text-base mb-2 mt-6">
                                স্বামীর / স্ত্রীর পরিচয় পত্র নং
                            </h6>
                            <FormFields.Text
                                register={register}
                                defaultValue={
                                    create ? "" : singleUser?.spouseNID
                                }
                                errors={errors}
                                name="spouseNID"
                                width="w-full"
                                isReadOnly={
                                    create
                                        ? !create
                                        : !isDetails || getEdit
                                        ? false
                                        : true
                                }
                            />
                        </Box>
                        <Box>
                            <h6 className="text-slate-800 font-medium text-base mb-2 mt-6">
                                ঠিকানা
                            </h6>
                            <FormFields.Text
                                register={register}
                                defaultValue={create ? "" : singleUser?.village}
                                errors={errors}
                                name="village"
                                width="w-full"
                                isReadOnly={
                                    create
                                        ? !create
                                        : !isDetails || getEdit
                                        ? false
                                        : true
                                }
                            />
                        </Box>

                        <Box>
                            <h6 className="text-slate-800 font-medium text-base mb-2 mt-6">
                                পেশা
                            </h6>
                            <FormFields.Text
                                register={register}
                                defaultValue={
                                    create ? "" : singleUser?.occupation
                                }
                                errors={errors}
                                name="occupation"
                                width="w-full"
                                isReadOnly={
                                    create
                                        ? !create
                                        : !isDetails || getEdit
                                        ? false
                                        : true
                                }
                            />
                        </Box>
                        <Box>
                            <h6 className="text-slate-800 font-medium text-base mb-2 mt-6">
                                মোবাইল
                            </h6>
                            <FormFields.Text
                                register={register}
                                defaultValue={create ? "" : singleUser?.mobile}
                                errors={errors}
                                name="mobile"
                                width="w-full"
                                isReadOnly={
                                    create
                                        ? !create
                                        : !isDetails || getEdit
                                        ? false
                                        : true
                                }
                            />
                        </Box>
                        <Box className=" justify-self-start">
                            <h6 className="text-slate-800 font-medium text-base mb-2 mt-6">
                                ওয়ার্ড
                            </h6>
                            <FormFields.Text
                                register={register}
                                defaultValue={create ? null : singleUser?.ward}
                                errors={errors}
                                name="ward"
                                width="w-full"
                                isReadOnly={
                                    create
                                        ? !create
                                        : !isDetails || getEdit
                                        ? false
                                        : true
                                }
                            />
                        </Box>

                        <Box className="col-span-1 mt-6">
                            <h6 className="text-slate-800 font-medium text-base mb-2">
                                ইউনিয়ন সিলেক্ট করুন
                            </h6>
                            <FormFields.SelectField
                                isUnionReset={isUnionReset}
                                data={villages ? villages : []}
                                placeholder="ইউনিয়ন সিলেক্ট করুন"
                                defaultValue={create ? "" : singleUser?.union}
                                handleSelectClick={handleUnion}
                                errors={errors}
                                openOnce={openOnce}
                                errorMsg="ইউনিয়ন সিলেক্ট বাধ্যতামূলক"
                                dropdownPosition="top-[56px]"
                                name="union"
                                width="w-full"
                                paddingY="py-3.5"
                                paddingX="px-5"
                                border="border border-slate-300"
                                radius="rounded"
                                dropDownHeight="max-h-48"
                                // openOnce={openOnce}
                                isReadOnly={
                                    create ? !create : getEdit ? !getEdit : true
                                }
                            />
                        </Box>
                    </div>
                    <div className="py-7 flex flex-col sm:flex-col md:flex-row justify-end gap-3 border-t border-[#919EAB1F] border-opacity-20">
                        <Buttons.Outline
                            borderColor="border-slate-400"
                            label="ফেরত যান"
                            textColor="text-slate-600"
                            fontWeight="font-bold"
                            textSize="text-sm"
                            paddingY="py-2.5"
                            paddingX="px-7"
                            onClick={handleBackNav}
                        />
                        {create && (
                            <Buttons.Primary
                                label="তৈরি করুন"
                                paddingX="px-3"
                                paddingY="py-2.5"
                                bgColor="bg-green-600"
                                radius="rounded-[2px]"
                                textColor="text-white"
                                textSize={"font-bold"}
                                type="submit"
                                // onClick={() => navigate("/beneficiaries/create")}
                            />
                        )}
                        {!create && isDetails && isAllBeneficiaries ? (
                            <Buttons.Primary
                                label="এডিট করুন"
                                paddingX="px-3"
                                paddingY="py-2.5"
                                width="w-[120px]"
                                bgColor="bg-green-600"
                                radius="rounded-[2px]"
                                textColor="text-white"
                                textSize={"font-bold"}
                                onClick={() => {
                                    navigate(
                                        `/beneficiaries/all/update/${singleUser._id}`
                                    );
                                }}
                            />
                        ) : !isAllBeneficiaries ? null : (
                            <Buttons.IconWithLabel
                                label="সেভ করুন"
                                paddingX="px-3"
                                paddingY="py-2.5"
                                width="w-[120px]"
                                icon={Icons.edit}
                                bgColor="bg-green-600"
                                radius="rounded-[2px]"
                                textColor="text-white"
                                textSize={"font-bold"}
                                type="submit"
                                onClick={() => setSaveEdit(true)}
                            />
                        )}
                    </div>
                </div>
            </form>
        </>
    );
};

export default BeneficiaryForm;

export const Box = ({ children, className }) => {
    return (
        <div
            className={`flex flex-col gap-2 w-[100%] sm:w-[100%] md:w-[29%] lg:w[30%] ${className}`}
        >
            {children}
        </div>
    );
};
