import React from "react";
import { useForm } from "react-hook-form";

import { Box } from "../../sections/BeneficiaryForm.jsx";
import FormFields from "../../components/FormFields/index.js";
import Buttons from "../../components/Buttons/index.js";
import { withToast } from "../../hoc/withToast";

const PasswordResetForm = ({ handlePasswordUpdate, showToast }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        if (data.password === data.reTypePassword) {
            // delete data.reTypePassword;
            handlePasswordUpdate(data);
        } else {
            showToast(
                "নতুন পাসওয়ার্ড, পুনরায় নতুন পাসওয়ার্ড ফিল্ডের সাথে মেলেনি !",
                true
            );
        }
    };
    return (
        <form
            className="mt-9 mb-4 flex flex-col w-full"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="flex flex-col items-end justify-center p-10 gap-10 ">
                <div className="flex flex-row gap-x-4 w-full">
                    <Box className="w-[450px]">
                        <h6 className="text-slate-800 font-medium text-base mb-2">
                            নতুন পাসওয়ার্ড
                        </h6>
                        <FormFields.Password
                            register={register}
                            defaultValue={""}
                            errors={errors}
                            name="reTypePassword"
                            width="w-full"
                            validations={{
                                pattern:
                                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^!&*])[A-Za-z0-9@#$%^!&*]{6,}$/,
                                required: true,
                            }}
                            // isReadOnly={create ? !create : getEdit ? !getEdit : true}
                            // validations={{ required: true, one: "asdf" }}
                            errorMsg={
                                "পাসওয়ার্ড নির্দিষ্ট ক্রাইটেরিয়া অনুসরণ করেনি অথবা  পাসওয়ার্ড মিলেনি"
                            }
                        />
                    </Box>
                    <Box className="w-[450px]">
                        <h6 className="text-slate-800 font-medium text-base mb-2">
                            পুনরায় নতুন পাসওয়ার্ড
                        </h6>
                        <FormFields.Password
                            register={register}
                            defaultValue={""}
                            errors={errors}
                            name="password"
                            width="w-full"
                            validations={{
                                pattern:
                                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^!&*])[A-Za-z0-9@#$%^!&*]{6,}$/,
                                required: true,
                            }}
                            // isReadOnly={create ? !create : getEdit ? !getEdit : true}
                            // validations={{ required: true, one: "asdf" }}
                            errorMsg={"পাসওয়ার্ড মিলেনি"}
                        />
                    </Box>
                </div>
                <Buttons.IconWithLabel
                    label="আপডেট করুন"
                    paddingX="px-3"
                    paddingY="py-2.5"
                    bgColor="bg-green-600"
                    radius="rounded-[2px]"
                    textColor="text-white"
                    textSize={"font-bold"}
                    type="submit"
                />
            </div>
        </form>
    );
};

export default withToast(PasswordResetForm);
