import axios from "axios";
import { USER_MANAGEMENT_URL, USER_MANAGEMENT_CREATE_URL, USER_MANAGEMENT_UPDATE_URL, USER_MANAGEMENT_DELETE_URL, USER_MANAGEMENT_RESET_PASSWORD_URL } from "../helpers/APIURL";

const getUsers = ({ token, page = 1, limit = 10, queryParams, url = USER_MANAGEMENT_URL }) => {
    const config = {
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
        params: {
            page: page,
            limit: limit,
            ...(queryParams.role ? { role: queryParams.role } : {}),
            ...(queryParams.startDate ? { startDate: queryParams.startDate } : {}),
            ...(queryParams.endDate ? { endDate: queryParams.endDate } : {}),
            ...(queryParams.union ? { union: queryParams.union } : {}),
            ...(queryParams.search ? { search: queryParams.search } : {}),
        },
    };
    return axios.get(url, config);
};

const getUsersById = (token, id) => {
    const config = {
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
        params: {
            id: id,
        },
    };
    return axios.get(USER_MANAGEMENT_URL, config);
};

const createUser = (token, data) => {
    const config = {
        headers: {
            Authorization: token,
        },
    };
    return axios.post(USER_MANAGEMENT_CREATE_URL, data, config);
};

const updateUser = (token, data, id) => {
    const config = {
        params: {
            id,
        },
        headers: {
            Authorization: token,
        },
    };
    return axios.patch(`${USER_MANAGEMENT_UPDATE_URL}`, data, config);
};

const resetUserPassword = (token, data, id) => {
    const config = {
        params: {
            id,
        },
        headers: {
            Authorization: token,
        },
    };
    return axios.patch(`${USER_MANAGEMENT_RESET_PASSWORD_URL}`, data, config);
};

const deleteUsers = (token, id) => {
    // console.log(token, id);
    let formattedId = id;

    if (Array.isArray(id)) {
        formattedId = id.map((id) => `id=${id}`).join("&"); // Convert array to comma-separated string
        return axios.delete(`${USER_MANAGEMENT_DELETE_URL}?${formattedId}`, {
            headers: {
                Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
            },
        });
    }

    const config = {
        params: {
            id: formattedId,
        },
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
    };
    return axios.delete(USER_MANAGEMENT_DELETE_URL, config);
};

const UserManagementService = {
    getUsers,
    createUser,
    getUsersById,
    updateUser,
    deleteUsers,
    resetUserPassword,
};
export default UserManagementService;
