import React, { useEffect, useState } from "react";
import Tooltip from "../../layouts/Tooltip";
import Icons from "../../assets/Icons";
import { translateENToBN } from "../../utils";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

const ModalSelectField = ({
    data,
    label,
    name,
    setQueryParams,
    width,
    register,
    validations,
    defaultValue,
    placeholder,
    paddingX,
    paddingY,
    textSize,
    fontWeight,
    border,
    radius,
    dropdownPosition,
    setSelectedItems,
    placeHolder,
    handleSelectClick,
    openOnce,
    errorMsg,
}) => {
    const [selected, setSelected] = useState("");
    const [open, setOpen] = useState(false);
    // console.log("deflst---------", openOnce, selected);

    useEffect(() => {
        setSelected(translateENToBN(defaultValue));
    }, [defaultValue]);

    const handleClick = (value) => {
        setSelected(value);
        handleSelectClick && handleSelectClick(value);
        setOpen(!open);
    };
    return (
        <div className="relative">
            <fieldset
                className={`${border} ${radius} ${paddingX} ${paddingY} flex items-center`}
            >
                {/* <legend className="text-[#919EAB] text-xs font-medium">
                    {label}
                </legend> */}
                <input
                    type="text"
                    readOnly
                    value={selected}
                    placeholder={placeholder}
                    onClick={() => {
                        setOpen(!open);
                    }}
                    className={`${textSize} ${fontWeight} text-slate-800 leading-6 border-none outline-none w-full`}
                />

                <button
                    onClick={() => {
                        setOpen(!open);
                    }}
                >
                    <img
                        src={Icons.Dropdown}
                        alt=""
                        className={selected ? "rotate-180" : "rotate-0"}
                    />
                </button>
            </fieldset>
            <span
                className={`text-xs flex items-center mt-3 text-red-600 ${
                    openOnce && !selected ? "block" : "hidden"
                }`}
            >
                <ExclamationCircleIcon className="w-4 h-4 mr-1.5" />{" "}
                <span className="mt-0.5">ইউনিয়ন সিলেক্ট আবশ্যক</span>
            </span>

            {open && (
                <Tooltip
                    top="top-12"
                    right=""
                    overlayVariant="transparent"
                    setOpen={setOpen}
                    isIndicator={false}
                    frameWidth={"w-full"}
                >
                    <div className="p-2 bg-white rounded shadow-light-gray flex flex-col w-full max-h-[24rem] overflow-auto">
                        {data.map((item, index) => (
                            <button
                                key={index}
                                className="py-2 px-2 text-gray-700 text-sm bg-white hover:bg-gray-50 rounded text-left"
                                onClick={() => handleClick(item)}
                            >
                                {item}
                            </button>
                        ))}
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

export default ModalSelectField;
