import React from "react";
import { useCardContext } from "../../context/CardProvider";
import { useUserContext } from "../../context/UserProvider";
import Buttons from "../../components/Buttons";
import { useNavigate } from "react-router-dom";

const GenerateCard = ({ id }) => {
    const {
        getBeneficiariesListApprove,
        generateParams,
        postCardsForGenerate,
    } = useCardContext();
    const { user } = useUserContext();
    const navigate = useNavigate();

    const handleGenerate = async (id) => {
        const res = await postCardsForGenerate(user.token, id);
        if (res === 200) {
            getBeneficiariesListApprove(
                user.token,
                generateParams.page,
                generateParams.limit,
                generateParams
            );
            navigate(-1);
        }
    };

    return (
        <Buttons.Primary
            label={"জেনারেট"}
            onClick={() => {
                handleGenerate(id);
            }}
            paddingX="px-7"
            paddingY="py-2"
            fontWeight="font-bold"
        />
    );
};

export default GenerateCard;
