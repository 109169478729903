import React from "react";
import Pagination from "../Pagination";
import { useForm } from "react-hook-form";

const TableFooter = ({ params, setParams, pageSize }) => {
    const { register, handleSubmit } = useForm();

    const onSubmit = (data) => {
        // setLimit(Number(data.limit));
        setParams((prev) => ({ ...prev, limit: data.limit }));
    };

    return (
        <div className="p-5 bg-white w-full flex items-center justify-end gap-x-4">
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex items-center gap-2"
            >
                <label className="text-slate-800 text-sm">
                    সারি প্রতি পেজ:
                </label>
                <input
                    defaultValue={params.limit}
                    {...register("limit")}
                    className="w-16 py-1.5 px-2 rounded-md border border-[#919EAB] border-opacity-[32%] outline-none text-slate-800 text-sm"
                />
            </form>
            <Pagination.Primary
                pageSize={pageSize}
                setParams={setParams}
                params={params}
            />
        </div>
    );
};

export default TableFooter;
