import React from "react";
import NoDataFound from "../Common/NoDataFound";

const Primary = ({
    tableHeads,
    tableActionHeads,
    tableDataKeys,
    tableActionComponents,
    tableData,
    selectedItems,
    setSelectedItems,
    selectedAsCardNumber,
    selectedBeneficiaries,
    setSelectedBeneficiaries,
    isCheckbox = true,
    errorMessage,
    needMinHeight = false,
    regenerateCards = false,
}) => {
    // const [selectedItems, setSelectedItems] = useState([]);

    const handleCheckboxClicked = (value, beneficiaryId) => {
        if (value === "all") {
            if (selectedItems.length === tableData.length) {
                setSelectedItems([]);
                setSelectedBeneficiaries && setSelectedBeneficiaries([]);
            } else {
                if (selectedAsCardNumber && regenerateCards) {
                    // console.log("hello");
                    const newArray = tableData.map(
                        (item) => item.beneficiaryId
                    );

                    setSelectedBeneficiaries &&
                        setSelectedBeneficiaries(newArray);
                    const idArray = tableData.map((item) => item.number);
                    setSelectedItems(idArray);
                } else if (selectedAsCardNumber) {
                    const newArray = tableData.map((item) => item.number);
                    setSelectedItems(newArray);
                } else {
                    const newArray = tableData.map((item) => item._id);
                    setSelectedItems(newArray);
                }
            }
        } else {
            if (selectedItems.includes(value)) {
                const newArray = selectedItems.filter((item) => item !== value);
                setSelectedItems(newArray);
                if (
                    beneficiaryId &&
                    selectedBeneficiaries.includes(beneficiaryId)
                ) {
                    const newIdArray = selectedBeneficiaries.filter(
                        (item) => item !== beneficiaryId
                    );
                    setSelectedBeneficiaries &&
                        setSelectedBeneficiaries(newIdArray);
                }
            } else {
                setSelectedItems((prev) => [...prev, value]);
                if (beneficiaryId) {
                    setSelectedBeneficiaries((prev) => [
                        ...prev,
                        beneficiaryId,
                    ]);
                }
            }
        }
    };

    return (
        <div className="relative">
            {/* <div> */}
            <div className="w-[350px] sm:w-[640px] md:w-auto overflow-hidden">
                <table
                    className={`bg-white overflow-x-auto block  ${
                        tableData.length <= 2 && needMinHeight
                            ? "min-h-[15rem]"
                            : ""
                    }`}
                >
                    <thead className="">
                        <tr className="bg-blue-100">
                            {isCheckbox && (
                                <th className="py-[18px] px-6 flex items-center justify-center">
                                    <input
                                        // disabled={
                                        //     !hasPermission(userPermissions, [
                                        //         requiredPermissions.delete,
                                        //     ])
                                        // }
                                        type="checkbox"
                                        className="h-4 w-4 cursor-pointer rounded border-2 border-slate-800 text-blue-600 focus:ring-blue-500 disabled:cursor-not-allowed disabled:opacity-30"
                                        // ref={checkbox}
                                        checked={
                                            tableData.length !== 0 &&
                                            selectedItems.length ===
                                                tableData.length
                                                ? true
                                                : false
                                        }
                                        onChange={() =>
                                            handleCheckboxClicked("all")
                                        }
                                    />
                                </th>
                            )}

                            {tableHeads.map((item, index) => (
                                <th
                                    align={item.align ? item.align : "center"}
                                    key={index}
                                    className={`text-slate-800  font-semibold text-sm p-4 ${
                                        isCheckbox ? "" : "pl-10"
                                    } sticky whitespace-nowrap ${
                                        item.width
                                            ? item.width
                                            : "min-w-[158px]"
                                    }`}
                                >
                                    {item.title}
                                </th>
                            ))}
                            {tableActionHeads.map((item, index) => (
                                <th
                                    // align={item.align ? item.align : "center"}
                                    align="center"
                                    key={index}
                                    className={`text-slate-800 font-semibold whitespace-nowrap text-sm p-4 ${
                                        item.width
                                            ? item.width
                                            : "min-w-[158px]"
                                    }`}
                                >
                                    {item.title}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    {/* </table> */}
                    {/* </div> */}
                    {/* </table>
            <div className="h-40 overflow-auto">
                <table className="bg-white w-full"> */}
                    {/* <div className="max-h-[24rem] overflow-y-auto overflow-x-hidden">
                <table> */}
                    <tbody>
                        {tableData.length > 0 ? (
                            <>
                                {tableData.map((singleData, index) => (
                                    <tr key={index} className="">
                                        {isCheckbox && (
                                            <td className="py-[18px] px-6 flex items-center justify-center">
                                                <input
                                                    // disabled={
                                                    //     !hasPermission(userPermissions, [
                                                    //         requiredPermissions.delete,
                                                    //     ])
                                                    // }
                                                    type="checkbox"
                                                    className="h-4 w-4 cursor-pointer rounded border-2 border-slate-800 text-blue-600 focus:ring-blue-500 disabled:cursor-not-allowed disabled:opacity-30"
                                                    // ref={checkbox}
                                                    checked={
                                                        selectedAsCardNumber
                                                            ? selectedItems.includes(
                                                                  singleData.number
                                                              )
                                                            : selectedItems.includes(
                                                                  singleData._id
                                                              )
                                                        // selectedAsCardNumber
                                                        //     ? regenerateCards
                                                        //         ? selectedItems.includes(
                                                        //               singleData.beneficiaryId
                                                        //           )
                                                        //         : selectedItems.includes(
                                                        //               singleData.number
                                                        //           )
                                                        //     : selectedItems.includes(
                                                        //           singleData._id
                                                        //       )
                                                    }
                                                    onChange={
                                                        () =>
                                                            // setSelectedItems((prev) => [
                                                            //     ...prev,
                                                            //     singleData._id,
                                                            // ])
                                                            selectedAsCardNumber
                                                                ? handleCheckboxClicked(
                                                                      singleData.number,
                                                                      singleData.beneficiaryId
                                                                  )
                                                                : handleCheckboxClicked(
                                                                      singleData._id
                                                                  )

                                                        // selectedAsCardNumber
                                                        //     ? regenerateCards
                                                        //         ? handleCheckboxClicked(
                                                        //               singleData.beneficiaryId
                                                        //           )
                                                        //         : handleCheckboxClicked(
                                                        //               singleData.number
                                                        //           )
                                                        //     : handleCheckboxClicked(
                                                        //           singleData._id
                                                        //       )
                                                    }
                                                />
                                            </td>
                                        )}
                                        {tableDataKeys.map((item, index) => (
                                            <td
                                                key={index}
                                                className={`py-[18px] px-4 ${
                                                    isCheckbox ? "" : "pl-10"
                                                } whitespace-nowrap text-sm text-slate-600 ${
                                                    item.width
                                                        ? item.width
                                                        : "w-auto"
                                                }`}
                                            >
                                                {singleData[item.title]}
                                            </td>
                                        ))}
                                        {tableActionComponents(singleData)}
                                    </tr>
                                ))}
                            </>
                        ) : (
                            <tr className="w-full">
                                <td
                                    colSpan={
                                        tableActionHeads.length +
                                        tableHeads.length +
                                        1
                                    }
                                    className="py-14 px-11"
                                >
                                    <NoDataFound message={errorMessage} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Primary;
