import React from "react";

const IconWithLabel = ({
    icon,
    label,
    textColor,
    paddingY,
    paddingX,
    width,
    bgColor,
    radius,
    fontWeight,
    textSize,
    gap = "gap-x-4",
    align = "justify-center",
    cursor,
    ...rest
}) => {
    return (
        <button
            {...rest}
            className={`${paddingX} ${paddingY} ${width} ${bgColor} ${radius} flex items-center ${gap} ${align} ${cursor} duration-300`}
        >
            {icon && <img src={icon} alt={label} className="w-5 h-5" />}
            <p
                className={`${textSize ? textSize : "text-sm"} ${
                    textColor ? textColor : "text-slate-700"
                } ${fontWeight} whitespace-nowrap mt-1`}
            >
                {label}
            </p>
        </button>
    );
};

export default IconWithLabel;
