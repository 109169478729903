import axios from "axios";
import { BENEFICIARIES_VILLAGE_URL, BENEFICIARIES_URL, BENEFICIARIES_UPLOAD_EXCEL_URL, BENEFICIARIES_APPROVE_URL, BENEFICIARIES_REJECT_URL, BENEFICIARIES_REQUEST_APPROVAL, CREATE_BENEFICIARY_PATH, BENEFICIARIES_SUCCESSFULLY_UPLOADED_EXCEL_FILES, BENEFICIARIES_FAILED_UPLOADED_EXCEL_FILES, GET_IMAGE, BENEFICIARIES_EXPORT_URL } from "../helpers/APIURL";

const getVillages = (token) => {
    const config = {
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
    };
    return axios.get(BENEFICIARIES_VILLAGE_URL, config);
    // return villageData;
};

const getBeneficiaries = ({ token, page = 1, limit = 10, queryParams, url = BENEFICIARIES_URL }) => {
    const config = {
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
        params: {
            page: page,
            limit: limit,
            ...(queryParams.status ? { status: queryParams.status } : {}),
            ...(queryParams.startDate ? { startDate: queryParams.startDate } : {}),
            ...(queryParams.endDate ? { endDate: queryParams.endDate } : {}),
            ...(queryParams.union ? { union: queryParams.union } : {}),
            ...(queryParams.search ? { search: queryParams.search } : {}),
        },
    };
    return axios.get(url, config);
};

const uploadBeneficiaries = (token, file) => {
    const config = {
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
    };
    return axios.post(BENEFICIARIES_UPLOAD_EXCEL_URL, file, config);
};

const getBeneficiariesById = (token, id) => {
    const config = {
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
        params: {
            id: id,
        },
    };
    return axios.get(BENEFICIARIES_URL, config);
};

const approveBeneficiaries = (token, id) => {
    let formattedId = id;

    if (Array.isArray(id)) {
        formattedId = id.map((id) => `id=${id}`).join("&"); // Convert array to comma-separated string
        return axios.patch(
            `${BENEFICIARIES_APPROVE_URL}?${formattedId}`,
            {},
            {
                headers: {
                    Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
                },
            }
        );
    }

    const config = {
        params: {
            id: formattedId,
        },
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
    };
    return axios.patch(BENEFICIARIES_APPROVE_URL, {}, config);
};

const rejectBeneficiaries = (token, id) => {
    let formattedId = id;

    if (Array.isArray(id)) {
        formattedId = id.map((id) => `id=${id}`).join("&"); // Convert array to comma-separated string
        return axios.patch(
            `${BENEFICIARIES_REJECT_URL}?${formattedId}`,
            {},
            {
                headers: {
                    Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
                },
            }
        );
    }

    const config = {
        params: {
            id: id,
        },
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
    };
    return axios.patch(BENEFICIARIES_REJECT_URL, {}, config);
};

const approveBeneficiaryById = (token, id) => {
    const config = {
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
        params: {
            id: id,
        },
    };
    return axios.get(BENEFICIARIES_APPROVE_URL, config);
};

const patchBeneficiaryRequestApproval = (token, id) => {
    let formattedId = id;

    if (Array.isArray(id)) {
        formattedId = id.map((id) => `id=${id}`).join("&"); // Convert array to comma-separated string
        return axios.patch(
            `${BENEFICIARIES_REQUEST_APPROVAL}?${formattedId}`,
            {},
            {
                headers: {
                    Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
                },
            }
        );
    }
    const config = {
        params: {
            id: formattedId,
        },
        headers: {
            Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
        },
    };
    return axios.patch(BENEFICIARIES_REQUEST_APPROVAL, {}, config);
};

// ------- Create beneficiary -------- //
const createBeneficiary = (token, data) => {
    const config = {
        headers: {
            Authorization: token,
        },
    };
    return axios.post(CREATE_BENEFICIARY_PATH, data, config);
};

// ------- Update beneficiary -------- //
const updateBeneficiary = (token, data, id) => {
    const config = {
        params: {
            id,
        },
        headers: {
            Authorization: token,
        },
    };
    return axios.patch(`${CREATE_BENEFICIARY_PATH}`, data, config);
};

const getSuccessfullyUploadedExcelFiles = (token, page, limit) => {
    const config = {
        params: {
            page,
            limit,
        },
        headers: {
            authorization: token,
        },
    };
    return axios.get(BENEFICIARIES_SUCCESSFULLY_UPLOADED_EXCEL_FILES, config);
};

const getFailedUploadedExcelFiles = (token, page, limit) => {
    const config = {
        headers: {
            authorization: token,
        },
        params: {
            page,
            limit,
        },
    };
    return axios.get(BENEFICIARIES_FAILED_UPLOADED_EXCEL_FILES, config);
};

const getDownlaodExcelFile = (token, fileLink) => {
    const config = {
        headers: {
            authorization: token,
        },
        params: {
            key: fileLink,
        },
    };
    return axios.get(`${GET_IMAGE}/url`, config);
};

const getBeneficiaryExport = (token, id, selectedType) => {
    if (selectedType === "selected") {
        let formattedId = id;

        if (Array.isArray(id)) {
            formattedId = id.map((id) => `id=${id}`).join("&"); // Convert array to comma-separated string
            return axios.get(`${BENEFICIARIES_EXPORT_URL}?${formattedId}`, {
                headers: {
                    Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
                },
            });
        }
        const config = {
            params: {
                id: formattedId,
            },
            headers: {
                Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
            },
        };
        return axios.get(BENEFICIARIES_EXPORT_URL, config);
    } else {
        const config = {
            params: {
                upazila: "রায়পুরা",
            },
            headers: {
                Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
            },
        };
        return axios.get(BENEFICIARIES_EXPORT_URL, config);
    }
};

const BeneficiaryService = {
    getVillages,
    getBeneficiariesById,
    approveBeneficiaries,
    rejectBeneficiaries,
    approveBeneficiaryById,
    uploadBeneficiaries,
    getBeneficiaries,
    patchBeneficiaryRequestApproval,
    createBeneficiary,
    updateBeneficiary,
    getSuccessfullyUploadedExcelFiles,
    getFailedUploadedExcelFiles,
    getDownlaodExcelFile,
    getBeneficiaryExport,
};

export default BeneficiaryService;
