import React, { useCallback, useEffect } from "react";
import Layouts from "../../layouts";

import { useUserContext } from "../../context/UserProvider";
import { useImageContext } from "../../context/imageUploaderProvider";

import ProfileUpdateForm from "./profileUpdateForm";
import ImageUploader from "../../components/Common/ImageUploader";
import PasswordUpdateForm from "./passwordUpdateForm";
import { removeEmptyProperties } from "../../utils";

const ProfileSettings = () => {
    const { user, userUpdateInfo, updateProfile, updatePassword, getUserInfo, setUserUpdateInfo } = useUserContext();
    const { imageUpload, disableButton } = useImageContext();

    const [selectedImage, setSelectedImage] = React.useState({
        preview: "",
        raw: "",
    });

    useEffect(() => {
        getUserInfo(user.token, user.user._id);
    }, [getUserInfo, user.token, user.user._id]);

    const handleImageUploader = useCallback(
        async (image) => {
            const res = await imageUpload(user.token, image);
            return res;
        },
        [imageUpload, user.token]
    );

    const handleUserUpdate = async (data) => {
        const finalData = removeEmptyProperties(data);
        if (selectedImage.raw) {
            try {
                const res = await handleImageUploader(selectedImage.raw);

                if (res.data.key) {
                    const uploadedImageURL = res.data.key;
                    const payload = { ...finalData, image: uploadedImageURL };
                    await updateProfile(user.token, user.user._id, payload);
                }
            } catch (error) {
                console.log("error");
            }
        } else {
            if (userUpdateInfo.image === "") {
                finalData.image = "";
            }
            await updateProfile(user.token, user.user._id, finalData);
        }
        // updateProfile(user.token, user.user._id, finalData);
    };

    const handlePasswordUpdate = (data) => {
        updatePassword(user.token, data);
    };

    return (
        <Layouts.Secondary>
            <div className="pt-[88px] lg:pl-[280px] bg-neutral-50 flex flex-col justify-center items-center mt-10 gap-10 w-[90%] sm:w[100%] md:w-[98%] lg:w-[80%] mx-auto">
                <div className="rounded-lg overflow-hidden bg-white shadow-light-gray w-full">
                    <div className="py-4 px-6 bg-blue-100">
                        <p className="text-base text-blue-950">উপকারভোগীর বিস্তারিত</p>
                    </div>
                    <div className="flex flex-col gap-5 p-2 sm:p-2 md:px-10 ">
                        <ImageUploader
                            create={true}
                            // imageUpload={handleImageUploader}
                            defaultImage={userUpdateInfo ? userUpdateInfo?.image : ""}
                            handleImageEmpty={() => {
                                setUserUpdateInfo((ps) => ({
                                    ...ps,
                                    image: "",
                                }));
                            }}
                            selectedImage={selectedImage}
                            setSelectedImage={setSelectedImage}
                        />
                        <ProfileUpdateForm
                            singleUserInfo={userUpdateInfo}
                            handleUserUpdate={handleUserUpdate}
                            disableButton={disableButton}
                        />
                    </div>
                </div>
                <div className="rounded-lg overflow-hidden bg-white shadow-light-gray mb-10 w-full">
                    <div className="py-4 px-6 bg-blue-100 ">
                        <p className="text-base text-blue-950">পাসওয়ার্ড পরিবর্তন</p>
                    </div>
                    <PasswordUpdateForm handlePasswordUpdate={handlePasswordUpdate} />
                </div>
            </div>
        </Layouts.Secondary>
    );
};

export default ProfileSettings;
