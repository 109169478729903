import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useBeneficiaryContext } from "../../context/BeneficiaryProvider";
import { useUserContext } from "../../context/UserProvider";
import Buttons from "../../components/Buttons";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import Tooltip from "../../layouts/Tooltip";
import IconWithLabel from "../../components/Buttons/IconWithLabel";
import Icons from "../../assets/Icons";
import Filter from "../../components/Filter";
import TableHeader from "../../components/Common/TableHeader";
import Table from "../../components/Table";
import TableFooter from "../../components/Common/TableFooter";

const BeneficiariesUnion = () => {
    const [activeTooltip, setActiveTooltip] = useState("");
    const [selectedItems, setSelectedItems] = useState([]);
    const [firstRender, setFirstRender] = useState(true);
    // const [page, setPage] = useState(1);
    // const [limit, setLimit] = useState(10);

    const navigate = useNavigate();
    const { id } = useParams();

    const {
        villages,
        getVillageData,
        getPendingBeneficiaries,
        pendingBeneficiaries,
        pendingBeneficiariesTotalPages,
        // queryParams,
        // setQueryParams,
        unionBeneficiaryParams,
        setUnionBeneficiaryParams,
        patchBeneficiaryRequestApproval,
    } = useBeneficiaryContext();
    const { user } = useUserContext();

    useEffect(() => {
        setActiveTooltip("");
    }, [navigate]);

    const handleSendRequest = async (id) => {
        const res = await patchBeneficiaryRequestApproval(user.token, id);
        if (res === 200) {
            getPendingBeneficiaries(
                user.token,
                unionBeneficiaryParams.page,
                unionBeneficiaryParams.limit,
                unionBeneficiaryParams
            );
            setSelectedItems([]);
        }
    };

    const renderActionComponents = (data) => {
        return (
            <>
                {/* <td className="py-3 flex justify-center w-full">
                    <div className="text-sm font-medium text-red-600 bg-red-100 px-2 py-1 rounded">
                        বাতিল
                    </div>
                </td> */}
                <td className="py-3 flex justify-center">
                    <div>
                        <Buttons.Primary
                            label={"উপজেলায় প্রেরন"}
                            onClick={() => {
                                handleSendRequest(data._id);
                            }}
                            paddingX="px-6"
                            paddingY="py-2"
                            fontWeight="font-bold"
                        />
                    </div>
                </td>
                <td>
                    <div className="px-4 relative">
                        <button onClick={() => setActiveTooltip(data._id)}>
                            <EllipsisVerticalIcon className="w-8 h-8 text-slate-700" />
                        </button>
                        {activeTooltip === data._id && (
                            <Tooltip
                                top="-top-3"
                                right="right-14"
                                overlayVariant="transparent"
                                setOpen={setActiveTooltip}
                            >
                                <div className="p-2 bg-white rounded">
                                    <IconWithLabel
                                        icon={Icons.file}
                                        label="বিস্তারিত দেখুন"
                                        paddingY="py-2.5"
                                        // paddingX="px-5"
                                        width="w-[180px]"
                                        onClick={() => navigate(`${data._id}`)}
                                    />
                                </div>
                            </Tooltip>
                        )}
                    </div>
                </td>
            </>
        );
    };

    useEffect(() => {
        if (user && firstRender) {
            getVillageData(user.token);
            getPendingBeneficiaries(
                user.token,
                unionBeneficiaryParams.page,
                unionBeneficiaryParams.limit,
                {
                    page: 1,
                    limit: 10,
                    startDate: null,
                    endDate: null,
                    status: null,
                    union: null,
                    search: null,
                }
            );
            setFirstRender(false);
            setUnionBeneficiaryParams({
                page: 1,
                limit: 10,
                startDate: null,
                endDate: null,
                status: null,
                union: null,
                search: null,
            });
        } else if (user) {
            getVillageData(user.token);
            getPendingBeneficiaries(
                user.token,
                unionBeneficiaryParams.page,
                unionBeneficiaryParams.limit,
                unionBeneficiaryParams
            );
        }
    }, [
        user,
        unionBeneficiaryParams,
        getVillageData,
        getPendingBeneficiaries,
        firstRender,
        setUnionBeneficiaryParams,
    ]);

    // useEffect(() => {
    //     // if (user) {
    //     // getVillageData(user.token);
    //     getPendingBeneficiaries(user.token, page, limit, queryParams);
    //     // }
    // }, []);

    return (
        <>
            {id ? (
                <Outlet />
            ) : (
                <div className="py-12 px-10 flex items-center rounded flex-col">
                    <div>
                        <div className="py-9 w-full flex justify-start">
                            <Buttons.IconWithLabel
                                label="নতুন উপকারভোগী তৈরি (একক)"
                                paddingX="px-3"
                                paddingY="py-2.5"
                                icon={Icons.add_white}
                                bgColor="bg-green-600"
                                radius="rounded-[2px]"
                                textColor="text-white"
                                textSize={"font-bold"}
                                onClick={() =>
                                    navigate("/beneficiaries/create")
                                }
                            />
                        </div>
                        <div className="shadow-medium-gray rounded-lg overflow-hidden w-[350px] sm:w-[640px] md:w-[550] lg:w-[700px] xl:w-auto">
                            <Filter.Primary
                                // firstSelectData={approvedStatusDropdown}
                                firstSelectLabel="অনুমোদন স্ট্যাটাস"
                                secondSelectLabel="ঠিকানা"
                                secondSelectData={villages ? villages : []}
                                setQueryParams={setUnionBeneficiaryParams}
                            />
                            <TableHeader
                                selectedItems={selectedItems}
                                setSelectedItems={setSelectedItems}
                                title={"সকল উপকারভোগীর লিস্ট"}
                                selectedTitle={"জন সিলেক্টেড"}
                            >
                                <>
                                    {/* <Buttons.Primary
                                        label={"অনুমোদন"}
                                        onClick={() => {
                                            handleSendRequest(selectedItems);
                                        }}
                                        paddingX="px-7"
                                        paddingY="py-2"
                                        fontWeight="font-bold"
                                    /> */}
                                    <Buttons.Primary
                                        label={"উপজেলায় প্রেরন"}
                                        onClick={() => {
                                            handleSendRequest(selectedItems);
                                        }}
                                        paddingX="px-6"
                                        paddingY="py-2"
                                        fontWeight="font-bold"
                                    />
                                </>
                            </TableHeader>
                            <Table.Primary
                                // tableHeads={["উপকারভোগীর নাম", "পরিচয় পত্র নং", "ঠিকানা", "মোবাইল"]}
                                tableHeads={tableHeadsData}
                                // tableDataKeys={["name", "nid", "village", "mobile"]}
                                tableDataKeys={tableDataKeys}
                                // tableActionHeads={["কার্ড জেনারেট অনুরোধ অনুমোদন", ""]}
                                tableActionHeads={tableActionHeads}
                                tableData={pendingBeneficiaries}
                                tableActionComponents={renderActionComponents}
                                setSelectedItems={setSelectedItems}
                                selectedItems={selectedItems}
                            />
                            <TableFooter
                                params={unionBeneficiaryParams}
                                setParams={setUnionBeneficiaryParams}
                                pageSize={pendingBeneficiariesTotalPages}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default BeneficiariesUnion;

const tableHeadsData = [
    {
        title: "উপকারভোগীর নাম",
        align: "left",
        width: "w-[190px]",
    },
    {
        title: "পরিচয় পত্র নং",
        align: "left",
        width: "w-[190px]",
    },
    {
        title: "ঠিকানা",
        align: "left",
        width: "w-[190px]",
    },
    {
        title: "মোবাইল",
        align: "left",
        width: "w-[190px]",
    },
];

const tableDataKeys = [
    {
        title: "name",
        width: "w-[190px]",
    },
    {
        title: "nid",
        width: "w-[190px]",
    },
    {
        title: "union",
        width: "w-[190px]",
    },
    {
        title: "mobile",
        width: "w-[190px]",
    },
];

const tableActionHeads = [
    {
        title: "ডাটা উপজেলায় প্রেরন ",
        align: "center",
        width: "w-[200px]",
    },
    {
        title: "",
        align: "center",
        width: "w-[62px]",
    },
];
