import React from "react";
import Layouts from "../../layouts";
import LoginForm from "../../sections/Login/LoginForm";

const Login = () => {
    return (
        <Layouts.Default>
            <LoginForm />
        </Layouts.Default>
    );
};

export default Login;
