import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import Icons from "../../assets/Icons";
import Buttons from "../../components/Buttons";
import GeneratedCardsDownloadsModal from "../../components/Common/GeneratedCardsDownloadsModal";
import TableFooter from "../../components/Common/TableFooter";
import TableHeader from "../../components/Common/TableHeader";
import Filter from "../../components/Filter";
import Table from "../../components/Table";
import { useCardContext } from "../../context/CardProvider";
import { useUserContext } from "../../context/UserProvider";
import Layouts from "../../layouts";
import { hasPermission } from "../../utils";
import { Permission } from "../../helpers/permissionStrings";
import RegenerateCardsModal from "../../components/Common/RegenerateCardsModal";

const CardGenerated = () => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openRegenerateCardModal, setOpenRegenerateCardModal] =
        useState(false);
    const navigate = useNavigate();
    const [firstRender, setFirstRender] = useState(true);
    const [downloadAvailable, setDownloadAvailable] = useState(false);

    // const [page, setPage] = useState(1);
    // const [limit, setLimit] = useState(10);

    const { id } = useParams();

    const {
        villages,
        getVillageData,
        getGeneratedCards,
        generatedCards,
        generatedCardstotalPages,
        generatedParams,
        setGeneratedParams,
        getDownloadedCard,
    } = useCardContext();
    const { user } = useUserContext();
    // console.log(generatedCards);

    useEffect(() => {
        setGeneratedParams({
            page: 1,
            limit: 10,
            startDate: null,
            endDate: null,
            status: null,
            union: null,
            search: null,
        });
    }, [setGeneratedParams]);

    const handleGenerate = async (id, type) => {
        // setOpenModal(!openModal);
        const res = await getDownloadedCard(user.token, id, type);
        if (res === 200) {
            // getGeneratedCards(user.token, page, limit, queryParams);
            setSelectedItems([]);
        }
    };

    const renderActionComponents = (data) => {
        return (
            <>
                <td className="py-3 flex justify-center">
                    <div className="flex gap-x-4">
                        <button
                            disabled={
                                selectedItems.length > 0 ||
                                data?.downloadAvailable === false
                            }
                            className={`${
                                selectedItems.length > 0 ||
                                data.downloadAvailable === false
                                    ? "bg-slate-500 opacity-50"
                                    : "bg-slate-700 opacity-100"
                            } p-2 rounded-[2px] shadow-extra-light-gray`}
                            onClick={() => {
                                navigate(`preview/${data.number}`);
                                // console.log(data);
                            }}
                        >
                            <img
                                src={Icons.open_eye_white}
                                alt="view"
                                className="w-4 h-4"
                            />
                        </button>
                        <button
                            disabled={
                                selectedItems.length > 0 ||
                                data?.downloadAvailable === false
                            }
                            className={`
                            ${
                                selectedItems.length > 0 ||
                                data.downloadAvailable === false
                                    ? "opacity-50 bg-slate-500"
                                    : "opacity-100 bg-green-600"
                            }
                                p-2 rounded-[2px] shadow-extra-light-gray`}
                            onClick={() =>
                                handleGenerate(data.number, "selected")
                            }
                        >
                            <img
                                src={Icons.download}
                                alt="download"
                                className="w-4 h-4"
                            />
                        </button>
                    </div>
                </td>
            </>
        );
    };

    useEffect(() => {
        if (user && firstRender) {
            getVillageData(user.token);
            getGeneratedCards(
                user.token,
                generatedParams.page,
                generatedParams.limit,
                {
                    page: 1,
                    limit: 10,
                    startDate: null,
                    endDate: null,
                    status: null,
                    union: null,
                    search: null,
                }
            );
            setFirstRender(false);
            setGeneratedParams({
                page: 1,
                limit: 10,
                startDate: null,
                endDate: null,
                status: null,
                union: null,
                search: null,
            });
        } else if (user) {
            getVillageData(user.token);
            getGeneratedCards(
                user.token,
                generatedParams.page,
                generatedParams.limit,
                generatedParams
            );
        }
    }, [
        user,
        generatedParams,
        getVillageData,
        getGeneratedCards,
        firstRender,
        setGeneratedParams,
    ]);

    // useEffect(() => {
    //     // if (user) {
    //     // getVillageData(user.token);
    //     getGeneratedCards(user.token, page, limit, queryParams);
    //     // }
    // }, []);
    // console.log(
    //     generatedCards.filter((item) => selectedItems.includes(item.number))
    // );

    const isDownloadDisabled = () => {
        const newArray = generatedCards.filter((item) =>
            selectedItems.includes(item.number)
        );
        const allItemsDownloadAvailable = newArray.every(
            (item) => item.downloadAvailable
        );
        // console.log(allItemsDownloadAvailable);
        return allItemsDownloadAvailable;
    };

    useEffect(() => {
        const newArray = generatedCards.filter((item) =>
            selectedItems.includes(item.number)
        );
        const allItemsDownloadAvailable = newArray.every(
            (item) => item.downloadAvailable
        );
        // console.log(allItemsDownloadAvailable);
        setDownloadAvailable(!allItemsDownloadAvailable);
    }, [selectedItems]);

    return (
        <>
            {id ? (
                <Outlet />
            ) : (
                <div className="py-12 px-10 flex items-center flex-col">
                    <div className="shadow-medium-gray rounded-lg overflow-hidden w-[350px] sm:w-[640px] md:w-[550] lg:w-[700px] xl:w-auto">
                        <Filter.Primary
                            // firstSelectData={approvedStatusDropdown}
                            firstSelectLabel="অনুমোদন স্ট্যাটাস"
                            secondSelectLabel="ঠিকানা"
                            secondSelectData={villages ? villages : []}
                            setQueryParams={setGeneratedParams}
                        />
                        <TableHeader
                            selectedItems={selectedItems}
                            setSelectedItems={setSelectedItems}
                            title={"কার্ড জেনারেট করার লিস্ট"}
                            selectedTitle={"টি কার্ড সিলেক্টেড"}
                        >
                            <>
                                <Buttons.Primary
                                    label={"ডাউনলোড"}
                                    onClick={() => {
                                        // handleGenerate(
                                        //     selectedItems,
                                        //     "selected"
                                        // );
                                        setOpenModal(!openModal);
                                    }}
                                    isDisabled={downloadAvailable}
                                    paddingX="px-7"
                                    paddingY="py-2"
                                    fontWeight="font-bold"
                                />
                                {hasPermission(user?.permissions, [
                                    Permission.REGENERATE_CARD,
                                ]) && (
                                    <Buttons.Primary
                                        label={"রিজেনারেট"}
                                        onClick={() => {
                                            setOpenRegenerateCardModal(
                                                !openRegenerateCardModal
                                            );
                                        }}
                                        paddingX="px-7"
                                        paddingY="py-2"
                                        fontWeight="font-bold"
                                    />
                                )}
                            </>
                        </TableHeader>
                        <Table.Primary
                            // tableHeads={["উপকারভোগীর নাম", "পরিচয় পত্র নং", "ঠিকানা", "মোবাইল"]}
                            tableHeads={tableHeadsData}
                            // tableDataKeys={["name", "nid", "village", "mobile"]}
                            tableDataKeys={tableDataKeys}
                            // tableActionHeads={["কার্ড জেনারেট অনুরোধ অনুমোদন", ""]}
                            tableActionHeads={tableActionHeads}
                            tableData={generatedCards}
                            tableActionComponents={renderActionComponents}
                            setSelectedItems={setSelectedItems}
                            setSelectedBeneficiaries={setSelectedBeneficiaries}
                            selectedBeneficiaries={selectedBeneficiaries}
                            regenerateCards={true}
                            selectedItems={selectedItems}
                            selectedAsCardNumber={true}
                        />
                        <TableFooter
                            params={generatedParams}
                            setParams={setGeneratedParams}
                            pageSize={generatedCardstotalPages}
                        />
                    </div>
                    {openModal && (
                        <Layouts.Modal
                            overlayVariant="light-gray"
                            setOpen={setOpenModal}
                        >
                            <GeneratedCardsDownloadsModal
                                setOpen={setOpenModal}
                                selectedItems={selectedItems}
                                setSelectedItems={setSelectedItems}
                                setSelectedBeneficiaries={
                                    setSelectedBeneficiaries
                                }
                                token={user.token}
                            />
                        </Layouts.Modal>
                    )}
                    {openRegenerateCardModal && (
                        <Layouts.Modal
                            overlayVariant="light-gray"
                            setOpen={setOpenModal}
                        >
                            <RegenerateCardsModal
                                setOpen={setOpenRegenerateCardModal}
                                selectedItems={selectedBeneficiaries}
                                setSelectedItems={setSelectedBeneficiaries}
                                setSelectedItemsId={setSelectedItems}
                                token={user.token}
                            />
                            {/* <GeneratedCardsDownloadsModal
                                setOpen={setOpenModal}
                                selectedItems={selectedItems}
                                setSelectedItems={setSelectedItems}
                                token={user.token}
                            /> */}
                        </Layouts.Modal>
                    )}
                </div>
            )}
        </>
    );
};

export default CardGenerated;

const tableHeadsData = [
    {
        title: "কার্ড নাম্বার",
        align: "left",
        width: "w-[157px]",
    },
    {
        title: "উপকারভোগীর নাম",
        align: "left",
        width: "w-[157px]",
    },
    {
        title: "পরিচয় পত্র নং",
        align: "left",
        width: "w-[157px]",
    },
    {
        title: "ঠিকানা",
        align: "left",
        width: "w-[157px]",
    },
    {
        title: "মোবাইল",
        align: "left",
        width: "w-[157px]",
    },
];

const tableDataKeys = [
    {
        title: "number",
        width: "w-[157px]",
    },
    {
        title: "name",
        width: "w-[157px]",
    },
    {
        title: "nid",
        width: "w-[157px]",
    },
    {
        title: "union",
        width: "w-[157px]",
    },
    {
        title: "mobile",
        width: "w-[157px]",
    },
];

const tableActionHeads = [
    {
        title: "ডাউনলোড",
        align: "center",
        width: "w-[227px]",
    },
];
