import React from "react";
import { useForm } from "react-hook-form";

import { Box } from "../../sections/BeneficiaryForm.jsx";
import FormFields from "../../components/FormFields/index.js";
import Buttons from "../../components/Buttons/index.js";
import { withToast } from "../../hoc/withToast";

const PasswordUpdateForm = ({ handlePasswordUpdate, showToast }) => {
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        const checkNewPass = getValues("newPasswordCheck");
        const newPass = getValues("newPassword");

        if (checkNewPass === newPass) {
            delete data.newPasswordCheck;
            handlePasswordUpdate(data);
        } else {
            showToast("নতুন পাসওয়ার্ড, পুনরায় নতুন পাসওয়ার্ড ফিল্ডের সাথে মেলেনি !", true);
        }
    };
    return (
        <form className="mt-9 mb-4 flex flex-col w-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col items-end justify-center gap-10 p-2 sm:p-2 md:px-10 ">
                <div className="flex flex-col sm:flex-col md:flex-row lg:flex-row justify-between w-full gap-5">
                    <Box>
                        <h6 className="text-slate-800 font-medium text-base mb-2">বর্তমান পাসওয়ার্ড</h6>
                        <FormFields.Password
                            register={register}
                            defaultValue={""}
                            errors={errors}
                            name="oldPassword"
                            width="w-full"
                            // isReadOnly={create ? !create : getEdit ? !getEdit : true}
                            validations={{ required: true, one: "asdf" }}
                            // errorMsg={errors?.name}
                        />
                    </Box>
                    <Box>
                        <h6 className="text-slate-800 font-medium text-base mb-2">নতুন পাসওয়ার্ড</h6>
                        <FormFields.Password
                            register={register}
                            defaultValue={""}
                            errors={errors}
                            name="newPasswordCheck"
                            width="w-full"
                            // isReadOnly={create ? !create : getEdit ? !getEdit : true}
                            validations={{ required: true, one: "asdf" }}
                            // errorMsg={errors?.name}
                        />
                    </Box>
                    <Box>
                        <h6 className="text-slate-800 font-medium text-base mb-2">পুনরায় নতুন পাসওয়ার্ড</h6>
                        <FormFields.Password
                            register={register}
                            defaultValue={""}
                            errors={errors}
                            name="newPassword"
                            width="w-full"
                            // isReadOnly={create ? !create : getEdit ? !getEdit : true}
                            validations={{ required: true, one: "asdf" }}
                            // errorMsg={errors?.name}
                        />
                    </Box>
                </div>
                <Buttons.IconWithLabel label="আপডেট করুন" paddingX="px-3" paddingY="py-2.5" bgColor="bg-green-600" radius="rounded-[2px]" textColor="text-white" textSize={"font-bold"} type="submit" />
            </div>
        </form>
    );
};

export default withToast(PasswordUpdateForm);
