import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import React from "react";

const TextForUpdate = ({
    defaultValue,
    placeholder,
    name,
    value,
    width = "w-full",
    errors,
    isReadOnly,
    onChange,
    errorMsg,
}) => {
    // console.log(errors[name]);
    return (
        <div>
            <input
                defaultValue={defaultValue}
                readOnly={isReadOnly ? true : false}
                type="text"
                placeholder={placeholder}
                className={`text-base font-medium py-3.5 px-5 rounded-sm border ${width} text-slate-800 outline-blue-600 outline-1 border border-slate-300 bg-white`}
                onChange={onChange}
                name={name}
                value={value}
            />
            <span
                className={`text-xs flex items-center mt-3 text-red-600 ${
                    errorMsg ? "visible" : "invisible"
                }`}
            >
                <ExclamationCircleIcon className="w-4 h-4 mr-1.5" />{" "}
                <span className="mt-0.5">{errorMsg ? errorMsg : ""}</span>
            </span>
        </div>
    );
};

export default TextForUpdate;
