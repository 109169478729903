import React from "react";
import { useForm } from "react-hook-form";
import { useCardContext } from "../../context/CardProvider";

import Icons from "../../assets/Icons";
import Buttons from "../Buttons";
import { withToast } from "../../hoc/withToast";

const GeneratedCardModal = ({
    setOpen,
    showToast,
    selectedItems,
    setSelectedItems,
    setSelectedBeneficiaries,
    token,
}) => {
    const { getDownloadedCard } = useCardContext();

    const { register, handleSubmit } = useForm({
        defaultValues: {
            type: "selected",
        },
    });

    async function onSubmitButton(data) {
        await getDownloadedCard(token, selectedItems, data.type);
        setOpen(false);
        setSelectedItems([]);
        setSelectedBeneficiaries([]);
    }

    return (
        <form
            className="w-[402px] rounded overflow-hidden bg-white"
            onSubmit={handleSubmit(onSubmitButton)}
        >
            <div className="p-4 bg-blue-950 flex items-center justify-between">
                <p className="text-slate-50 text-base leading-6">
                    একাধিক জেনারেটেড কার্ড ডাউনলোড
                </p>
                <button onClick={() => setOpen(false)}>
                    <img
                        src={Icons.cross_white}
                        alt="cross_white"
                        className="w-5 h-5"
                    />
                </button>
            </div>
            <div className="p-4">
                <p className="text-blue-950 text-sm font-semibold leading-[22px]">
                    সিলেক্ট কার্ড
                </p>
                <div className="mt-3 flex flex-col items-start gap-0">
                    <div className="mt-3 flex flex-row items-center justify-center gap-2">
                        <input
                            {...register("type")}
                            type="radio"
                            value="selected"
                            id="field-rain"
                            className=""
                        />
                        <label htmlFor="field-rain" className="ml">
                            বর্তমানে সিলেক্টেড কার্ড
                        </label>
                    </div>
                    <div className="mt-3 flex flex-row items-center justify-center gap-2">
                        <input
                            {...register("type")}
                            type="radio"
                            value="all"
                            id="field-wind"
                        />
                        <label htmlFor="field-wind">সকল কার্ড</label>
                    </div>
                </div>
            </div>
            <div className="p-4 flex justify-end items-center gap-x-4">
                <Buttons.Outline
                    label={"বাতিল"}
                    onClick={() => setOpen(false)}
                    paddingX="px-6"
                    paddingY="py-2"
                    fontWeight="font-bold"
                    textColor={"text-slate-600"}
                    borderColor={"border-slate-400"}
                />
                <Buttons.Primary
                    label={"ডাউনলোড"}
                    // isDisabled={loading}
                    onClick={handleSubmit}
                    paddingX="px-6"
                    paddingY="py-2"
                    fontWeight="font-bold"
                />
            </div>
        </form>
    );
};

export default withToast(GeneratedCardModal);
