import React from "react";

const Primary = ({
    label,
    paddingY,
    paddingX,
    width,
    bgColor,
    radius,
    textColor,
    fontWeight,
    textSize,
    type,
    isDisabled,
    ...rest
}) => {
    return (
        <button
            {...rest}
            disabled={isDisabled}
            // type={type ? type : "button"}
            className={`whitespace-nowrap ${
                paddingY ? paddingY : "py-4"
            } ${paddingX} ${width} ${bgColor ? bgColor : "bg-green-600"} ${
                radius ? radius : "rounded-sm"
            } ${textColor ? textColor : "text-white"} ${fontWeight} ${
                textSize ? textSize : "text-base"
            } ${isDisabled && "opacity-80 cursor-not-allowed"}`}
        >
            {label}
        </button>
    );
};

export default Primary;
