import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import Context from "./context";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        {/* <ProviderInjection app={<App />} /> */}
        <Context.UserProvider>
            <Context.DashboardProvider>
                <Context.CardProvider>
                    <Context.BeneficiaryProvider>
                        <Context.ImageProvider>
                            <Context.DistributionProvider>
                                <Context.ReportProvider>
                                    <App />
                                </Context.ReportProvider>
                            </Context.DistributionProvider>
                        </Context.ImageProvider>
                    </Context.BeneficiaryProvider>
                </Context.CardProvider>
            </Context.DashboardProvider>
        </Context.UserProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
