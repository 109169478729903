import React, { useEffect, useState } from "react";
import Filter from "../../components/Filter";
import { useDistributionContext } from "../../context/distributionProvider";
import { useBeneficiaryContext } from "../../context/BeneficiaryProvider";
import { useUserContext } from "../../context/UserProvider";
import TableHeader from "../../components/Common/TableHeader";
import Table from "../../components/Table";
import TableFooter from "../../components/Common/TableFooter";
import { format } from "date-fns";
import Icons from "../../assets/Icons";
import Layouts from "../../layouts";
import DistributionImageModal from "../../components/Common/DistributionImageModal";

const DistributionWithNid = () => {
    const [reformatDistributionReadData, setReformatDistributionReadData] =
        useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [singleData, setSingleData] = useState("");
    const { user } = useUserContext();
    const [firstRender, setFirstRender] = useState(true);
    const {
        distributionReadWithNid,
        distributionReadWithNidParams,
        setDistributionReadWithNidParams,
        distributionReadWithNidTotal,
        handleDistributionWithNid,
    } = useDistributionContext();

    const { getVillageData, villages } = useBeneficiaryContext();

    useEffect(() => {
        if (user && firstRender) {
            getVillageData(user.token);
            handleDistributionWithNid(
                user.token,
                distributionReadWithNidParams.page,
                distributionReadWithNidParams.limit,
                distributionReadWithNidParams
            );
            setFirstRender(false);
            setDistributionReadWithNidParams({
                page: 1,
                limit: 10,
                startDate: null,
                endDate: null,
                status: null,
                union: null,
                search: null,
            });
        } else {
            handleDistributionWithNid(
                user.token,
                distributionReadWithNidParams.page,
                distributionReadWithNidParams.limit,
                distributionReadWithNidParams
            );
        }
    }, [
        distributionReadWithNidParams,
        setDistributionReadWithNidParams,
        firstRender,
        getVillageData,
        handleDistributionWithNid,
        user,
    ]);

    useEffect(() => {
        const transformedArray = distributionReadWithNid?.map((item) => ({
            _id: item._id,
            dateInISO: item.dateInISO,
            date: format(new Date(item.dateInISO), "dd/MM/yyyy"),
            dealerId: item.dealerId,
            dealerName: item.dealerName, // Extract the filename
            beneficiaryNid: item.beneficiaryNid,
            union: item.union,
            upazila: item.upazila,
            createdAt: new Date(item.createdAt).toLocaleDateString("en-US"), // Reformat the date
            createdBy: item.createdBy,
        }));
        setReformatDistributionReadData(transformedArray);
    }, [distributionReadWithNid]);

    const renderActionComponents = (data) => {
        return (
            <>
                <td>
                    <div className="flex items-center gap-x-4  px-10">
                        {data.beneficiaryNid && (
                            <button
                                className="bg-slate-700 p-2 rounded-[2px] shadow-extra-light-gray"
                                onClick={() => {
                                    setOpenModal(true);
                                    setSingleData(data.beneficiaryNid);
                                }}
                            >
                                <img
                                    src={Icons.open_eye_white}
                                    alt="view"
                                    className="w-4 h-4"
                                />
                            </button>
                        )}
                    </div>
                </td>
            </>
        );
    };

    return (
        <div className="">
            <div className=" bg-neutral-00 overflow-y-auto py-12 px-10 flex items-center flex-col ">
                <div className="py-12 px-10 flex items-center flex-col">
                    <div className="shadow-medium-gray rounded-lg overflow-hidden ">
                        {/* <div className="p-6 bg-white flex gap-x-4 items-center justify-between">
        <div className="flex flex-row gap-3">
            <FormFields.LegendDateField
                setQueryParams={setDistributionParams}
            />
            <FormFields.LegendSelectField
                data={[]}
                label={"ঠিকানা"}
                setQueryParams={setDistributionParams}
                name="union"
            />
        </div>
        <div className="w-[60%]">
            <FormFields.SearchField
                placeholder="উপকারভোগী সার্চ করুন"
                setQueryParams={setDistributionParams}
            />
        </div>
    </div> */}

                        <Filter.Primary
                            // firstSelectData={approvedStatusDropdown}
                            // firstSelectLabel="অনুমোদন স্ট্যাটাস"
                            secondSelectLabel="ঠিকানা"
                            secondSelectData={villages ? villages : []}
                            setQueryParams={setDistributionReadWithNidParams}
                            futureDateSelectable={true}
                        />

                        <TableHeader
                            selectedItems={[]}
                            // setSelectedItems={setSelectedItems}
                            title={"সকল বিতরণের লিস্ট"}
                            selectedTitle={"বিতরণ সিলেঙ্কট করা হয়েছে"}
                        >
                            {/* <>
                                <Buttons.Primary
                                    label={"ডিলিট"}
                                    onClick={() => {}}
                                    paddingX="px-7"
                                    paddingY="py-2"
                                    fontWeight="font-bold"
                                    bgColor="bg-red-500"
                                />
                            </> */}
                        </TableHeader>

                        <Table.Primary
                            // tableHeads={["উপকারভোগীর নাম", "পরিচয় পত্র নং", "ঠিকানা", "মোবাইল"]}
                            tableHeads={tableHeadsData}
                            // tableDataKeys={["name", "nid", "village", "mobile"]}
                            tableDataKeys={tableDataKeys}
                            //   // tableActionHeads={["কার্ড জেনারেট অনুরোধ অনুমোদন", ""]}
                            tableActionHeads={tableActionHeads}
                            tableData={reformatDistributionReadData || []}
                            tableActionComponents={renderActionComponents}
                            // setSelectedItems={setSelectedItems}
                            // selectedItems={selectedItems}
                            dataLength={distributionReadWithNid?.length}
                            needMinHeight={true}
                            isCheckbox={false}
                        />
                        <TableFooter
                            params={distributionReadWithNidParams}
                            setParams={setDistributionReadWithNidParams}
                            pageSize={distributionReadWithNidTotal}
                        />
                    </div>
                </div>
            </div>
            {openModal && (
                <Layouts.Modal
                    overlayVariant="light-gray"
                    setOpen={setOpenModal}
                >
                    <DistributionImageModal
                        setOpen={setOpenModal}
                        image={singleData}
                        // selectedItems={selectedItems}
                        // setSelectedItems={setSelectedItems}
                        token={user.token}
                    />
                </Layouts.Modal>
            )}
        </div>
    );
};

export default DistributionWithNid;

const tableHeadsData = [
    {
        title: "ডিলারের নাম",
        align: "left",
        width: "w-[358px]",
    },
    {
        title: "তারিখ",
        align: "left",
        width: "w-[158px]",
    },
    {
        title: "ইউনিয়নের নাম",
        align: "left",
        width: "w-[258px]",
    },
];

const tableDataKeys = [
    {
        title: "dealerName",
        width: "w-[158px]",
    },
    {
        title: "date",
        width: "w-[150px]",
    },
    {
        title: "union",
        width: "w-[358px]",
    },
];

const tableActionHeads = [
    {
        title: "ছবি দেখুন",
        align: "center",
        width: "w-[116px]",
    },
];
