import React from "react";
import { approvedStatusWithEN } from "../../libs/common";

const AllBeneficiariesActions = ({ data }) => {
    return (
        <div className="flex items-center gap-x-2">
            <h1 className="text-gray-800 text-sm font-semibold">
                অনুমোদন স্ট্যাটাস:
            </h1>
            <div
                className={`text-sm whitespace-nowrap font-medium ${
                    approvedStatusWithEN.find(
                        (item) => item.name_en === data.status
                    )?.color
                } px-2 py-1 rounded`}
            >
                {
                    approvedStatusWithEN.find(
                        (item) => item.name_en === data.status
                    )?.name_bn
                }
            </div>
        </div>
    );
};

export default AllBeneficiariesActions;
