import React from "react";
import FormFields from "../FormFields";

const Primary = ({
    firstSelectData,
    secondSelectData,
    firstSelectLabel,
    secondSelectLabel,
    searchbarLabel = "সার্চ করুন",
    setQueryParams,
    futureDateSelectable,
}) => {
    return (
        <div className="p-6 bg-white flex gap-4 sm:items-end justify-between flex-wrap sm:flex-row flex-col">
            <FormFields.LegendDateField
                setQueryParams={setQueryParams}
                legendTitle="শুরুর তারিখ"
                startDate={true}
                futureDateSelectable={futureDateSelectable}
            />
            <FormFields.LegendDateField
                setQueryParams={setQueryParams}
                legendTitle="শেষের তারিখ"
                startDate={false}
                futureDateSelectable={futureDateSelectable}
            />
            {firstSelectData && (
                <FormFields.LegendStatusField
                    data={firstSelectData}
                    label={firstSelectLabel}
                    setQueryParams={setQueryParams}
                    name={firstSelectLabel === "রোল নাম" ? "role" : "status"}
                />
            )}
            {secondSelectData.length > 0 && (
                <FormFields.LegendSelectField
                    data={secondSelectData}
                    label={secondSelectLabel}
                    setQueryParams={setQueryParams}
                    name="union"
                />
            )}
            <FormFields.SearchField
                placeholder={searchbarLabel}
                setQueryParams={setQueryParams}
            />
        </div>
    );
};

export default Primary;
