import React from "react";

const Overlay = ({ variant, zIndex, setOpen }) => {
    const variants = {
        transparent: "bg-transparent",
        "light-gray": "bg-black bg-opacity-10",
    };
    return (
        <div
            onClick={() => setOpen(false)}
            className={`${
                variants[variant]
            } fixed top-0 left-0 w-full h-screen animate-fadeIn ${
                zIndex ? zIndex : "z-10"
            }`}
        />
    );
};

export default Overlay;
