import React from "react";
import { useUserContext } from "../../context/UserProvider";
import { approvedStatusWithEN } from "../../libs/common";
import Buttons from "../../components/Buttons";
import { useCardContext } from "../../context/CardProvider";
import { useNavigate } from "react-router-dom";

const RejectedCard = ({ data, id }) => {
    const { user } = useUserContext();
    const navigate = useNavigate();
    const {
        getBeneficiariesListRejected,
        rejectedParams,
        patchBeneficiaryApprove,
    } = useCardContext();

    const handleApprove = async (id) => {
        const res = await patchBeneficiaryApprove(user.token, id);
        if (res === 200) {
            getBeneficiariesListRejected(
                user.token,
                rejectedParams.page,
                rejectedParams.limit,
                rejectedParams
            );
            navigate(-1);
        }
    };

    return (
        <div className="flex flex-col md:flex-row items-start md:items-center gap-x-4">
            <div className="flex items-center gap-x-2">
                <h1 className="text-gray-800 text-sm font-semibold">
                    অনুমোদন স্ট্যাটাস:
                </h1>
                <div
                    className={`text-sm whitespace-nowrap font-medium ${
                        approvedStatusWithEN.find(
                            (item) => item.name_en === data.status
                        )?.color
                    } px-2 py-1 rounded`}
                >
                    {
                        approvedStatusWithEN.find(
                            (item) => item.name_en === data.status
                        )?.name_bn
                    }
                </div>
            </div>
            <Buttons.Primary
                label={"অনুমোদন"}
                onClick={() => {
                    handleApprove(data._id);
                }}
                paddingX="px-7"
                paddingY="py-2"
                fontWeight="font-bold"
            />
        </div>
    );
};

export default RejectedCard;
