import React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

const Text = ({
    defaultValue,
    placeholder,
    register,
    name,
    width = "w-full",
    errors,
    errorMsg,
    isReadOnly,
    validations,
}) => {
    // console.log(validations, "valueasdjf");
    return (
        <div>
            <input
                defaultValue={defaultValue}
                readOnly={isReadOnly ? true : false}
                type="text"
                {...register(name, { ...validations })}
                placeholder={placeholder}
                className={`${
                    isReadOnly ? "outline-none" : "outline-blue-600 outline-1"
                } text-base font-medium py-3.5 px-5 rounded-sm border ${width} text-slate-800  border border-slate-300 bg-white`}
            />
            <span
                className={`text-xs flex items-start mt-3 text-red-600 ${width} ${
                    errors[name] ? "visible" : "invisible"
                }`}
            >
                <ExclamationCircleIcon className="w-4 h-4 mr-1.5" />{" "}
                <span className="mt-0.5">{errorMsg}</span>
            </span>
        </div>
    );
};

export default Text;
