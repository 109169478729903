import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import Icons from "../../assets/Icons";
import Buttons from "../../components/Buttons";
import IconWithLabel from "../../components/Buttons/IconWithLabel";
import TableFooter from "../../components/Common/TableFooter";
import TableHeader from "../../components/Common/TableHeader";
import Filter from "../../components/Filter";
import Table from "../../components/Table";
import { useCardContext } from "../../context/CardProvider";
import { useUserContext } from "../../context/UserProvider";
import Tooltip from "../../layouts/Tooltip";

const CardRequest = () => {
    const [activeTooltip, setActiveTooltip] = useState("");
    const [selectedItems, setSelectedItems] = useState([]);
    const [firstRender, setFirstRender] = useState(true);
    // const [page, setPage] = useState(1);
    // const [limit, setLimit] = useState(10);

    const navigate = useNavigate();
    const { id } = useParams();

    const {
        villages,
        getVillageData,
        getBeneficiaries,
        beneficiaries,
        totalPages,
        requestParams,
        setRequestParams,
        patchBeneficiaryApprove,
        patchBeneficiaryReject,
    } = useCardContext();
    const { user } = useUserContext();

    useEffect(() => {
        setActiveTooltip("");
    }, [navigate]);

    const handleApprove = async (id) => {
        const res = await patchBeneficiaryApprove(user.token, id);
        if (res === 200) {
            getBeneficiaries(
                user.token,
                requestParams.page,
                requestParams.limit,
                requestParams
            );
            setSelectedItems([]);
        }
    };

    const handleReject = async (id) => {
        const res = await patchBeneficiaryReject(user.token, id);
        if (res === 200) {
            getBeneficiaries(
                user.token,
                requestParams.page,
                requestParams.limit,
                requestParams
            );
            setSelectedItems([]);
        }
    };

    const renderActionComponents = (data) => {
        return (
            <>
                <td className="py-3">
                    <div className="flex items-center gap-x-4  px-10">
                        <Buttons.Primary
                            label={"অনুমোদন"}
                            onClick={() => {
                                handleApprove(data._id);
                            }}
                            paddingX="px-7"
                            paddingY="py-2"
                            fontWeight="font-bold"
                        />
                        <Buttons.Outline
                            label={"বাতিল"}
                            onClick={() => {
                                handleReject(data._id);
                            }}
                            paddingX="px-7"
                            paddingY="py-2"
                            fontWeight="font-bold"
                        />
                    </div>
                </td>
                <td>
                    <div className="px-4 relative">
                        <button onClick={() => setActiveTooltip(data._id)}>
                            <EllipsisVerticalIcon className="w-8 h-8 text-slate-700" />
                        </button>
                        {activeTooltip === data._id && (
                            <Tooltip
                                top="-top-3"
                                right="right-14"
                                overlayVariant="transparent"
                                setOpen={setActiveTooltip}
                            >
                                <div className="p-2 bg-white rounded">
                                    <IconWithLabel
                                        icon={Icons.file}
                                        label="বিস্তারিত দেখুন"
                                        paddingY="py-2.5"
                                        // paddingX="px-5"
                                        width="w-[180px]"
                                        onClick={() => navigate(`${data._id}`)}
                                    />
                                </div>
                            </Tooltip>
                        )}
                    </div>
                </td>
            </>
        );
    };

    useEffect(() => {
        if (user && firstRender) {
            getVillageData(user.token);
            getBeneficiaries(
                user.token,
                requestParams.page,
                requestParams.limit,
                {
                    page: 1,
                    limit: 10,
                    startDate: null,
                    endDate: null,
                    status: null,
                    union: null,
                    search: null,
                }
            );
            setFirstRender(false);
            setRequestParams({
                page: 1,
                limit: 10,
                startDate: null,
                endDate: null,
                status: null,
                union: null,
                search: null,
            });
        } else if (user) {
            getVillageData(user.token);
            getBeneficiaries(
                user.token,
                requestParams.page,
                requestParams.limit,
                requestParams
            );
        }
    }, [
        user,
        requestParams,
        getVillageData,
        getBeneficiaries,
        firstRender,
        setRequestParams,
    ]);

    // useEffect(() => {
    //     // if (user) {
    //     // getVillageData(user.token);
    //     getBeneficiaries(user.token, page, limit, queryParams);
    //     // }
    // }, []);

    return (
        <>
            {id ? (
                <Outlet />
            ) : (
                <div className="py-12 px-10 flex items-center flex-col">
                    <div className="shadow-medium-gray rounded-lg overflow-hidden w-[350px] sm:w-[640px] md:w-[550] lg:w-[700px] xl:w-auto min-h-[28rem] bg-white relative">
                        <Filter.Primary
                            // firstSelectData={approvedStatusDropdown}
                            firstSelectLabel="অনুমোদন স্ট্যাটাস"
                            secondSelectLabel="ঠিকানা"
                            secondSelectData={villages ? villages : []}
                            setQueryParams={setRequestParams}
                        />
                        <TableHeader
                            selectedItems={selectedItems}
                            setSelectedItems={setSelectedItems}
                            title={
                                "সকল কার্ড জেনারেট অনুরোধ অনুমোদন দেওয়ার লিস্ট"
                            }
                            selectedTitle={"টি  কার্ড জেনারেট অনুরোধ সিলেক্টেড"}
                        >
                            <>
                                <Buttons.Primary
                                    label={"অনুমোদন"}
                                    onClick={() => {
                                        handleApprove(selectedItems);
                                    }}
                                    paddingX="px-7"
                                    paddingY="py-2"
                                    fontWeight="font-bold"
                                />
                                <Buttons.Outline
                                    label={"বাতিল"}
                                    onClick={() => {
                                        handleReject(selectedItems);
                                    }}
                                    paddingX="px-7"
                                    paddingY="py-2"
                                    fontWeight="font-bold"
                                />
                            </>
                        </TableHeader>

                        <Table.Primary
                            // tableHeads={["উপকারভোগীর নাম", "পরিচয় পত্র নং", "ঠিকানা", "মোবাইল"]}
                            tableHeads={tableHeadsData}
                            // tableDataKeys={["name", "nid", "village", "mobile"]}
                            tableDataKeys={tableDataKeys}
                            // tableActionHeads={["কার্ড জেনারেট অনুরোধ অনুমোদন", ""]}
                            tableActionHeads={tableActionHeads}
                            tableData={beneficiaries}
                            tableActionComponents={renderActionComponents}
                            setSelectedItems={setSelectedItems}
                            selectedItems={selectedItems}
                        />
                        <TableFooter
                            params={requestParams}
                            setParams={setRequestParams}
                            pageSize={totalPages}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default CardRequest;

const tableHeadsData = [
    {
        title: "উপকারভোগীর নাম",
        align: "left",
        width: "w-[158px]",
    },
    {
        title: "পরিচয় পত্র নং",
        align: "left",
        width: "w-[158px]",
    },
    {
        title: "ঠিকানা",
        align: "left",
        width: "w-[158px]",
    },
    {
        title: "মোবাইল",
        align: "left",
        width: "w-[158px]",
    },
];

const tableDataKeys = [
    {
        title: "name",
        width: "w-[158px]",
    },
    {
        title: "nid",
        width: "w-[158px]",
    },
    {
        title: "union",
        width: "w-[158px]",
    },
    {
        title: "mobile",
        width: "w-[158px]",
    },
];

const tableActionHeads = [
    {
        title: "কার্ড জেনারেট অনুরোধ অনুমোদন",
        align: "center",
        width: "w-[316px]",
    },
    {
        title: "",
        align: "center",
        width: "w-16",
    },
];
