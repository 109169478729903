import React, { useCallback, useEffect, useState } from "react";
import Table from "../../components/Table";
import Buttons from "../../components/Buttons";
import TableHeader from "../../components/Common/TableHeader";
import { withToast } from "../../hoc/withToast";
import { useUserContext } from "../../context/UserProvider";
import TableFooter from "../../components/Common/TableFooter";
import { Outlet } from "react-router-dom";
import { useParams } from "react-router-dom";
import Layouts from "../../layouts";
import UploadModal from "../../components/Common/UploadModal";

import { useBeneficiaryContext } from "../../context/BeneficiaryProvider";

const BeneficiariesFailedUploadedExcel = ({ showToast }) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [uploadOpen, setUploadOpen] = useState(false);
    const [changeFailedExcelData, setChangeFailedExcelData] = useState([]);
    // const [page, setPage] = useState(1);
    // const [limit, setLimit] = useState(10);

    const { id } = useParams();

    const {
        getFailedUploadedExcelFiles,
        failedExcelData,
        failedExcelTotal,
        failedExcelParams,
        setFailedExcelParams,
        getDownloadFiles,
    } = useBeneficiaryContext();
    const { user } = useUserContext();

    useEffect(() => {
        setFailedExcelParams({ page: 1, limit: 10 });
    }, [setFailedExcelParams]);

    const handleApprove = async (id) => {
        // const res = await approveBeneficiaries(user.token, id);
        // if (res === 200) {
        //     getAwaitingApprovalBeneficiaries(
        //         user.token,
        //         approveBeneficiaryParams.page,
        //         approveBeneficiaryParams.limit,
        //         approveBeneficiaryParams
        //     );
        //     setSelectedItems([]);
        // }
    };

    const handleReject = async (id) => {
        // const res = await rejectBeneficiaries(user.token, id);
        // if (res === 200) {
        //     getAwaitingApprovalBeneficiaries(
        //         user.token,
        //         approveBeneficiaryParams.page,
        //         approveBeneficiaryParams.limit,
        //         approveBeneficiaryParams
        //     );
        //     setSelectedItems([]);
        // }
    };

    const handleDownload = (fileLink) => {
        getDownloadFiles(user.token, fileLink);
    };

    const renderActionComponents = (data) => {
        return (
            <>
                <td className="py-3">
                    <div className="flex items-center gap-x-4 justify-center">
                        <Buttons.Primary
                            label={"ডাউনলোড"}
                            onClick={() => {
                                handleDownload(data.file);
                            }}
                            paddingX="px-7"
                            paddingY="py-2"
                            fontWeight="font-bold"
                        />
                        <Buttons.Primary
                            label={"রিআপ্লোড"}
                            onClick={() => setUploadOpen(!uploadOpen)}
                            paddingX="px-7"
                            paddingY="py-2"
                            fontWeight="font-bold"
                        />
                    </div>
                </td>
            </>
        );
    };

    const handleFailedUploadedExcelFiles = useCallback(() => {
        getFailedUploadedExcelFiles(
            user.token,
            failedExcelParams.page,
            failedExcelParams.limit
        );
    }, [
        getFailedUploadedExcelFiles,
        user.token,
        failedExcelParams.page,
        failedExcelParams.limit,
    ]);

    useEffect(() => {
        if (user) {
            handleFailedUploadedExcelFiles();
        }
    }, [
        user,
        failedExcelParams,
        getFailedUploadedExcelFiles,
        handleFailedUploadedExcelFiles,
    ]);

    useEffect(() => {
        if (failedExcelData) {
            const transformedArray = failedExcelData?.map((item) => ({
                _id: item._id,
                file: item.file,
                newFileName: item.file.split("/").pop(), // Extract the filename
                createdAt: new Date(item.createdAt).toLocaleDateString("en-US"), // Reformat the date
                createdBy: item.createdBy,
            }));
            setChangeFailedExcelData(transformedArray);
        }
    }, [failedExcelData]);

    return (
        <>
            {id ? (
                <Outlet />
            ) : (
                <div className="py-12 px-10 flex items-center flex-col">
                    <div className="shadow-medium-gray rounded-lg  overflow-hidden w-[350px] sm:w-[640px] md:w-[550] lg:w-[700px] xl:w-auto">
                        <TableHeader
                            selectedItems={selectedItems}
                            setSelectedItems={setSelectedItems}
                            title={"সকল ফেইল্ড এক্সেল লিস্ট"}
                            selectedTitle={"জন সিলেক্টেড"}
                        >
                            <>
                                <Buttons.Primary
                                    label={"অনুমোদন"}
                                    onClick={() => {
                                        handleApprove(selectedItems);
                                    }}
                                    paddingX="px-7"
                                    paddingY="py-2"
                                    fontWeight="font-bold"
                                />
                                <Buttons.Outline
                                    label={"বাতিল"}
                                    onClick={() => {
                                        handleReject(selectedItems);
                                    }}
                                    paddingX="px-7"
                                    paddingY="py-2"
                                    fontWeight="font-bold"
                                />
                            </>
                        </TableHeader>

                        <Table.Primary
                            // tableHeads={["উপকারভোগীর নাম", "পরিচয় পত্র নং", "ঠিকানা", "মোবাইল"]}
                            tableHeads={tableHeadsData}
                            // tableDataKeys={["name", "nid", "village", "mobile"]}
                            tableDataKeys={tableDataKeys}
                            // tableActionHeads={["কার্ড জেনারেট অনুরোধ অনুমোদন", ""]}
                            tableActionHeads={tableActionHeads}
                            tableData={changeFailedExcelData || []}
                            tableActionComponents={renderActionComponents}
                            setSelectedItems={setSelectedItems}
                            selectedItems={selectedItems}
                            isCheckbox={false}
                        />
                        <TableFooter
                            params={failedExcelParams}
                            setParams={setFailedExcelParams}
                            pageSize={failedExcelTotal}
                        />
                        {uploadOpen && (
                            <Layouts.Modal
                                overlayVariant="light-gray"
                                setOpen={setUploadOpen}
                            >
                                <UploadModal
                                    setOpen={setUploadOpen}
                                    handleRecal={handleFailedUploadedExcelFiles}
                                />
                            </Layouts.Modal>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default withToast(BeneficiariesFailedUploadedExcel);

const tableHeadsData = [
    {
        title: "আপ্লোডকারির নাম",
        align: "left",
        width: "w-[260px]",
    },
    {
        title: "ফাইলের নাম",
        align: "left",
        width: "w-[340px]",
    },
];

const tableDataKeys = [
    {
        title: "createdBy",
        width: "w-[260px]",
    },
    {
        title: "newFileName",
        width: "w-[340px]",
    },
];

const tableActionHeads = [
    {
        title: "ডাউনলোড / রিআপ্লোড",
        align: "center",
        width: "w-[360px]",
    },
];
