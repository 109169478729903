import BeneficiaryProvider from "./BeneficiaryProvider";
import CardProvider from "./CardProvider";
import DashboardProvider from "./DashboardProvider";
import ReportProvider from "./ReportPRovider";
import UserProvider from "./UserProvider";
import DistributionProvider from "./distributionProvider";
import ImageProvider from "./imageUploaderProvider";

const Context = {
    UserProvider,
    CardProvider,
    BeneficiaryProvider,
    DistributionProvider,
    ReportProvider,
    ImageProvider,
    DashboardProvider,
};

export default Context;
