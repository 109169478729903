import Icons from "../../assets/Icons";
import { Permission } from "../permissionStrings";
import * as PATH from "../Slug";

export const beneficiaryManagement = [
    {
        name: "উপকারভোগী ম্যানেজমেন্ট",
        icon: Icons.user_multiple,
        hoverIcon: Icons.user_multiple_hover,
        current: false,
        // component: Blogs,
        href: PATH.BENEFICIARIES_PATH,
        hidden: true,
        permissions: [Permission.READ_BENEFICIARY],
        children: [
            {
                name: "সকল উপকারভোগী",
                href: "all",
                // icon: PencilIcon,
                current: false,
                // parent: "Blogs",
                hidden: true,
                // component: CreateBlog,
                permissions: [
                    Permission.READ_BENEFICIARY,
                    Permission.CREATE_BENEFICIARY,
                ],
            },
            {
                name: "উপকারভোগী অনুমোদন",
                href: "approve",
                // icon: PencilIcon,
                current: false,
                // parent: "Blogs",
                hidden: true,
                // component: CreateBlog,
                permissions: [
                    Permission.READ_BENEFICIARY,
                    Permission.APPROVE_BENEFICIARY,
                ],
            },
            {
                name: "অনুমোদিত উপকারভোগী",
                href: "approved",
                // icon: PencilIcon,
                current: false,
                // parent: "Blogs",
                hidden: true,
                // component: CreateBlog,
                permissions: [
                    Permission.READ_BENEFICIARY,
                    Permission.REJECT_BENEFICIARY,
                ],
            },
            // {
            //     name: "বাতিলকৃত উপকারভোগী",
            //     href: "rejected",
            // {
            //     name: "অনুমোদনকৃত উপকারভোগী",
            //     href: "approved",
            //     // icon: PencilIcon,
            //     current: false,
            //     // parent: "Blogs",
            //     hidden: true,
            //     // component: CreateBlog,
            //     permissions: [
            //         Permission.READ_BENEFICIARY,
            //         Permission.APPROVE_BENEFICIARY,
            //     ],
            // },
            //         Permission.REJECT_BENEFICIARY,
            //     ],
            // },
            {
                name: "বাতিলকৃত উপকারভোগী",
                href: "rejected",
                // icon: PencilIcon,
                current: false,
                // parent: "Blogs",
                hidden: true,
                // component: CreateBlog,
                permissions: [
                    Permission.READ_BENEFICIARY,
                    Permission.APPROVE_BENEFICIARY,
                ],
            },
            {
                name: "উপজেলায় প্রেরণ",
                href: PATH.BENEFICIARY_UNION_PATH,
                // icon: PencilIcon,
                current: false,
                // parent: "Blogs",
                hidden: true,
                // component: CreateBlog,
                permissions: [
                    Permission.READ_BENEFICIARY,
                    Permission.SEND_TO_UPAZILLA_BENEFICIARY,
                ],
            },
            {
                name: "আপলোডেড এক্সেল",
                href: PATH.BENEFICIARIES_UPLOAD_EXCEL,
                // icon: PencilIcon,
                current: false,
                // parent: "Blogs",
                hidden: true,
                // component: CreateBlog,

                // Approve is added here in order to remove access of union user
                // to this page, as single create and bulk create permission is not separated in backend
                permissions: [
                    Permission.READ_BENEFICIARY,
                    Permission.APPROVE_BENEFICIARY,
                ],
            },
            {
                name: "ফেইল্ড এক্সেল",
                href: PATH.BENEFICIARIES_FAILED_UPLOAD_EXCEL,
                // icon: PencilIcon,
                current: false,
                // parent: "Blogs",
                hidden: true,
                // component: CreateBlog,

                // Approve is added here in order to remove access of union user
                // to this page, as single create and bulk create permission is not separated in backend
                permissions: [
                    Permission.READ_BENEFICIARY,
                    Permission.APPROVE_BENEFICIARY,
                ],
            },
        ],
    },
];
