import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import Icons from "../../assets/Icons";
import Buttons from "../../components/Buttons";
import IconWithLabel from "../../components/Buttons/IconWithLabel";
import TableFooter from "../../components/Common/TableFooter";
import TableHeader from "../../components/Common/TableHeader";
import Filter from "../../components/Filter";
import Table from "../../components/Table";
import { useBeneficiaryContext } from "../../context/BeneficiaryProvider";
import { useCardContext } from "../../context/CardProvider";
import { useUserContext } from "../../context/UserProvider";
import { withToast } from "../../hoc/withToast";
import Tooltip from "../../layouts/Tooltip";
import {
    approvedStatusDropdown,
    approvedStatusWithEN,
} from "../../libs/common";

const Beneficiaries = ({ showToast }) => {
    const [activeTooltip, setActiveTooltip] = useState("");
    const [selectedItems, setSelectedItems] = useState([]);
    const [firstRender, setFirstRender] = useState(true);

    const navigate = useNavigate();
    const { id } = useParams();

    const {
        villages,
        getVillageData,
        getAllBeneficiariesList,
        allBeneficiaries,
        allBeneficiariesTotal,
    } = useCardContext();

    const { allBeneficiaryParams, setAllBeneficiaryParams } =
        useBeneficiaryContext();
    const { user } = useUserContext();

    useEffect(() => {
        setActiveTooltip("");
    }, [navigate]);

    // const handleApprove = async (id) => {
    //     const res = await patchBeneficiaryApprove(user.token, id);
    //     if (res === 200) {
    //         getAllBeneficiariesList(
    //             user.token,
    //             allBeneficiaryParams.page,
    //             allBeneficiaryParams.limit,
    //             allBeneficiaryParams
    //         );
    //         setSelectedItems([]);
    //     }
    // };

    // const handleReject = async (id) => {
    //     const res = await patchBeneficiaryReject(user.token, id);
    //     if (res === 200) {
    //         getAllBeneficiariesList(
    //             user.token,
    //             allBeneficiaryParams.page,
    //             allBeneficiaryParams.limit,
    //             allBeneficiaryParams
    //         );
    //         setSelectedItems([]);
    //     }
    // };

    const renderActionComponents = (data) => {
        return (
            <>
                <td className="py-3 flex justify-center w-full">
                    <div
                        className={`text-sm whitespace-nowrap font-medium ${
                            approvedStatusWithEN.find(
                                (item) => item.name_en === data.status
                            )?.color
                        } px-2 py-1 rounded`}
                    >
                        {
                            approvedStatusWithEN.find(
                                (item) => item.name_en === data.status
                            )?.name_bn
                        }
                    </div>
                </td>
                <td>
                    <div className="px-4 relative">
                        <button
                            onClick={() => {
                                setActiveTooltip(data._id);
                            }}
                        >
                            <EllipsisVerticalIcon className="w-8 h-8 text-slate-700" />
                        </button>
                        {activeTooltip === data._id && (
                            <Tooltip
                                top={
                                    allBeneficiaries
                                        .slice(-2)
                                        .some((item) => item._id === data._id)
                                        ? allBeneficiaries.length >= 3
                                            ? "-top-20"
                                            : "-top-[1.4rem]"
                                        : "-top-3"
                                }
                                right="right-14"
                                overlayVariant="transparent"
                                setOpen={setActiveTooltip}
                                align={
                                    allBeneficiaries
                                        .slice(-2)
                                        .some((item) => item._id === data._id)
                                        ? allBeneficiaries.length >= 3
                                            ? "bottom"
                                            : "top"
                                        : "top"
                                }
                            >
                                <div className="p-2 bg-white rounded">
                                    <IconWithLabel
                                        icon={Icons.file}
                                        label="বিস্তারিত দেখুন"
                                        paddingY="py-2.5"
                                        // paddingX="px-5"
                                        width="w-[180px]"
                                        onClick={() => {
                                            navigate(`${data._id}`);
                                        }}
                                    />
                                </div>
                                <div className="p-2 bg-white rounded">
                                    <IconWithLabel
                                        icon={Icons.edit}
                                        label="আপডেট করুন"
                                        paddingY="py-2.5"
                                        // paddingX="px-5"
                                        width="w-[180px]"
                                        onClick={() =>
                                            navigate(`update/${data._id}`)
                                        }
                                    />
                                </div>
                            </Tooltip>
                        )}
                    </div>
                </td>
            </>
        );
    };

    useEffect(() => {
        if (user && firstRender) {
            getVillageData(user.token);
            getAllBeneficiariesList(
                user.token,
                allBeneficiaryParams.page,
                allBeneficiaryParams.limit,
                {
                    page: 1,
                    limit: 10,
                    startDate: null,
                    endDate: null,
                    status: null,
                    union: null,
                    search: null,
                }
            );
            setFirstRender(false);
            setAllBeneficiaryParams({
                page: 1,
                limit: 10,
                startDate: null,
                endDate: null,
                status: null,
                union: null,
                search: null,
            });
        } else if (user) {
            getVillageData(user.token);
            getAllBeneficiariesList(
                user.token,
                allBeneficiaryParams.page,
                allBeneficiaryParams.limit,
                allBeneficiaryParams
            );
        }
    }, [
        allBeneficiaryParams,
        firstRender,
        getAllBeneficiariesList,
        getVillageData,
        setAllBeneficiaryParams,
        user,
    ]);

    // useEffect(() => {
    //     // if (user) {
    //     // getVillageData(user.token);
    //     getAllBeneficiariesList(user.token, page, limit, allBeneficiaryParams);
    //     // }
    // }, []);

    return (
        <>
            {id ? (
                <Outlet />
            ) : (
                <div className="py-12 px-10 flex items-center flex-col">
                    <div>
                        <div className="py-9 w-full flex justify-start">
                            <Buttons.IconWithLabel
                                label="নতুন উপকারভোগী তৈরি (একক)"
                                paddingX="px-3"
                                paddingY="py-2.5"
                                icon={Icons.add_white}
                                bgColor="bg-green-600"
                                radius="rounded-[2px]"
                                textColor="text-white"
                                textSize={"font-bold"}
                                onClick={() =>
                                    navigate("/beneficiaries/create")
                                }
                            />
                        </div>
                        <div className="shadow-medium-gray rounded-lg overflow-hidden w-[350px] sm:w-[640px] md:w-auto">
                            <Filter.Primary
                                firstSelectData={approvedStatusDropdown}
                                firstSelectLabel="অনুমোদন স্ট্যাটাস"
                                secondSelectLabel="ঠিকানা"
                                secondSelectData={villages ? villages : []}
                                setQueryParams={setAllBeneficiaryParams}
                            />
                            <TableHeader
                                isBeneficiaries={true}
                                selectedItems={selectedItems}
                                setSelectedItems={setSelectedItems}
                                isDataUpEx={true}
                                title={"সকল উপকারভোগীর লিস্ট"}
                                selectedTitle={"জন সিলেক্টেড"}
                                showToast={showToast}
                                getAllBeneficiariesList={
                                    getAllBeneficiariesList
                                }
                                queryParams={allBeneficiaryParams}
                            ></TableHeader>

                            <Table.Primary
                                // tableHeads={["উপকারভোগীর নাম", "পরিচয় পত্র নং", "ঠিকানা", "মোবাইল"]}
                                tableHeads={tableHeadsData}
                                // tableDataKeys={["name", "nid", "village", "mobile"]}
                                tableDataKeys={tableDataKeys}
                                // tableActionHeads={["কার্ড জেনারেট অনুরোধ অনুমোদন", ""]}
                                tableActionHeads={tableActionHeads}
                                tableData={allBeneficiaries}
                                tableActionComponents={renderActionComponents}
                                setSelectedItems={setSelectedItems}
                                selectedItems={selectedItems}
                                isCheckbox={true}
                                needMinHeight={true}
                            />
                            <TableFooter
                                params={allBeneficiaryParams}
                                setParams={setAllBeneficiaryParams}
                                // limit={queryParams}
                                // setLimit={setLimit}
                                pageSize={allBeneficiariesTotal}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default withToast(Beneficiaries);

const tableHeadsData = [
    {
        title: "উপকারভোগীর নাম",
        align: "left",
        width: "w-[250px]",
    },
    {
        title: "পরিচয় পত্র নং",
        align: "left",
        width: "w-[202px]",
    },
    {
        title: "ঠিকানা",
        align: "left",
        width: "w-[202px]",
    },
    {
        title: "মোবাইল",
        align: "left",
        width: "w-[202px]",
    },
];

const tableDataKeys = [
    {
        title: "name",
        width: "w-[202px]",
    },
    {
        title: "nid",
        width: "w-[202px]",
    },
    {
        title: "union",
        width: "w-[202px]",
    },
    {
        title: "mobile",
        width: "w-[202px]",
    },
];

const tableActionHeads = [
    {
        title: "অনুমোদন স্ট্যাটাস",
        align: "center",
        width: "w-[202px]",
    },
    {
        title: "",
        align: "center",
        width: "w-[72px]",
    },
];
