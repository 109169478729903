import MobileSideDrawer from "../components/Common/MobileSideDrawer";
import SideDrawer from "../components/Common/SideDrawer";
import Navbar from "../components/Navbar";
import React from "react";

const Secondary = ({ children }) => {
    const [open, setOpen] = React.useState(false);
    return (
        <div>
            <Navbar.Primary setOpen={setOpen} open={open} />
            <SideDrawer />
            <MobileSideDrawer setOpen={setOpen} open={open} />
            {children}
        </div>
    );
};

export default Secondary;
