"use client";
import React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import Icons from "../../assets/Icons";

const Password = ({
    defaultValue,
    placeholder,
    register,
    name,
    width = "w-full",
    isRequired,
    errors,
    errorMsg,
    validations,
}) => {
    const [showPassword, setShowPassword] = React.useState(false);
    return (
        <div>
            <div
                className={`py-2.5 px-4 rounded-sm ${width} border border-slate-300 bg-white flex items-center gap-x-4`}
            >
                <input
                    type={showPassword ? "text" : "password"}
                    defaultValue={defaultValue}
                    {...register(name, { ...validations })}
                    placeholder={placeholder}
                    className={`text-base font-medium  text-primary-black text-opacity-[88%] outline-none border-none w-full py-1 px-1 bg-transparent`}
                />
                {showPassword ? (
                    <button
                        onClick={() => setShowPassword(false)}
                        type="button"
                    >
                        <img
                            src={Icons.open_eye}
                            alt="Eye-icons"
                            className="w-5 h-5 mr-1"
                        />
                    </button>
                ) : (
                    <button onClick={() => setShowPassword(true)} type="button">
                        <img
                            src={Icons.close_eye}
                            alt="Eye-icons"
                            className="w-5 h-5 mr-1"
                        />
                    </button>
                )}
            </div>

            <span
                className={`${width} text-xs flex items-start mt-3 text-red-600 ${
                    errors[name] ? "visible" : "invisible"
                }`}
            >
                <ExclamationCircleIcon className="w-4 h-4 mr-1.5" />{" "}
                <span className="mt-0.5">{errorMsg}</span>
            </span>
        </div>
    );
};

export default Password;
