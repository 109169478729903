import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import * as PATH from "./helpers/Slug";
import Login from "./pages/Login";
import Beneficiary from "./pages/Benificiary";
import Beneficiaries from "./pages/Benificiary/Beneficiaries";
import Card from "./pages/Card";
import CardRequest from "./pages/Card/CardRequest";
import CardApprove from "./pages/Card/CardApprove";
import CardRejected from "./pages/Card/CardRejected";
import CardGenerate from "./pages/Card/CardGenerate";
import CardGenerated from "./pages/Card/CardGenerated";
import CardDetails from "./pages/Card/CardDetails";
import { useUserContext } from "./context/UserProvider";
import { hasPermission } from "./utils";
import React from "react";
import { sidebarPermissions } from "./libs/sidebarPermissions";
import Loader from "./components/Common/Loader";
import BeneficiariesApproved from "./pages/Benificiary/BeneficiariesApproved";
import Report from "./pages/Report";
import ReportGenerate from "./pages/Report/ReportGenerate";
import BeneficiariesRejected from "./pages/Benificiary/BeneficiariesRejected";
import BeneficiariesApprove from "./pages/Benificiary/BeneficiariesApprove";
import DistributionManagement from "./pages/distribution";
import BeneficiariesUnion from "./pages/BeneficiaryUnion/BeneficiariesUnion";
import CardGenerateRequest from "./pages/CardUnion/CardGenerateRequest";
import UserManagement from "./pages/UserManagement";
import UserManageMentList from "./pages/UserManagement/UserManageMentList";
import NewUserCreate from "./pages/UserManagement/NewUserCreate";
import EditUser from "./pages/UserManagement/EditUser";
import ProfileSettings from "./pages/ProfileSettings";
import CreateDistributionSchedule from "./pages/distribution/CreateDistributionSchedule";
import UserResetPassword from "./pages/UserManagement/UserResetPassword";
import Dashboard from "./pages/Dashboard";
import DistributionInfo from "./pages/distribution/distributionInfo";
import BeneficiariesUploadExcel from "./pages/Benificiary/BeneficiariesUploadExcel";
import BeneficiariesFailedUploadedExcel from "./pages/Benificiary/BeneficiariesFailedUploadedExcel";
import CardPreview from "./pages/Card/CardPreview";
import GeneratedReports from "./pages/Report/GeneratedReports";
import DealerMessage from "./pages/DealerMessage";
import DistributionRead from "./pages/distribution/DistributionRead";
import DistributionWithNid from "./pages/distribution/DistributionWithNid";

function App() {
    const { user, loader } = useUserContext();
    // console.log("permissions", user);
    // const navigate = useNavigate();

    // useEffect(() => {
    // if (!user?.token) {
    //     return <Navigate to={PATH.LOGIN_PATH} replace />;
    // }
    // }, [user]);

    return (
        <>
            {loader && <Loader />}
            <BrowserRouter>
                {!JSON.parse(sessionStorage.getItem("user")) && (
                    <Navigate to={PATH.LOGIN_PATH} replace />
                )}
                <Routes>
                    <Route
                        path="/"
                        element={<Navigate to={PATH.LOGIN_PATH} replace />}
                    />
                    <Route
                        path={PATH.BENEFICIARIES_PATH}
                        element={
                            <Navigate
                                to={`${PATH.BENEFICIARIES_PATH}/${PATH.BENEFICIARIES_ALL_PATH}`}
                                replace
                            />
                        }
                    />

                    <Route
                        path={PATH.CARD_PATH}
                        element={
                            <Navigate
                                to={`${PATH.CARD_PATH}/${PATH.CARD_REQUEST_PATH}`}
                                replace
                            />
                        }
                    />

                    <Route
                        path={PATH.REPORT_PATH}
                        element={
                            <Navigate
                                to={`${PATH.REPORT_PATH}/${PATH.REPORT_GENERATE_PATH}`}
                                replace
                            />
                        }
                    />

                    <Route path={PATH.LOGIN_PATH} element={<Login />} />

                    {/* {sidebarMenu.map((route, index) => (
                    <React.Fragment key={index}>
                        {hasPermission(
                            user?.permissions,
                            route.permissions
                        ) && (
                            <Route
                                path={route.href}
                                element={
                                    route.component ? (
                                        <route.component />
                                    ) : (
                                        <></>
                                    )
                                }
                            >
                                {route.children &&
                                    route.children.map((child, index) => (
                                        <React.Fragment key={index}>
                                            {hasPermission(user?.permissions, [
                                                "ALL",
                                            ]) && (
                                                <Route
                                                    path={child.href}
                                                    element={
                                                        <child.component />
                                                    }
                                                />
                                            )}
                                        </React.Fragment>
                                    ))}
                            </Route>
                        )}
                    </React.Fragment>
                ))} */}

                    <Route path={PATH.DASHBOARD_PATH} element={<Dashboard />} />

                    <Route
                        path={PATH.BENEFICIARIES_PATH}
                        element={<Beneficiary />}
                    >
                        {hasPermission(
                            user?.permissions,
                            sidebarPermissions.allBeneficiaries
                        ) && (
                            <Route
                                path={PATH.BENEFICIARIES_ALL_PATH}
                                element={<Beneficiaries />}
                            >
                                <Route
                                    path={`:id`}
                                    element={
                                        <CardDetails
                                            type="allBeneficiariesActions"
                                            isAllBeneficiaries={true}
                                            isDetails={true}
                                        />
                                    }
                                />
                                <Route
                                    path={`update/:id`}
                                    element={
                                        <CardDetails type="allBeneficiariesActions" />
                                    }
                                />
                            </Route>
                        )}

                        {hasPermission(
                            user?.permissions,
                            sidebarPermissions.beneficiariesApprove
                        ) && (
                            <Route
                                path={PATH.BENEFICIARIES_APPROVE_PATH}
                                element={<BeneficiariesApprove />}
                            >
                                <Route
                                    path={`:id`}
                                    element={
                                        <CardDetails
                                            type="approveBeneficiaries"
                                            isDetails={true}
                                        />
                                    }
                                />
                            </Route>
                        )}
                        {hasPermission(
                            user?.permissions,
                            sidebarPermissions.beneficiariesApproved
                        ) && (
                            <Route
                                path={PATH.BENEFICIARIES_APPROVED_PATH}
                                element={<BeneficiariesApproved />}
                            >
                                <Route
                                    path={`:id`}
                                    element={
                                        <CardDetails
                                            type="approvedBeneficiaries"
                                            isDetails={true}
                                        />
                                    }
                                />
                            </Route>
                        )}
                        {hasPermission(
                            user?.permissions,
                            sidebarPermissions.beneficiariesRejected
                        ) && (
                            <Route
                                path={PATH.BENEFICIARIES_REJECTED_PATH}
                                element={<BeneficiariesRejected />}
                            >
                                <Route
                                    path={`:id`}
                                    element={
                                        <CardDetails
                                            type="rejectedBeneficiaries"
                                            isDetails={true}
                                        />
                                    }
                                />
                            </Route>
                        )}
                        {hasPermission(
                            user?.permissions,
                            sidebarPermissions.beneficiariesUnion
                        ) && (
                            <Route
                                path={PATH.BENEFICIARY_UNION_PATH}
                                element={<BeneficiariesUnion />}
                            >
                                <Route
                                    path={`:id`}
                                    element={
                                        <CardDetails
                                            type="sendToUpazila"
                                            isDetails={true}
                                        />
                                    }
                                />
                            </Route>
                        )}
                        {hasPermission(
                            user?.permissions,
                            sidebarPermissions.createBeneficiary
                        ) && (
                            <Route
                                path={PATH.CREATE_BENEFICIARY_UNION_PATH}
                                element={<CardDetails />}
                            />
                        )}
                        {hasPermission(
                            user?.permissions,
                            sidebarPermissions.beneficiariesApproved
                        ) && (
                            <Route
                                path={PATH.BENEFICIARIES_UPLOAD_EXCEL}
                                element={<BeneficiariesUploadExcel />}
                            />
                        )}
                        {hasPermission(
                            user?.permissions,
                            sidebarPermissions.beneficiariesApproved
                        ) && (
                            <Route
                                path={PATH.BENEFICIARIES_FAILED_UPLOAD_EXCEL}
                                element={<BeneficiariesFailedUploadedExcel />}
                            />
                        )}
                    </Route>

                    <Route path={PATH.CARD_PATH} element={<Card />}>
                        {hasPermission(
                            user?.permissions,
                            sidebarPermissions.cardRequest
                        ) && (
                            <Route
                                path={PATH.CARD_REQUEST_PATH}
                                element={<CardRequest />}
                            >
                                <Route
                                    path={`:id`}
                                    element={
                                        <CardDetails
                                            type="requestCard"
                                            isDetails={true}
                                        />
                                    }
                                />
                            </Route>
                        )}
                        {hasPermission(
                            user?.permissions,
                            sidebarPermissions.cardApprove
                        ) && (
                            <Route
                                path={PATH.CARD_APPROVED_PATH}
                                element={<CardApprove />}
                            >
                                <Route
                                    path={`:id`}
                                    element={
                                        <CardDetails
                                            type="approvedCard"
                                            isDetails={true}
                                        />
                                    }
                                />
                            </Route>
                        )}
                        {hasPermission(
                            user?.permissions,
                            sidebarPermissions.cardRejected
                        ) && (
                            <Route
                                path={PATH.CARD_REJECTED_PATH}
                                element={<CardRejected />}
                            >
                                <Route
                                    path={`:id`}
                                    element={
                                        <CardDetails
                                            type="rejectedCard"
                                            isDetails={true}
                                        />
                                    }
                                />
                            </Route>
                        )}
                        {hasPermission(
                            user?.permissions,
                            sidebarPermissions.cardGenerate
                        ) && (
                            <Route
                                path={PATH.CARD_GENERATE_PATH}
                                element={<CardGenerate />}
                            >
                                <Route
                                    path={`:id`}
                                    element={
                                        <CardDetails
                                            type="generateCard"
                                            isDetails={true}
                                        />
                                    }
                                />
                            </Route>
                        )}
                        {hasPermission(
                            user?.permissions,
                            sidebarPermissions.cardGenerated
                        ) && (
                            <Route
                                path={PATH.CARD_GENERATED_PATH}
                                element={<CardGenerated />}
                            >
                                <Route
                                    path={`:id`}
                                    element={<CardDetails isDetails={true} />}
                                />
                                <Route
                                    path={`preview/:id`}
                                    element={<CardPreview />}
                                />
                            </Route>
                        )}
                        {hasPermission(
                            user?.permissions,
                            sidebarPermissions.cardSendToUpazila
                        ) && (
                            <Route
                                path={PATH.CARD_UNION_PATH}
                                element={<CardGenerateRequest />}
                            />
                        )}
                    </Route>
                    {hasPermission(
                        user?.permissions,
                        sidebarPermissions.distributionRead
                    ) && (
                        <Route
                            path={PATH.DISTRIBUTION_SCHEDULE_PATH}
                            element={<DistributionManagement />}
                        >
                            {hasPermission(
                                user?.permissions,
                                sidebarPermissions.distributionRead
                            ) && (
                                <Route
                                    path={PATH.DISTRIBUTION_INFO}
                                    element={<DistributionInfo />}
                                ></Route>
                            )}
                            {hasPermission(
                                user?.permissions,
                                sidebarPermissions.distributionCreate
                            ) && (
                                <Route
                                    path={PATH.CREATE_PATH}
                                    element={
                                        <CreateDistributionSchedule
                                            create={true}
                                        />
                                    }
                                ></Route>
                            )}
                            {hasPermission(
                                user?.permissions,
                                sidebarPermissions.distributionUpdate
                            ) && (
                                <Route
                                    path={`:id`}
                                    element={
                                        <CreateDistributionSchedule
                                            isDetails={true}
                                        />
                                    }
                                ></Route>
                            )}
                            {hasPermission(
                                user?.permissions,
                                sidebarPermissions.distributionUpdate
                            ) && (
                                <Route
                                    path={`update/:id`}
                                    element={<CreateDistributionSchedule />}
                                ></Route>
                            )}
                            {hasPermission(
                                user?.permissions,
                                sidebarPermissions.distributionUpdate
                            ) && (
                                <Route
                                    path={`${PATH.DISTRIBUTION_READ}`}
                                    element={<DistributionRead />}
                                ></Route>
                            )}

                            {hasPermission(
                                user?.permissions,
                                sidebarPermissions.distributionUpdate
                            ) && (
                                <Route
                                    path={PATH.DISTRIBUTION_NID}
                                    element={<DistributionWithNid />}
                                ></Route>
                            )}
                        </Route>
                    )}

                    <Route path={PATH.REPORT_PATH} element={<Report />}>
                        {hasPermission(
                            user?.permissions,
                            sidebarPermissions.reportGenerate
                        ) && (
                            <Route
                                path={PATH.REPORT_GENERATE_PATH}
                                element={<ReportGenerate />}
                            ></Route>
                        )}
                        {hasPermission(
                            user?.permissions,
                            sidebarPermissions.reportGenerate
                        ) && (
                            <Route
                                path={PATH.GENERATED_REPORTS_PATH}
                                element={<GeneratedReports />}
                            ></Route>
                        )}
                    </Route>
                    {hasPermission(
                        user?.permissions,
                        sidebarPermissions.userManagementList
                    ) && (
                        <Route
                            path={PATH.USER_MANAGEMENT_PATH}
                            element={<UserManagement />}
                        >
                            {hasPermission(
                                user?.permissions,
                                sidebarPermissions.userManagementList
                            ) && (
                                <Route
                                    path={``}
                                    element={<UserManageMentList />}
                                />
                            )}
                            {hasPermission(
                                user?.permissions,
                                sidebarPermissions.newUserCreate
                            ) && (
                                <Route
                                    path={PATH.USER_MANAGEMENT_CREATE_PATH}
                                    element={<NewUserCreate />}
                                />
                            )}

                            {hasPermission(
                                user?.permissions,
                                sidebarPermissions.editUser
                            ) && (
                                <Route
                                    path={`${PATH.USER_MANAGEMENT_UPDATE_PATH}/:id`}
                                    element={<EditUser />}
                                />
                            )}

                            {hasPermission(
                                user?.permissions,
                                sidebarPermissions.userPasswordChange
                            ) && (
                                <Route
                                    path={`${PATH.USER_MANAGEMENT_RESET_PASSWORD_PATH}/:id`}
                                    element={<UserResetPassword />}
                                />
                            )}
                        </Route>
                    )}
                    {hasPermission(
                        user?.permissions,
                        sidebarPermissions.profile
                    ) && (
                        <Route
                            path={PATH.USER_PROFILE_SETTINGS}
                            element={<ProfileSettings />}
                        ></Route>
                    )}
                    <Route
                        path={"/dealer-info"}
                        element={<DealerMessage />}
                    ></Route>
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
