import React, { useRef, useState } from "react";
import Icons from "../../assets/Icons";
import { useCardContext } from "../../context/CardProvider";
import { useUserContext } from "../../context/UserProvider";
import Buttons from "../Buttons";
import { withToast } from "../../hoc/withToast";

const UploadModal = ({ setOpen, showToast, getAllBeneficiariesList, queryParams, handleRecal }) => {
    const fileInputRef = useRef(null);
    const [file, setFile] = useState(null);
    const { postBeneficiariesListFile } = useCardContext();
    const { user } = useUserContext();
    const [loading, setLoading] = useState(false);

    const openFileUploader = () => {
        fileInputRef.current.click();
    };

    const handleFileSelect = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            // Handle the selected file here (e.g., upload it to a server)
            setFile(selectedFile);
        }
    };

    const clearFile = () => {
        setFile(null);
    };

    const handleSubmit = async () => {
        if (file) {
            const formData = new FormData();
            formData.append("file", file);

            setLoading(true);

            const res = await postBeneficiariesListFile(user.token, formData);

            if (res.status === 200 || 206) {
                setLoading(false);
                setFile(null);
                setOpen(false);
                showToast(res.message);
                handleRecal && handleRecal();
                getAllBeneficiariesList && getAllBeneficiariesList(user.token, 1, 10, queryParams);
            }
        } else {
            showToast("অনুগ্রহ করে, আপনাকে অবশ্যই একটি ফাইল আপলোড করতে হবে।", true);
        }
    };

    return (
        <div className="w-[402px] rounded overflow-hidden bg-white">
            <div className="p-4 bg-blue-950 flex items-center justify-between">
                <p className="text-slate-50 text-base leading-6">উপকারভোগীর ডাটা আপলোড</p>
                <button onClick={() => setOpen(false)}>
                    <img
                        src={Icons.cross_white}
                        alt="cross_white"
                        className="w-5 h-5"
                    />
                </button>
            </div>
            <div className="p-4">
                <p className="text-blue-950 text-sm font-semibold leading-[22px]">আপলোড এক্সেল ফাইল</p>
                <div className="mt-3 flex items-center gap-2">
                    {!file ? (
                        <>
                            <button
                                onClick={openFileUploader}
                                className="py-2.5 px-6 flex items-center gap-x-2 bg-slate-100 rounded-sm">
                                <img
                                    src={Icons.add}
                                    alt="upload"
                                    className="w-5 h-5"
                                />
                                <p className="mb-0 mt-1 text-sm font-bold text-blue-950 leading-[18px]">ফাইল যুক্ত করুন</p>
                            </button>
                            <input
                                type="file"
                                className="hidden"
                                ref={fileInputRef}
                                onChange={handleFileSelect}
                                accept=".xlsx, .xls"
                            />
                        </>
                    ) : (
                        <>
                            <p className="text-slate-500 text-base font-medium">{file?.name}</p>
                            <button onClick={clearFile}>
                                <img
                                    src={Icons.cancel}
                                    alt="cross_white"
                                    className="w-5 h-5"
                                />
                            </button>
                        </>
                    )}
                </div>
                <p className="text-slate-500 text-xs font-medium mt-6">
                    নমুনা ফাইল :{" "}
                    <a
                        className="text-blue-800"
                        href="https://docs.google.com/spreadsheets/d/1vYg-RtT7Zl5SxO7K2M1xgKevmc7DmyD_/edit?usp=sharing&ouid=106598345912233080943&rtpof=true&sd=true"
                        target="_blank"
                        rel="noopener noreferrer">
                        TCB Smart Card Info.xlsx
                    </a>
                </p>
                <p className="text-slate-500 text-xs font-medium mt-1">প্রদত্ত ফাইলের কাঠামো বজায় রাখতে হবে। সবচেয়ে গুরুত্বপূর্ণ হল, ডেটা দ্বিতীয় পৃষ্ঠায় থাকবে এবং কলামের ক্রম একই হতে হবে।</p>
            </div>
            <div className="p-4 flex justify-end items-center gap-x-4">
                <Buttons.Outline
                    label={"বাতিল"}
                    onClick={() => setOpen(false)}
                    paddingX="px-6"
                    paddingY="py-2"
                    fontWeight="font-bold"
                    textColor={"text-slate-600"}
                    borderColor={"border-slate-400"}
                />
                <Buttons.Primary
                    label={loading ? "আপলোডিং..." : "আপলোড"}
                    isDisabled={loading}
                    onClick={handleSubmit}
                    paddingX="px-6"
                    paddingY="py-2"
                    fontWeight="font-bold"
                />
            </div>
        </div>
    );
};

export default withToast(UploadModal);
