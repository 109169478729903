import React, { useCallback, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import SecondaryTableHeader from "../../components/Common/SecondaryTableHeader";
import UserForm from "../../sections/UserManagement/UserForm";
import { useUserContext } from "../../context/UserProvider";
import { useBeneficiaryContext } from "../../context/BeneficiaryProvider";
import { useImageContext } from "../../context/imageUploaderProvider";
import { useNavigate } from "react-router-dom";
import { withToast } from "../../hoc/withToast";

const NewUserCreate = ({ showToast }) => {
    const { id } = useParams();
    const { user, createUser } = useUserContext();
    const { villages, getVillageData } = useBeneficiaryContext();
    const { imageUpload } = useImageContext();
    const navigate = useNavigate();
    const [selectedImage, setSelectedImage] = React.useState({
        preview: "",
        raw: "",
    });

    useEffect(() => {
        setSelectedImage({
            preview: "",
            raw: "",
        });
    }, []);

    useEffect(() => {
        getVillageData(user.token);
    }, [getVillageData, user.token]);

    const handleImageUploader = useCallback(
        async (image) => {
            const res = await imageUpload(user.token, image);
            return res;
        },
        [imageUpload, user.token]
    );

    const handleSubmit = async (data) => {
        // console.log(getImageUploaderResponse, "data");
        // console.log(data, "data2");
        const body = {
            name: data.name,
            mobile: data.mobile,
            password: data.password,
            role: data.role,
            union: data.union,
        };
        if (data.password === data.confirmPassword) {
            if (selectedImage.raw) {
                try {
                    const res = await handleImageUploader(selectedImage.raw);

                    if (res.data.key) {
                        const uploadedImageURL = res.data.key;
                        const payload = { ...body, image: uploadedImageURL };
                        const response = await createUser(user.token, payload);
                        if (response === 200) {
                            navigate(-1);
                        }
                    }
                } catch (error) {
                    const payload = { ...body, image: "" };
                    const response = await createUser(user.token, payload);
                    if (response === 200) {
                        navigate(-1);
                    }
                }
            } else {
                const payload = { ...body, image: "" };
                const response = await createUser(user.token, payload);
                if (response === 200) {
                    navigate(-1);
                }
            }
        } else {
            showToast("পাসওয়ার্ড মিলেনি");
        }
    };

    return (
        <>
            {id ? (
                <Outlet />
            ) : (
                <div className="flex flex-col items-center px-10 py-9">
                    <div className="rounded-lg">
                        <SecondaryTableHeader title="ব্যাবহারকারীর তথ্য" />
                        <UserForm
                            villages={villages}
                            create={true}
                            handleImageUpload={handleImageUploader}
                            handleSubmitData={handleSubmit}
                            selectedImage={selectedImage}
                            setSelectedImage={setSelectedImage}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default withToast(NewUserCreate);
