import React, { useEffect, useState } from "react";
import Tooltip from "../../layouts/Tooltip";
import Icons from "../../assets/Icons";
import { translateENToBN } from "../../utils";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

const SelectField = ({
    data,
    label,
    name,
    setQueryParams,
    width,
    register,
    isReadOnly,
    validations,
    defaultValue,
    placeholder,
    paddingX,
    paddingY,
    textSize,
    fontWeight,
    border,
    radius,
    dropdownPosition,
    setSelectedItems,
    placeHolder,
    handleSelectClick,
    overlayZIndex,
    errorMsg,
    openOnce = false,
    dropDownHeight,
    isUnionReset,
}) => {
    const [selected, setSelected] = useState("");
    const [open, setOpen] = useState(false);
    // console.log("deflst---------", defaultValue);

    useEffect(() => {
        setSelected(translateENToBN(defaultValue));
        if (isUnionReset) {
            setSelected("");
        }
    }, [defaultValue, isUnionReset]);

    const handleClick = (value) => {
        setSelected(value);
        handleSelectClick && handleSelectClick(value);
        setOpen(!open);
    };
    return (
        <div className="relative">
            <fieldset
                className={`${border} ${radius} ${paddingX} ${paddingY} flex items-center`}
            >
                {/* <legend className="text-[#919EAB] text-xs font-medium">
                    {label}
                </legend> */}
                <input
                    type="text"
                    readOnly={isReadOnly ? true : false}
                    value={selected}
                    placeholder={placeholder}
                    onClick={() => !isReadOnly && setOpen(!open)}
                    className={`${textSize} ${fontWeight} text-slate-800 leading-6 border-none outline-none w-full`}
                />

                <button
                    type="button"
                    onClick={() => !isReadOnly && setOpen(!open)}
                >
                    <img
                        src={Icons.Dropdown}
                        alt=""
                        className={open ? "rotate-180" : "rotate-0"}
                    />
                </button>
            </fieldset>
            <span
                className={`text-xs flex items-center mt-3 text-red-600 ${
                    openOnce && !selected ? "visible" : "invisible"
                }`}
            >
                <ExclamationCircleIcon className="w-4 h-4 mr-1.5" />{" "}
                <span className="mt-0.5">{errorMsg}</span>
            </span>
            {open && (
                <Tooltip
                    top={dropdownPosition}
                    right=""
                    overlayVariant="transparent"
                    setOpen={setOpen}
                    isIndicator={false}
                    frameWidth={"w-full"}
                    overlayZIndex={overlayZIndex}
                >
                    <div
                        className={`p-2 bg-white rounded shadow-light-gray flex flex-col w-full ${
                            dropDownHeight ? dropDownHeight : "max-h-[24rem]"
                        } overflow-auto`}
                    >
                        {data.map((item, index) => (
                            <button
                                key={index}
                                className="py-2 px-2 text-gray-700 text-sm bg-white hover:bg-gray-50 rounded text-left"
                                onClick={() => handleClick(item)}
                            >
                                {item}
                            </button>
                        ))}
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

export default SelectField;
