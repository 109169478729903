import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useBeneficiaryContext } from "../../context/BeneficiaryProvider";
import { useUserContext } from "../../context/UserProvider";
import Buttons from "../../components/Buttons";
import Filter from "../../components/Filter";
import TableHeader from "../../components/Common/TableHeader";
import Table from "../../components/Table";
import TableFooter from "../../components/Common/TableFooter";
import { useCardContext } from "../../context/CardProvider";

const CardGenerateRequest = () => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [firstRender, setFirstRender] = useState(true);

    // const [page, setPage] = useState(1);
    // const [limit, setLimit] = useState(10);

    const { id } = useParams();

    const {
        villages,
        getVillageData,
        generateRequestParams,
        setGenerateRequestParams,
        patchCardGenerateRequest,
    } = useCardContext();

    const {
        getApprovedBeneficiaries,
        approvedBeneficiaries,
        approveBeneficiariesTotalPage,
    } = useBeneficiaryContext();
    const { user } = useUserContext();

    useEffect(() => {
        setGenerateRequestParams({
            page: 1,
            limit: 10,
            startDate: null,
            status: null,
            union: null,
            search: null,
        });
    }, [setGenerateRequestParams]);

    const handleSendRequest = async (id) => {
        const res = await patchCardGenerateRequest(user.token, id);
        if (res === 200) {
            getApprovedBeneficiaries(
                user.token,
                generateRequestParams.page,
                generateRequestParams.limit,
                generateRequestParams
            );
            setSelectedItems([]);
        }
    };

    const renderActionComponents = (data) => {
        return (
            <>
                <td className="py-3 flex justify-center">
                    <div>
                        <Buttons.Primary
                            label={"কার্ড জেনারেট অনুরোধ প্রেরন"}
                            onClick={() => {
                                handleSendRequest(data._id);
                            }}
                            paddingX="px-6"
                            paddingY="py-2"
                            fontWeight="font-bold"
                            textSize="text-[13px]"
                        />
                    </div>
                </td>
            </>
        );
    };

    useEffect(() => {
        if (user && firstRender) {
            getVillageData(user.token);
            getApprovedBeneficiaries(
                user.token,
                generateRequestParams.page,
                generateRequestParams.limit,
                {
                    page: 1,
                    limit: 10,
                    startDate: null,
                    status: null,
                    union: null,
                    search: null,
                }
            );
            setFirstRender(false);
            setGenerateRequestParams({
                page: 1,
                limit: 10,
                startDate: null,
                status: null,
                union: null,
                search: null,
            });
        } else if (user) {
            getVillageData(user.token);
            getApprovedBeneficiaries(
                user.token,
                generateRequestParams.page,
                generateRequestParams.limit,
                generateRequestParams
            );
        }
    }, [
        user,
        generateRequestParams,
        getVillageData,
        getApprovedBeneficiaries,
        firstRender,
        setGenerateRequestParams,
    ]);

    return (
        <>
            {id ? (
                <Outlet />
            ) : (
                <div className="py-12 px-10 flex items-center rounded flex-col">
                    <div className="shadow-medium-gray rounded-lg overflow-hidden w-[350px] sm:w-[640px] md:w-[550] lg:w-[700px] xl:w-auto">
                        <Filter.Primary
                            // firstSelectData={approvedStatusDropdown}
                            firstSelectLabel="অনুমোদন স্ট্যাটাস"
                            secondSelectLabel="ঠিকানা"
                            secondSelectData={villages ? villages : []}
                            setQueryParams={setGenerateRequestParams}
                        />
                        <TableHeader
                            selectedItems={selectedItems}
                            setSelectedItems={setSelectedItems}
                            title={"সকল অনুমোদনকৃত উপকারভোগীর লিস্ট"}
                            selectedTitle={"জন সিলেক্টেড"}
                        >
                            <>
                                <Buttons.Primary
                                    label={"কার্ড জেনারেট অনুরোধ প্রেরন"}
                                    onClick={() => {
                                        handleSendRequest(selectedItems);
                                    }}
                                    paddingX="px-6"
                                    paddingY="py-2"
                                    fontWeight="font-bold"
                                    textSize="text-[13px]"
                                />
                            </>
                        </TableHeader>
                        <Table.Primary
                            tableHeads={tableHeadsData}
                            tableDataKeys={tableDataKeys}
                            tableActionHeads={tableActionHeads}
                            tableData={approvedBeneficiaries}
                            tableActionComponents={renderActionComponents}
                            setSelectedItems={setSelectedItems}
                            selectedItems={selectedItems}
                        />
                        <TableFooter
                            params={generateRequestParams}
                            setParams={setGenerateRequestParams}
                            pageSize={approveBeneficiariesTotalPage}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default CardGenerateRequest;

const tableHeadsData = [
    {
        title: "উপকারভোগীর নাম",
        align: "left",
        width: "w-[190px]",
    },
    {
        title: "পরিচয় পত্র নং",
        align: "left",
        width: "w-[190px]",
    },
    {
        title: "ঠিকানা",
        align: "left",
        width: "w-[190px]",
    },
    {
        title: "মোবাইল",
        align: "left",
        width: "w-[190px]",
    },
];

const tableDataKeys = [
    {
        title: "name",
        width: "w-[190px]",
    },
    {
        title: "nid",
        width: "w-[190px]",
    },
    {
        title: "union",
        width: "w-[190px]",
    },
    {
        title: "mobile",
        width: "w-[190px]",
    },
];

const tableActionHeads = [
    {
        title: "কার্ড জেনারেট অনুরোধ প্রেরন",
        align: "center",
        width: "w-[245px]",
    },
    // {
    //     title: "",
    //     align: "center",
    //     width: "w-[62px]",
    // },
];
