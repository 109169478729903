import React, { useEffect, useCallback } from "react";
import BeneficiaryForm from "../../sections/BeneficiaryForm.jsx";
import { useNavigate, useParams } from "react-router-dom";
import { useCardContext } from "../../context/CardProvider";
import { useUserContext } from "../../context/UserProvider";
import { useBeneficiaryContext } from "../../context/BeneficiaryProvider";
import { useImageContext } from "../../context/imageUploaderProvider";
import { useState } from "react";
import BeneficiaryUpdateForm from "../../sections/BeneficiaryForm.jsx/BeneficiaryUpdateForm.jsx";
import { dateFormatter } from "../../utils/index.js";

const CardDetails = ({ type, isDetails, isAllBeneficiaries = false }) => {
    const { id } = useParams();
    const { getBeneficiariesById, getAllBeneficiariesList } = useCardContext();
    const {
        createBeneficiary,
        updateBeneficiary,
        allBeneficiaryParams,
        getVillageData,
        villages,
    } = useBeneficiaryContext();
    const { imageUpload } = useImageContext();
    const [singleBeneficiaries, setSingleBeneficiaries] = useState({});
    const { user } = useUserContext();
    const navigate = useNavigate();
    // for image uploader.
    const [selectedImage, setSelectedImage] = React.useState({
        preview: "",
        raw: "",
    });
    // console.log(selectedImage.raw);

    const handleGetData = useCallback(async () => {
        if (id && user) {
            const response = await getBeneficiariesById(user.token, id);
            setSingleBeneficiaries(response);
        }
    }, [id, user, getBeneficiariesById, setSingleBeneficiaries]);

    useEffect(() => {
        handleGetData();
        getVillageData(user.token);
    }, [getVillageData, handleGetData, id, user]);

    // console.log("villages------------", villages);

    // const handleBackNav = () => {
    //     setSingleBeneficiaries({});
    //     navigate(-1);
    // };

    // const handleApprove = async (id) => {
    //     const res = await patchBeneficiaryApprove(user.token, id);
    //     if (res === 200) {
    //         getBeneficiaries(user.token, 1, 10, queryParams);
    //         navigate(-1);
    //     }
    // };

    // const handleReject = async (id) => {
    //     const res = await patchBeneficiaryReject(user.token, id);
    //     if (res === 200) {
    //         getBeneficiaries(user.token, 1, 10, queryParams);
    //         navigate(-1);
    //     }
    // };

    // Image uploader.
    const handleImageUploader = useCallback(
        async (image) => {
            const response = await imageUpload(user.token, image);
            return response;
        },
        [imageUpload, user.token]
    );

    // if, image upload successfully then form upload with image url, otherwise form upload without imageURL.
    const handleCreate = async (data) => {
        if (selectedImage.raw) {
            const res = await handleImageUploader(selectedImage.raw);

            if (res.data.key) {
                const uploadedImageURL = res.data.key;
                const finalData = { ...data, image: uploadedImageURL };
                finalData.dob = dateFormatter(finalData.dob, false);
                await createBeneficiary(user.token, finalData);
                setSelectedImage({
                    preview: "",
                    raw: "",
                });
            }
        } else {
            const finalData = { ...data, image: "" };
            finalData.dob = dateFormatter(finalData.dob, false);
            await createBeneficiary(user.token, finalData);
            setSelectedImage({
                preview: "",
                raw: "",
            });
        }
    };

    const handleUpdate = async (data) => {
        // if (getImageUploaderResponse !== null) {
        //     data.image = getImageUploaderResponse;
        // }
        if (selectedImage.raw) {
            try {
                const res = await handleImageUploader(selectedImage.raw);

                if (res.data.key) {
                    const uploadedImageURL = res.data.key;
                    const finalData = { ...data, image: uploadedImageURL };
                    // finalData.dob = dateFormatter(finalData.dob, false);
                    await updateBeneficiary(user.token, finalData, id);
                }
            } catch (error) {
                const finalData = { ...data };
                await updateBeneficiary(user.token, finalData, id);
            }
        } else {
            const finalData = { ...data };
            const res = await updateBeneficiary(user.token, finalData, id);
            if (res === 200) {
                getAllBeneficiariesList(
                    user.token,
                    allBeneficiaryParams.page,
                    allBeneficiaryParams.limit,
                    allBeneficiaryParams
                );
            }
        }
        navigate("/beneficiaries/all");
    };

    return (
        <div className="py-9 px-10  flex justify-center">
            <div className="rounded-lg overflow-hidden bg-white shadow-light-gray w-[1080px]">
                <div className="py-4 px-6 bg-blue-100">
                    <p className="text-base text-blue-950">
                        উপকারভোগীর বিস্তারিত
                    </p>
                </div>
                <div className="p-6">
                    {singleBeneficiaries && isDetails ? (
                        <BeneficiaryForm
                            villages={villages}
                            singleUser={singleBeneficiaries}
                            handleUpdate={handleUpdate}
                            create={false}
                            isDetails={isDetails}
                            isAllBeneficiaries={isAllBeneficiaries}
                            handleImageUpload={handleImageUploader}
                            type={type}
                            selectedImage={selectedImage}
                            setSelectedImage={setSelectedImage}
                        />
                    ) : singleBeneficiaries?.nid ? (
                        <BeneficiaryUpdateForm
                            villages={villages}
                            singleUser={singleBeneficiaries}
                            handleUpdate={handleUpdate}
                            create={false}
                            // isAllBeneficiaries={isAllBeneficiaries}
                            handleImageUpload={handleImageUploader}
                            type={type}
                            selectedImage={selectedImage}
                            setSelectedImage={setSelectedImage}
                        />
                    ) : (
                        <BeneficiaryForm
                            villages={villages}
                            create={true}
                            handleCreate={handleCreate}
                            isAllBeneficiaries={isAllBeneficiaries}
                            handleImageUpload={handleImageUploader}
                            selectedImage={selectedImage}
                            setSelectedImage={setSelectedImage}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default CardDetails;
