import axios from "axios";
import {
    DISTRIBUTION_GET_URL,
    USER_MANAGEMENT_URL,
    DISTRIBUTION_SCHEDULE_CREATE,
    DISTRIBUTION_READ,
    DISTRIBUTION_READ_WITH_NID,
} from "../helpers/APIURL";

const getAllDistributionList = (token, page, limit, queryParams) => {
    const config = {
        headers: {
            authorization: token,
        },
        params: {
            page,
            limit,
            ...(queryParams.status && { status: queryParams.status }),
            ...(queryParams.startDate && { startDate: queryParams.startDate }),
            ...(queryParams.endDate && { endDate: queryParams.endDate }),
            ...(queryParams.union && { union: queryParams.union }),
            ...(queryParams.search && { search: queryParams.search }),
        },
    };
    return axios.get(DISTRIBUTION_GET_URL, config);
};

const getSingleDistribution = (token, id) => {
    const config = {
        headers: {
            authorization: token,
        },
        params: {
            id,
        },
    };
    return axios.get(DISTRIBUTION_GET_URL, config);
};

const getDealerList = (token, limit = 10) => {
    const config = {
        headers: {
            authorization: token,
        },
        params: {
            role: "Dealer",
            limit: limit,
        },
    };
    return axios.get(USER_MANAGEMENT_URL, config);
};

const createDistributionSchedule = (token, data) => {
    const config = {
        headers: {
            authorization: token,
        },
    };
    return axios.post(DISTRIBUTION_SCHEDULE_CREATE, data, config);
};

const updateDistributionSchedule = (token, id, data) => {
    const config = {
        headers: {
            authorization: token,
        },
        params: {
            id,
        },
    };
    return axios.patch(DISTRIBUTION_SCHEDULE_CREATE, data, config);
};

const deleteDistributionSchedule = (token, id) => {
    let formattedId = id;

    if (Array.isArray(id)) {
        formattedId = id.map((id) => `id=${id}`).join("&");
        return axios.delete(`${DISTRIBUTION_SCHEDULE_CREATE}?${formattedId}`, {
            headers: {
                Authorization: token, // Use "Bearer" for JWT tokens, adjust as needed
            },
        });
    }

    const config = {
        headers: {
            authorization: token,
        },
        params: {
            id,
        },
    };
    return axios.delete(DISTRIBUTION_SCHEDULE_CREATE, config);
};

const distributionRead = (token, page, limit, queryParams) => {
    const config = {
        headers: {
            authorization: token,
        },
        params: {
            page,
            limit,
            ...(queryParams.status && { status: queryParams.status }),
            ...(queryParams.startDate && { startDate: queryParams.startDate }),
            ...(queryParams.endDate && { endDate: queryParams.endDate }),
            ...(queryParams.union && { union: queryParams.union }),
            ...(queryParams.search && { search: queryParams.search }),
        },
    };
    return axios.get(DISTRIBUTION_READ, config);
};

const distributionReadWithNid = (token, page, limit, queryParams) => {
    const config = {
        headers: {
            authorization: token,
        },
        params: {
            page,
            limit,
            ...(queryParams.status && { status: queryParams.status }),
            ...(queryParams.startDate && { startDate: queryParams.startDate }),
            ...(queryParams.endDate && { endDate: queryParams.endDate }),
            ...(queryParams.union && { union: queryParams.union }),
            ...(queryParams.search && { search: queryParams.search }),
        },
    };
    return axios.get(DISTRIBUTION_READ_WITH_NID, config);
};

const DistributionService = {
    getAllDistributionList,
    getSingleDistribution,
    getDealerList,
    createDistributionSchedule,
    updateDistributionSchedule,
    deleteDistributionSchedule,
    distributionRead,
    distributionReadWithNid,
};

export default DistributionService;
