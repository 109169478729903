import React, { createContext, useCallback, useContext, useState } from "react";
import { withToast } from "../hoc/withToast";
import DistributionService from "../services/distributionService";
import { useUserContext } from "./UserProvider";

const DistributionContext = createContext({});
export const useDistributionContext = () => {
    return useContext(DistributionContext);
};

const DistributionProvider = ({ showToast, children }) => {
    const { setLoader } = useUserContext();
    const [getAllDistributionList, setAllDistributionList] = useState([]);
    const [getAllDistributionListTotal, setAllDistributionListTotal] =
        useState(null);
    const [dealerList, setDealerList] = useState([]);

    const [singleDistribution, setSingleDistribution] = useState({});

    const [distributionParams, setDistributionParams] = useState({
        page: 1,
        limit: 10,
        startDate: null,
        endDate: null,
        status: null,
        union: null,
        search: null,
    });

    const [distributionRead, setDistributionRead] = useState([]);
    const [distributionReadWithNid, setDistributionReadWithNid] = useState([]);
    const [distributionReadTotal, setDistributionReadTotal] = useState(null);
    const [distributionReadWithNidTotal, setDistributionReadWithNidTotal] =
        useState(null);

    const [distributionReadParams, setDistributionReadParams] = useState({
        page: 1,
        limit: 10,
        startDate: null,
        endDate: null,
        status: null,
        union: null,
        search: null,
    });

    const [distributionReadWithNidParams, setDistributionReadWithNidParams] =
        useState({
            page: 1,
            limit: 10,
            startDate: null,
            endDate: null,
            status: null,
            union: null,
            search: null,
        });

    //------------ Distribution table data ------------//
    const handleGetAllDistributionList = useCallback(
        async (token, page, limit, queryParams) => {
            try {
                setLoader(true);
                const res = await DistributionService.getAllDistributionList(
                    token,
                    page,
                    limit,
                    queryParams
                );

                if (res) {
                    setAllDistributionList(res.data.distributions);
                    setAllDistributionListTotal(res.data.totalPages);
                }
            } catch (e) {
                // console.log(e.message);
                if (e.response.status === 404) {
                    setAllDistributionList([]);
                }
            } finally {
                setLoader(false);
            }
        },
        [setLoader]
    );

    // -------- single Distributiion data ------------- //
    const getSingleDistribution = useCallback(
        async (token, id) => {
            try {
                setLoader(true);
                const res = await DistributionService.getSingleDistribution(
                    token,
                    id
                );
                if (res) {
                    setSingleDistribution(res.data.distribution);
                }
            } catch (e) {
            } finally {
                setLoader(false);
            }
        },
        [setLoader]
    );

    // -------- Dealer List --------- //

    const getDealerList = useCallback(
        async (token) => {
            try {
                setLoader(true);
                const res = await DistributionService.getDealerList(
                    token,
                    100000
                );
                if (res) {
                    // console.log(res.data.users);
                    setDealerList(res.data.users);
                }
            } catch (e) {
            } finally {
                setLoader(false);
            }
        },
        [setLoader]
    );

    // --------- Create distribution schedule ---------- //

    const createDistributionSchedule = async (token, data) => {
        setLoader(true);
        try {
            const res = await DistributionService.createDistributionSchedule(
                token,
                data
            );
            if (res) {
                setLoader(false);
                showToast(res.data.message);
                return res.status;
            }
        } catch (e) {
            showToast(e.response.data.message, true);
        } finally {
            setLoader(false);
        }
    };

    // ---------- Update distribution schedule ----------- //
    const updateDistributionSchedule = async (token, id, data) => {
        setLoader(true);
        try {
            const res = await DistributionService.updateDistributionSchedule(
                token,
                id,
                data
            );
            if (res) {
                setLoader(false);
                showToast(res.data.message);
                return res.status;
            }
        } catch (e) {
            showToast(e.response.data.message, true);
        } finally {
            setLoader(false);
        }
    };

    // ---------- Delete distribution ----------- //
    const deleteDistributionSchedule = async (token, id) => {
        setLoader(true);
        try {
            const res = await DistributionService.deleteDistributionSchedule(
                token,
                id
            );
            if (res) {
                setLoader(false);
                showToast(res.data.message);
                return res.status;
            }
        } catch (e) {
        } finally {
            setLoader(false);
        }
    };

    // ------- Read Distribution -------- //
    const handleDistributionRead = useCallback(
        async (token, page, limit, queryParams) => {
            try {
                setLoader(true);
                const res = await DistributionService.distributionRead(
                    token,
                    page,
                    limit,
                    queryParams
                );
                if (res) {
                    setDistributionRead(res.data.distributions);
                    setDistributionReadTotal(res.data.totalPages);
                }
            } catch (e) {
                if (e.response.status === 404) {
                    setDistributionRead([]);
                }
            } finally {
                setLoader(false);
            }
        },
        [setLoader, setDistributionRead, setDistributionReadTotal]
    );

    // --------  Read Distribution With NID -------- //
    const handleDistributionWithNid = useCallback(
        async (token, page, limit, queryParams) => {
            try {
                setLoader(true);
                const res = await DistributionService.distributionReadWithNid(
                    token,
                    page,
                    limit,
                    queryParams
                );

                if (res) {
                    setDistributionReadWithNid(res.data.distributions);
                    setDistributionReadWithNidTotal(res.data.totalPages);
                }
            } catch (e) {
                if (e.response.status === 404) {
                    setDistributionReadWithNid([]);
                    setDistributionReadWithNidTotal(null);
                }
            } finally {
                setLoader(false);
            }
        },
        [setLoader, setDistributionReadWithNid, setDistributionReadWithNidTotal]
    );

    const value = {
        getAllDistributionList,
        getAllDistributionListTotal,
        setAllDistributionList,
        setAllDistributionListTotal,
        handleGetAllDistributionList,
        getSingleDistribution,
        distributionParams,
        setDistributionParams,
        getDealerList,
        dealerList,
        createDistributionSchedule,
        singleDistribution,
        updateDistributionSchedule,
        deleteDistributionSchedule,
        distributionRead,
        setDistributionRead,
        distributionReadWithNid,
        setDistributionReadWithNid,
        distributionReadTotal,
        distributionReadWithNidTotal,
        setDistributionReadTotal,
        distributionReadParams,
        setDistributionReadParams,
        distributionReadWithNidParams,
        setDistributionReadWithNidParams,
        handleDistributionRead,
        handleDistributionWithNid,
    };
    return (
        <DistributionContext.Provider value={value}>
            {children}
        </DistributionContext.Provider>
    );
};

export default withToast(DistributionProvider);
