import { Bars3Icon } from "@heroicons/react/20/solid";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useUserContext } from "../../context/UserProvider";
import { generatePageTitle, getImage } from "../../utils";
import Icons from "../../assets/Icons";

const Primary = ({ setOpen, open }) => {
    const { user } = useUserContext();
    const { getUserInfo, userUpdateInfo } = useUserContext();
    const [image, setImage] = useState(null);
    const location = useLocation();

    React.useEffect(() => {
        if (user) {
            getUserInfo(user?.token, user?.user._id);
        }
    }, [getUserInfo, user]);

    const handleGetImage = useCallback(async () => {
        if (userUpdateInfo?.image) {
            const res = await getImage(user?.token, userUpdateInfo.image);
            setImage(res);
        }
    }, [user?.token, userUpdateInfo.image, setImage]);

    useEffect(() => {
        handleGetImage();
    }, [handleGetImage]);

    // const getLocation = () =>
    //     useCallback(() => {
    //         console.log(location.pathname);
    //         return location?.pathname;
    //     }, [location?.pathname]);

    // console.log(getLocation());
    return (
        <div
            className={`fixed top-0 left-0 w-full py-5 pl-6 ${
                location.pathname === "/dealer-info"
                    ? "lg:pl-[20px]"
                    : "lg:pl-[304px]"
            } lg:pl-[304px] pr-6 bg-blue-50 flex items-center justify-between z-30`}
        >
            <div className="flex items-start gap-x-4">
                {location.pathname === "/dealer-info" ? (
                    <div className="py-5 px-6 bg-blue-950 flex items-center gap-x-4">
                        <img
                            src={Icons.bd_gov_logo}
                            alt="bd_gov_logo"
                            className="w-12 h-12"
                        />
                        <h1 className="text-lg font-bold text-white">
                            {`সুপার এডমিন প্যানেল`}
                        </h1>
                    </div>
                ) : (
                    <>
                        {" "}
                        <button
                            className="block lg:hidden"
                            onClick={() => setOpen(!open)}
                        >
                            <Bars3Icon className="w-6 h-6 text-green-600" />
                        </button>
                        <h1 className="text-xl hidden sm:block font-bold text-blue-950">
                            {generatePageTitle(location.pathname)}
                        </h1>
                    </>
                )}
                {/* <div className="py-5 px-6 bg-blue-950 flex items-center gap-x-4">
                    <img
                        src={Icons.bd_gov_logo}
                        alt="bd_gov_logo"
                        className="w-12 h-12"
                    />
                    <h1 className="text-lg font-bold text-white">
                        {`super এডমিন প্যানেল`}
                    </h1>
                </div> */}
            </div>
            <div className="flex items-center gap-x-4">
                {/* <div className="w-10 h-10 rounded-full bg-red-600" /> */}
                {!image || "" ? null : (
                    <img
                        src={`${image}`}
                        alt=""
                        className="w-10 h-10 rounded-full"
                    />
                )}

                <div>
                    <h3 className="text-base text-blue-950">
                        {userUpdateInfo ? userUpdateInfo.name : "--"}
                    </h3>
                    <h3 className="text-sm text-blue-950 leading-[22px]">
                        {userUpdateInfo ? userUpdateInfo.email : "--"}
                    </h3>
                </div>
            </div>
        </div>
    );
};

export default React.memo(Primary);
