import React from "react";
import Icons from "../../assets/Icons";
import { useForm } from "react-hook-form";

const SearchField = ({ placeholder, setQueryParams }) => {
    const { register, handleSubmit } = useForm();

    const onSubmit = (data) => {
        setQueryParams((prev) => ({
            ...prev,
            search: data.search,
            page: 1,
            limit: 10,
        }));
    };
    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex items-center gap-x-2 py-4 px-3.5 border border-[#919EAB] border-opacity-[32%] rounded-lg flex-1"
        >
            <img src={Icons.search} alt="search" className="" />
            <input
                type="text"
                placeholder={placeholder}
                {...register("search")}
                className="border-none outline-none text-neutral-800 text-base leading-6 w-full"
                // onChange={(e) => handleChange(e.target.value)}
            />
        </form>
    );
};

export default SearchField;
