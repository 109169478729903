import React from "react";
import Layouts from "../../layouts";

import { Outlet } from "react-router-dom";

const DistributionManagement = () => {
    return (
        <Layouts.Secondary>
            <div className="pt-[88px] lg:pl-[280px]">
                <Outlet />
            </div>
        </Layouts.Secondary>
    );
};

export default DistributionManagement;
