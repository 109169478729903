import React from "react";
import Buttons from "../../components/Buttons";
import { useUserContext } from "../../context/UserProvider";
import { useCardContext } from "../../context/CardProvider";
import { useNavigate } from "react-router-dom";

const RequestCard = ({ id }) => {
    const { user } = useUserContext();
    const navigate = useNavigate();
    const {
        getBeneficiaries,
        requestParams,
        patchBeneficiaryApprove,
        patchBeneficiaryReject,
    } = useCardContext();

    const handleApprove = async (id) => {
        const res = await patchBeneficiaryApprove(user.token, id);
        if (res === 200) {
            getBeneficiaries(
                user.token,
                requestParams.page,
                requestParams.limit,
                requestParams
            );
            navigate(-1);
        }
    };

    const handleReject = async (id) => {
        const res = await patchBeneficiaryReject(user.token, id);
        if (res === 200) {
            getBeneficiaries(
                user.token,
                requestParams.page,
                requestParams.limit,
                requestParams
            );
            navigate(-1);
        }
    };

    return (
        <div className="flex flex-col md:flex-row items-center gap-4">
            <Buttons.Outline
                label="বাতিল"
                paddingX="px-2"
                paddingY="py-2"
                width="w-[110px]"
                fontWeight="font-bold"
                onClick={() => handleReject(id)}
            />
            <Buttons.Primary
                label="অনুমোদন"
                paddingX="px-2"
                paddingY="py-2"
                width="w-[110px]"
                fontWeight="font-bold"
                onClick={() => handleApprove(id)}
            />
        </div>
    );
};

export default RequestCard;
