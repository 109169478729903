import React, { useState } from "react";
import Icons from "../../assets/Icons";
import Buttons from "../Buttons";

const BeneficiaryExportModal = ({
    handleSubmit,
    setOpen,
    setSelectedType,
    selectedType,
}) => {
    const [loading] = useState(false);

    const handleExport = () => {
        handleSubmit();
    };

    return (
        <div className="w-[402px] rounded overflow-hidden bg-white">
            <div className="p-4 bg-blue-950 flex items-center justify-between">
                <p className="text-slate-50 text-base leading-6">
                    উপকারভোগীর ডাটা এক্সপোর্ট
                </p>
                <button onClick={() => setOpen(false)}>
                    <img
                        src={Icons.cross_white}
                        alt="cross_white"
                        className="w-5 h-5"
                    />
                </button>
            </div>

            <div className="flex flex-col gap-y-5 p-4">
                <div className="space-y-[11px]">
                    <h1 className="text-blue-950 text-sm font-semibold">
                        সিলেক্ট উপকারভোগী
                    </h1>
                    {beneficiarySelection.map((item, idx) => (
                        <div key={idx} className="flex items-center gap-x-1">
                            <input
                                type="radio"
                                s
                                id={idx}
                                name="beneficiary-options"
                                checked={selectedType === item.slug}
                                onChange={(event) =>
                                    setSelectedType(event.target.value)
                                }
                                value={item.slug}
                                className="border-slate-800 rounded-full w-3 h-3 transition duration-300 ease-in-out checked:accent-green-600 checked:outline-green-700"
                            />
                            <p className="text-slate-800 text-sm  pt-1">
                                {item.title}
                            </p>
                        </div>
                    ))}
                </div>
            </div>

            <div className="p-4 flex justify-end items-center gap-x-4">
                <Buttons.Outline
                    label={"বাতিল"}
                    onClick={() => setOpen(false)}
                    paddingX="px-6"
                    paddingY="py-2"
                    fontWeight="font-bold"
                    textColor={"text-slate-600"}
                    borderColor={"border-slate-400"}
                />
                <Buttons.Primary
                    label={loading ? "এক্সপোর্টিং..." : "এক্সপোর্ট"}
                    isDisabled={loading}
                    onClick={handleExport}
                    paddingX="px-6"
                    paddingY="py-2"
                    fontWeight="font-bold"
                />
            </div>
        </div>
    );
};

export default BeneficiaryExportModal;

const beneficiarySelection = [
    { title: "বর্তমানে সিলেক্টেড উপকারভোগী লিস্ট", slug: "selected" },
    { title: "সকল উপকারভোগী", slug: "all" },
];
