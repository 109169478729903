import React from "react";

const SecondaryTableHeader = ({ title }) => {
    return (
        <div className="bg-slate-100 py-3 px-6 w-full">
            <div className="flex justify-between items-center">
                <h1 className="text-slate-800 text-sm font-semibold my-2.5">
                    {title}
                </h1>
            </div>
        </div>
    );
};

export default SecondaryTableHeader;
