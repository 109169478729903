import React, { useEffect, useState } from "react";
import Icons from "../../assets/Icons";
import Buttons from "../Buttons";
import { useUserContext } from "../../context/UserProvider";
import { hasPermission } from "../../utils";
import { Permission } from "../../helpers/permissionStrings";
import { translateBNToEN } from "../../utils";
import FormFields from "../FormFields";
import { useBeneficiaryContext } from "../../context/BeneficiaryProvider";

const UnionUpazillaModal = ({
    handleSubmit,
    setOpen,
    setSelectedItems,
    selectedItems,
}) => {
    const [loading] = useState(false);
    const { user } = useUserContext();
    const [openOnce, setOpenOnce] = useState(false);
    const { villages, getVillageData } = useBeneficiaryContext();

    const handleExport = () => {
        // console.log(selectedItems, "total selected item");

        // if (
        //     (selectedItems.area === "union" && selectedItems.union === "") ||
        //     selectedItems.fields.length === 0
        // )
        // {
        //     setOpenOnce(true);
        // }
        if (selectedItems.area === "union" && selectedItems.union === "") {
            setOpenOnce(true);
        } else {
            handleSubmit(selectedItems);
        }
    };

    const handleSelectClick = (value) => {
        if (value === "") {
            setSelectedItems((prev) => ({ ...prev, union: null }));
        } else {
            setSelectedItems((prev) => ({ ...prev, union: value }));
        }
        setSelectedItems((prev) => ({ ...prev, union: value }));
    };

    // const handleDataColumn = (value) => {
    //     const newValue = translateBNToEN(value);
    //     let newArray = selectedItems.fields;
    //     if (newArray.includes(newValue)) {
    //         newArray = newArray.filter((item) => item !== newValue);
    //     } else {
    //         newArray.push(newValue);
    //     }
    //     setSelectedItems((prev) => ({
    //         ...prev,
    //         fields: newArray,
    //     }));
    // };

    useEffect(() => {
        getVillageData(user.token);
    }, [getVillageData, user.token]);

    return (
        <div className="w-[402px] rounded overflow-hidden bg-white">
            <div className="p-4 bg-blue-950 flex items-center justify-between">
                <p className="text-slate-50 text-base leading-6">
                    উপকারভোগীর ডাটা এক্সপোর্ট
                </p>
                <button onClick={() => setOpen(false)}>
                    <img
                        src={Icons.cross_white}
                        alt="cross_white"
                        className="w-5 h-5"
                    />
                </button>
            </div>

            <div className="flex flex-col gap-y-5 p-4">
                {/* <div className="space-y-[11px]">
                        <h1 className="text-blue-950 text-sm font-semibold">
                            সিলেক্ট উপকারভোগী
                        </h1>
                        {beneficiarySelection.map((item, idx) => (
                            <div
                                key={idx}
                                className="flex items-center gap-x-1"
                            >
                                <input
                                    type="radio"
                                    id={idx}
                                    name="beneficiary-options"
                                    className="border-slate-800 rounded-full w-3 h-3 transition duration-300 ease-in-out checked:accent-green-600 checked:outline-green-700"
                                />
                                <p className="text-slate-800 text-sm  pt-1">
                                    {item}
                                </p>
                            </div>
                        ))}
                    </div> */}

                <>
                    {hasPermission(user?.permissions, [
                        Permission.READ_AREA_WISE_REPORT,
                    ]) && (
                        <>
                            <div className="space-y-[11px]">
                                <h1 className="text-blue-950 text-sm font-semibold">
                                    এলাকা সিলেক্ট করুন
                                </h1>
                                {areaSelection.map((item, idx) => (
                                    <div
                                        key={idx}
                                        className="flex items-center gap-x-1"
                                    >
                                        <input
                                            type="radio"
                                            id={idx}
                                            value={item}
                                            checked={
                                                selectedItems.area ===
                                                translateBNToEN(item)
                                            }
                                            name="area-options"
                                            onChange={(event) =>
                                                setSelectedItems((prev) => ({
                                                    ...prev,
                                                    area: translateBNToEN(
                                                        event.target.value
                                                    ),
                                                }))
                                            }
                                            className="border-slate-800 rounded-full w-3 h-3 transition duration-300 ease-in-out checked:accent-green-600 checked:outline-green-700"
                                        />
                                        <p className="text-slate-800 text-sm  pt-1">
                                            {item}
                                        </p>
                                    </div>
                                ))}
                            </div>
                            {selectedItems.area === "union" && (
                                <FormFields.ModalSelectField
                                    data={villages ? villages : []}
                                    label={"ঠিকানা"}
                                    placeholder="ইউনিয়ন সিলেক্ট করুন"
                                    openOnce={openOnce}
                                    handleSelectClick={handleSelectClick}
                                    // setQueryParams={setQueryParams}
                                    name="union"
                                    border="border border-[#CBD5E1]"
                                    radius="rounded-[2px]"
                                    paddingX="px-2"
                                    paddingY="py-2.5"
                                    textSize="text-sm"
                                    setSelectedItems={setSelectedItems}
                                />
                            )}
                        </>
                    )}

                    {/* <div className="space-y-[11px]">
                        <h1 className="text-blue-950 text-sm font-semibold">
                            ডাটা কলাম সিলেক্ট করুন
                        </h1>
                        <div className="grid grid-cols-2 gap-y-3 gap-x-4">
                            {dataColoumnSelection.map((item, idx) => (
                                <div
                                    key={idx}
                                    className="flex gap-x-2 col-span-1"
                                >
                                    <input
                                        value={item}
                                        type="checkbox"
                                        className="h-4 w-4 cursor-pointer rounded border-2 border-slate-800 text-blue-600 focus:ring-blue-500 disabled:cursor-not-allowed disabled:opacity-30 checked:accent-green-600"
                                        checked={
                                            selectedItems.fields.includes(
                                                translateBNToEN(item)
                                            )
                                                ? true
                                                : false
                                        }
                                        onChange={() => handleDataColumn(item)}
                                    />
                                    <p className="text-slate-800 text-sm">
                                        {item}
                                    </p>
                                </div>
                            ))}
                        </div>
                        <span
                            className={`text-xs flex items-center mt-3 text-red-600 ${
                                openOnce && selectedItems.fields.length === 0
                                    ? "block"
                                    : "hidden"
                            }`}
                        >
                            <ExclamationCircleIcon className="w-4 h-4 mr-1.5" />{" "}
                            <span className="mt-0.5">
                                ডাটা কলাম সিলেক্ট আবশ্যক
                            </span>
                        </span>
                    </div> */}

                    <div className="space-y-[11px]">
                        <h1 className="text-blue-950 text-sm font-semibold">
                            সিলেক্ট ডেট
                        </h1>
                        {dateSelection.map((item, idx) => (
                            <div
                                key={idx}
                                className="flex items-center gap-x-1"
                            >
                                <input
                                    type="radio"
                                    id={idx}
                                    name="date-options"
                                    value={item}
                                    checked={
                                        selectedItems.range ===
                                        translateBNToEN(item)
                                    }
                                    onChange={(event) =>
                                        setSelectedItems((prev) => ({
                                            ...prev,
                                            range: translateBNToEN(
                                                event.target.value
                                            ),
                                        }))
                                    }
                                    className="border-slate-800 rounded-full w-3 h-3 transition duration-300 ease-in-out checked:accent-green-600 checked:outline-green-700"
                                />
                                <p className="text-slate-800 text-sm  pt-1">
                                    {item}
                                </p>
                            </div>
                        ))}
                    </div>
                </>

                {/* <div className="space-y-[11px]">
                    <h1 className="text-blue-950 text-sm font-semibold">
                        ডকুমেন্ট ফরমেট
                    </h1>
                    {formatSelection.map((item, idx) => (
                        <div key={idx} className="flex items-center gap-x-1">
                            <input
                                type="radio"
                                id={idx}
                                name="format-options"
                                value={item}
                                checked={
                                    selectedItems.format ===
                                    translateBNToEN(item)
                                }
                                onChange={(event) =>
                                    setSelectedItems((prev) => ({
                                        ...prev,
                                        format: translateBNToEN(
                                            event.target.value
                                        ),
                                    }))
                                }
                                className="border-slate-800 rounded-full w-3 h-3 transition duration-300 ease-in-out checked:accent-green-600 checked:outline-green-700"
                            />
                            <p className="text-slate-800 text-sm  pt-1">
                                {item}
                            </p>
                        </div>
                    ))}
                </div> */}
            </div>

            <div className="p-4 flex justify-end items-center gap-x-4">
                <Buttons.Outline
                    label={"বাতিল"}
                    onClick={() => setOpen(false)}
                    paddingX="px-6"
                    paddingY="py-2"
                    fontWeight="font-bold"
                    textColor={"text-slate-600"}
                    borderColor={"border-slate-400"}
                />
                <Buttons.Primary
                    label={loading ? "এক্সপোর্টিং..." : "এক্সপোর্ট"}
                    isDisabled={loading}
                    onClick={handleExport}
                    paddingX="px-6"
                    paddingY="py-2"
                    fontWeight="font-bold"
                />
            </div>
        </div>
    );
};

export default UnionUpazillaModal;

// const beneficiarySelection = [
//     "বর্তমানে সিলেক্টেড উপকারভোগী লিস্ট",
//     "সকল উপকারভোগী",
// ];

const dateSelection = ["দৈনিক", "সাপ্তাহিক", "মাসিক"];

// const formatSelection = ["এক্সেল", "PDF"];

// const dataColoumnSelection = [
//     "সকল কলাম",
//     "উপকারভোগীর নাম",
//     "পরিচয় পত্র নং",
//     "ঠিকানা",
//     "মোবাইল",
//     "অনুমোদন স্ট্যাটাস",
// ];

const areaSelection = ["উপজেলা", "ইউনিয়ন", "সকল"];
