import React, { createContext, useCallback, useContext, useState } from "react";
import { withToast } from "../hoc/withToast";
import DashboardService from "../services/DashboardService";
import { useUserContext } from "./UserProvider";

const DashboardContext = createContext({});
export const useDashboardContext = () => {
    return useContext(DashboardContext);
};

const DashboardProvider = ({ showToast, children }) => {
    const { setLoader } = useUserContext();
    const [dashboardData, setDashboardData] = useState(null);

    //------------ Distribution table data ------------//

    const getDashboardData = useCallback(
        async (token) => {
            try {
                setLoader(true);
                const res = await DashboardService.getDashboardData(token);
                setDashboardData(res.data);
                setLoader(false);
            } catch (e) {
                setLoader(false);
                showToast(e.response.data.message, true);
            }
        },
        [setLoader, showToast]
    );

    const value = {
        getDashboardData,
        dashboardData,
    };
    return (
        <DashboardContext.Provider value={value}>
            {children}
        </DashboardContext.Provider>
    );
};

export default withToast(DashboardProvider);
