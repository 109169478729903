import DistributionManagement from "../../pages/distribution";
import { beneficiaryManagement } from "./beneficiaryManagement";
// import { beneficiaryManagementUnion } from "./beneficiaryManagementUnion";
import { cardManagement } from "./cardManagement";
import { distributionManagement } from "./distributionMangement";
import { profileSettingsManagement } from "./profileSettingsManagement";
import { reportManagement } from "./reportManagement";
import { userManagement } from "./userManagement";

const sidebarMenu = [
    // ...dashboard,
    ...beneficiaryManagement,
    ...cardManagement,
    ...reportManagement,
    ...distributionManagement,
    ...userManagement,
    ...profileSettingsManagement,
    // ...beneficiaryManagementUnion,
];

export default sidebarMenu;
