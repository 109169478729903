import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import FormFields from "../../components/FormFields";
import Buttons from "../../components/Buttons";
import ImageUploader from "../../components/Common/ImageUploader";
import { useCardContext } from "../../context/CardProvider";
import { roleNameDropDown } from "../../libs/common";
import { removeEmptyProperties, translateBNToEN } from "../../utils";
import { Box } from "../BeneficiaryForm.jsx";
// import { useUserContext } from "../../context/UserProvider";

const UserForm = ({
    singleUser,
    create,
    handleSubmitData,
    villages,
    selectedImage,
    setSelectedImage,
    handleImageEmpty,
}) => {
    const navigate = useNavigate();
    // const { user } = useUserContext();
    const [getEdit] = useState(create ? false : true);
    const [union, setUnion] = useState(null);
    const [roleName, setRoleName] = useState(
        singleUser?.roleName ? singleUser?.roleName : null
    );
    const [openOnce, setOpenOnce] = useState(false);

    const { setSingleBeneficiaries } = useCardContext();

    // console.log("user---------------------", user.user.role);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({});

    const onSubmit = (data) => {
        if (create) {
            if (roleName) {
                data.role = roleName;
                if (union) {
                    data.union = union;
                }
                const newData = removeEmptyProperties(data);
                handleSubmitData(removeEmptyProperties(newData));
            } else {
                setOpenOnce(true);
            }
        } else {
            data.role = roleName;
            const newData = removeEmptyProperties(data);
            if (roleName === "Super Admin") {
                data.union = "";
            } else if (union) {
                data.union = union;
            }
            handleSubmitData(newData);
        }
    };

    const handleBackNav = () => {
        setSingleBeneficiaries({});
        navigate(-1);
    };

    const handleUnion = (value) => {
        setUnion(value);
    };

    const handleRoleName = (value) => {
        setRoleName(translateBNToEN(value));
        // console.log("------------>", value);
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="mt-9 mb-4 flex flex-col gap-8 bg-white"
        >
            {/* <div className=""> */}
            <ImageUploader
                defaultImage={singleUser?.image ? singleUser?.image : ""}
                create={create}
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                handleImageEmpty={handleImageEmpty}
            />
            <div className="flex flex-wrap items-start justify-start md:gap-x-5 lg:gap-x-8 w-full flex-col sm:flex-col md:flex-row px-5 sm:px-5 md:px-5 lg:px-16">
                <Box className="col-span-1">
                    <h6 className="text-slate-800 font-medium text-base mb-2">
                        ব্যাবহারকারীর নাম
                    </h6>
                    <FormFields.Text
                        register={register}
                        defaultValue={create ? "" : singleUser?.name}
                        errors={errors}
                        errorMsg="ব্যাবহারকারীর নাম প্রদান করুন "
                        name="name"
                        width="w-full"
                        isReadOnly={
                            create ? !create : getEdit ? !getEdit : true
                        }
                        // validations={{ required: true, one: "asdf" }}
                    />
                </Box>
                <Box className="col-span-1">
                    <h6 className="text-slate-800 font-medium text-base mb-2 ">
                        ব্যাবহারকারীর রোল
                    </h6>
                    <FormFields.SelectField
                        data={roleNameDropDown}
                        placeholder="রোল নাম সিলেক্ট করুন "
                        handleSelectClick={handleRoleName}
                        register={register}
                        defaultValue={create ? "" : singleUser?.role}
                        errorMsg="রোল নাম সিলেক্ট বাধ্যতামূলক"
                        name="role"
                        width="w-full"
                        paddingY="py-3.5"
                        paddingX="px-5"
                        border="border border-slate-300"
                        radius="rounded"
                        openOnce={openOnce}
                        isReadOnly={
                            create ? !create : getEdit ? !getEdit : true
                        }
                        dropdownPosition="top-[56px]"
                    />
                </Box>
                {((roleName && roleName === "Union Admin") ||
                    (roleName && roleName === "Dealer") ||
                    singleUser?.union) && (
                    <Box className="col-span-1">
                        <h6 className="text-slate-800 font-medium text-base mb-2">
                            ইউনিয়ন সিলেক্ট করুন
                        </h6>
                        <FormFields.SelectField
                            data={villages ? villages : []}
                            placeholder="ইউনিয়ন সিলেক্ট করুন"
                            defaultValue={create ? "" : singleUser?.union}
                            handleSelectClick={handleUnion}
                            errors={errors}
                            errorMsg="ইউনিয়ন সিলেক্ট বাধ্যতামূলক"
                            dropdownPosition="top-[56px]"
                            name="union"
                            width="w-full"
                            paddingY="py-3.5"
                            paddingX="px-5"
                            border="border border-slate-300"
                            radius="rounded"
                            openOnce={openOnce}
                            isReadOnly={
                                create ? !create : getEdit ? !getEdit : true
                            }
                        />
                    </Box>
                )}
                <Box className="col-span-1">
                    <h6
                        className={`text-slate-800 font-medium text-base mb-2 ${
                            (roleName && roleName === "Union Admin") ||
                            (roleName && roleName === "Dealer") ||
                            singleUser?.union
                                ? "mt-6"
                                : ""
                        }`}
                    >
                        মোবাইল
                    </h6>
                    <FormFields.Text
                        register={register}
                        defaultValue={create ? "" : singleUser?.mobile}
                        errors={errors}
                        validations={{
                            pattern: /^(?:(?:\+|00)88|01)?\d{11}$/,
                            required: create ? true : false,
                        }}
                        errorMsg="সঠিক মোবাইল নাম্বার প্রদান করুন"
                        name="mobile"
                        width="w-full"
                        isReadOnly={
                            create ? !create : getEdit ? !getEdit : true
                        }
                    />
                </Box>
                {create && (
                    <>
                        <Box className="col-span-1">
                            <h6 className="text-slate-800 font-medium text-base mb-2 mt-6">
                                নতুন পাসওয়ার্ড
                            </h6>
                            <FormFields.Password
                                register={register}
                                defaultValue={create ? "" : singleUser?.mobile}
                                errors={errors}
                                name="password"
                                width="w-full"
                                isReadOnly={
                                    create ? !create : getEdit ? !getEdit : true
                                }
                            />
                        </Box>
                        <Box className="col-span-1">
                            <h6 className="text-slate-800 font-medium text-base mb-2 mt-6">
                                পুনরায় পাসওয়ার্ড
                            </h6>
                            <FormFields.Password
                                register={register}
                                defaultValue={create ? "" : singleUser?.mobile}
                                errors={errors}
                                name="confirmPassword"
                                width="w-full"
                                isReadOnly={
                                    create ? !create : getEdit ? !getEdit : true
                                }
                            />
                        </Box>
                    </>
                )}
            </div>
            <div className="py-7 px-9 flex justify-end shadow-medium-gray gap-3">
                <Buttons.Outline
                    borderColor="border-slate-400"
                    label="ফেরত যান"
                    textColor="text-slate-600"
                    fontWeight="font-bold"
                    textSize="text-sm"
                    paddingY="py-2.5"
                    // paddingX="px-7"
                    width="w-[120px]"
                    onClick={handleBackNav}
                />
                {create ? (
                    <Buttons.Primary
                        label="তৈরি করুন"
                        paddingX="px-3"
                        paddingY="py-2.5"
                        width="w-[120px]"
                        bgColor="bg-green-600"
                        radius="rounded-[2px]"
                        textColor="text-white"
                        textSize={"font-bold"}
                        type="submit"
                    />
                ) : (
                    <Buttons.Primary
                        label="আপডেট করুন"
                        // paddingX="px-3"
                        paddingY="py-2.5"
                        width="w-[120px]"
                        bgColor="bg-green-600"
                        radius="rounded-[2px]"
                        textColor="text-white"
                        textSize={"font-bold"}
                        type="submit"
                        // onClick={() => {
                        //     handleImageUpload();
                        //     handleSubmitData();
                        // }}
                    />
                )}
            </div>
            {/* </div> */}
        </form>
    );
};

export default UserForm;

// const Box = ({ children, className }) => {
//     return <div className={`flex flex-col justify-start col-span-1 gap-2 ${className}`}>{children}</div>;
// };
