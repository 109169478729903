import Overlay from "../components/Common/Overlay";
import React from "react";
import Icons from "../assets/Icons";

const Tooltip = ({
    overlayVariant,
    bottom,
    left,
    overlayZIndex,
    popupZIndex,
    right,
    top,
    children,
    frameWidth,
    open,
    setOpen,
    isIndicator = true,
    align,
}) => {
    return (
        <>
            <Overlay
                variant={overlayVariant}
                zIndex={overlayZIndex}
                setOpen={setOpen}
            />
            <div
                className={`absolute animate-fadeIn ${frameWidth} ${
                    top && top
                } shadow-light-gray ${bottom && bottom} ${left && left} ${
                    right && right
                } ${popupZIndex ? popupZIndex : "z-20"} shadow-lg`}
            >
                {children}
                {isIndicator && (
                    <img
                        src={Icons.white_polygon}
                        alt="triangle"
                        className={`w-[55px] h-[27px] absolute ${
                            align === "top" ? "top-3.5" : "bottom-3.5"
                        } -right-8`}
                    />
                )}
            </div>
        </>
    );
};

export default Tooltip;
