import { Permission } from "../helpers/permissionStrings";

export const sidebarPermissions = {
    cardRoot: [Permission.READ_CARD],
    cardRequest: [Permission.APPROVE_CARD],
    cardSendToUpazila: [Permission.SEND_GENERATE_REQUEST_CARD],
    cardApprove: [Permission.READ_CARD, Permission.REJECT_CARD],
    cardRejected: [Permission.READ_CARD, Permission.APPROVE_CARD],
    cardGenerate: [Permission.READ_CARD, Permission.CREATE_CARD],
    cardGenerated: [Permission.READ_CARD],
    allBeneficiaries: [Permission.READ_BENEFICIARY],
    createBeneficiary: [Permission.CREATE_BENEFICIARY],
    beneficiariesApprove: [Permission.APPROVE_BENEFICIARY],
    beneficiariesApproved: [
        Permission.READ_BENEFICIARY,
        Permission.REJECT_BENEFICIARY,
    ],
    beneficiariesRejected: [
        Permission.READ_BENEFICIARY,
        Permission.APPROVE_BENEFICIARY,
    ],
    beneficiariesUnion: [
        Permission.READ_BENEFICIARY,
        Permission.SEND_TO_UPAZILLA_BENEFICIARY,
    ],
    reportGenerate: [Permission.READ_REPORT],
    userManagementList: [Permission.READ_CARD],
    newUserCreate: [Permission.CREATE_USER, Permission.READ_USER],
    editUser: [Permission.READ_USER, Permission.UPDATE_USER],
    userPasswordChange: [Permission.UPDATE_USER],
    updateProfile: [Permission.UPDATE_PROFILE, Permission.READ_PROFILE],
    distributionRead: [Permission.READ_DISTRIBUTION_SCHEDULE],
    distributionCreate: [
        Permission.READ_DISTRIBUTION_SCHEDULE,
        Permission.CREATE_DISTRIBUTION_SCHEDULE,
    ],
    distributionUpdate: [
        Permission.READ_DISTRIBUTION_SCHEDULE,
        Permission.UPDATE_DISTRIBUTION_SCHEDULE,
    ],
    profile: [Permission.READ_PROFILE],
};
