import React from "react";
import SecondaryTableHeader from "../../components/Common/SecondaryTableHeader";
import { useUserContext } from "../../context/UserProvider";
import PasswordResetForm from "../../sections/UserManagement/passwordResetForm";
import { useParams, useNavigate } from "react-router";
import { withToast } from "../../hoc/withToast";

const UserResetPassword = () => {
    const { id } = useParams();
    const { user, resetUserPassword } = useUserContext();
    const navigate = useNavigate();

    // console.log(id);

    const handlePasswordUpdate = (data) => {
        // console.log("I am here", user.token, data, id);
        resetUserPassword(user.token, data, id);
        navigate(-1);
    };

    return (
        <div className="flex flex-col items-center px-10 py-9">
            <div className="w-[1080px] rounded-lg overflow-hidden">
                <SecondaryTableHeader title="ব্যাবহারকারীর পাসওয়ার্ড পরিবর্তন" />
                <PasswordResetForm
                    handlePasswordUpdate={handlePasswordUpdate}
                />
            </div>
        </div>
    );
};

export default withToast(UserResetPassword);
