const SERVER_URL = process.env.REACT_APP_API;

const API_URL = SERVER_URL;

export const LOGIN_USER_URL = `${API_URL}/users/login`;
export const CREATE_BENEFICIARY_PATH = `${API_URL}/beneficiaries`;
export const BENEFICIARIES_VILLAGE_URL = `${API_URL}/beneficiaries/distinct/union`;
export const BENEFICIARIES_URL = `${API_URL}/beneficiaries`;
export const BENEFICIARIES_UPLOAD_EXCEL_URL = `${API_URL}/beneficiaries/upload-excel`;
export const BENEFICIARIES_APPROVE_URL = `${API_URL}/beneficiaries/approve`;
export const BENEFICIARIES_APPROVED_URL = `${API_URL}/beneficiaries/approved`;
export const BENEFICIARIES_REJECTED_URL = `${API_URL}/beneficiaries/rejected`;
export const BENEFICIARIES_REJECT_URL = `${API_URL}/beneficiaries/reject`;
export const BENEFICIARIES_PENDING_URL = `${API_URL}/beneficiaries/pending`;
export const BENEFICIARIES_AWAITING_APPROVAL = `${API_URL}/beneficiaries/awaiting-approval`;
export const BENEFICIARIES_REQUEST_APPROVAL = `${API_URL}/beneficiaries/request-approval`;
export const BENEFICIARIES_SUCCESSFULLY_UPLOADED_EXCEL_FILES = `${API_URL}/files/uploaded`;
export const BENEFICIARIES_FAILED_UPLOADED_EXCEL_FILES = `${API_URL}/files/failed`;
export const BENEFICIARIES_EXPORT_URL = `${BENEFICIARIES_URL}/export`;

export const CARD_REQUEST_URL = `${API_URL}/cards/requests`;
export const CARD_REQUEST_APPROVE_URL = `${API_URL}/cards/requests/approve`;
export const CARD_REQUEST_REJECT_URL = `${API_URL}/cards/requests/reject`;
export const CARD_REQUEST_APPROVED_URL = `${API_URL}/cards/requests/approved`;
export const CARD_REQUEST_REJECTED_URL = `${API_URL}/cards/requests/rejected`;
export const CARD_GENERATE_URL = `${API_URL}/cards/generate`;
export const CARD_GENERATED_URL = `${API_URL}/cards/generated`;
export const CARD_GENERATE_REQUEST_URL = `${API_URL}/cards/request`;
export const AWAITING_CARD_GENERATE_REQUEST_URL = `${API_URL}/cards/awaiting-card-generate-request`;
export const CARD_DOWNLOAD_URL = `${API_URL}/cards/download`;
export const CARD_PREVIEW_URL = `${API_URL}/cards/url`;
export const REGENERATE_CARD = `${API_URL}/cards/regenerate`;

// Distributions
export const DISTRIBUTION_GET_URL = `${API_URL}/distributions/schedules`;
export const DISTRIBUTION_SCHEDULE_CREATE = `${API_URL}/distributions/schedules`;
export const DISTRIBUTION_READ = `${API_URL}/distributions`;
export const DISTRIBUTION_READ_WITH_NID = `${API_URL}/distributions/with-nid`;

// File upload endpoint
export const IMAGE_UPLODER_URL = `${API_URL}/files/images`;

export const REPORT_DOWNLOAD_URL = `${API_URL}/reports/download`;

//User Management
export const USER_MANAGEMENT_URL = `${API_URL}/users`;
export const USER_MANAGEMENT_CREATE_URL = `${API_URL}/users`;
export const USER_MANAGEMENT_UPDATE_URL = `${API_URL}/users`;
export const USER_MANAGEMENT_DELETE_URL = `${API_URL}/users`;
export const USER_MANAGEMENT_RESET_PASSWORD_URL = `${API_URL}/users/reset-password`;

export const IMAGE_ROOT = `${API_URL}/files/images`;

// USER PROFILE
export const USER_PROFILE_UPDATE = `${API_URL}/profile`;
export const USER_PASSWORD_UPDATE = `${API_URL}/profile/change-password`;
export const GET_IMAGE = `${API_URL}/files`;

//dashboard

export const DASHBOARD_STATISTICS_URL = `${API_URL}/dashboard/statistics`;

// Reports
export const GENERATED_REPORTS_LIST_URL = `${API_URL}/reports`;
//Report Generate Download
export const BENEFICIARIES_REPORT_DOWNLOAD_URL = `${API_URL}/reports/download/beneficiaries`;
export const APPROVED_BENEFICIARIES_REPORT_DOWNLOAD_URL = `${API_URL}/reports/download/approved-beneficiaries`;
export const GENERATED_CARD_REPORT_DOWNLOAD_URL = `${API_URL}/reports/download/generated-cards`;
export const DEALERS_REPORT_DOWNLOAD_URL = `${API_URL}/reports/download/dealers`;
export const DISTRIBUTION_REPORT_DOWNLOAD_URL = `${API_URL}/reports/download/distributions`;
export const DISTRIBUTION_SCHEDULE_REPORT_DOWNLOAD_URL = `${API_URL}/reports/download/distribution-schedule`;
