export const numbers = {
    "০": 0,
    "১": 1,
    "২": 2,
    "৩": 3,
    "৪": 4,
    "৫": 5,
    "৬": 6,
    "৭": 7,
    "৮": 8,
    "৯": 9,
};

export const approvedStatusDropdown = [
    "অনুমোদিত",
    "বাতিলকৃত",
    "পেন্ডিং",
    "কার্ড জেনারেট আবেদন",
    "কার্ড জেনারেট অনুমোদিত",
    "কার্ড জেনারেট বাতিল",
    "কার্ড জেনারেটেড",
];

export const roleNameDropDown = [
    "সুপার এডমিন",
    "ইউনিয়ন এডমিন",
    "উপজেলা এডমিন",
    "ডিলার",
];

export const roleNameDropDownDataEN = [
    {
        _id: 1,
        name_bn: "সুপার এডমিন",
        name_en: "Super Admin",
        color: "text-green-600 bg-green-100",
    },
    {
        _id: 2,
        name_bn: "ইউনিয়ন এডমিন",
        name_en: "Union Admin",
        color: "text-green-600 bg-green-100",
    },
    {
        _id: 3,
        name_bn: "উপজেলা এডমিন",
        name_en: "Upazila Admin",
        color: "text-green-600 bg-green-100",
    },
    {
        _id: 4,
        name_bn: "ডিলার",
        name_en: "Dealer",
        color: "text-green-600 bg-green-100",
    },
];

export const approvedStatusWithEN = [
    {
        _id: 1,
        name_bn: "অনুমোদিত",
        name_en: "APPROVED",
        color: "text-green-600 bg-green-100",
    },
    {
        _id: 2,
        name_bn: "বাতিলকৃত",
        name_en: "REJECTED",
        color: "text-red-600 bg-red-100",
    },
    {
        _id: 3,
        name_bn: "পেন্ডিং",
        name_en: "PENDING",
        color: "text-amber-600 bg-amber-100",
    },
    {
        _id: 4,
        name_bn: "কার্ড জেনারেট আবেদন",
        name_en: "CARD_GENERATE_REQUESTED",
        color: "text-orange-600 bg-orange-100",
    },
    {
        _id: 5,
        name_bn: "কার্ড জেনারেট অনুমোদিত",
        name_en: "CARD_GENERATE_APPROVED",
        color: "text-blue-600 bg-blue-100",
    },
    {
        _id: 6,
        name_bn: "কার্ড জেনারেট বাতিল",
        name_en: "CARD_GENERATE_REJECTED",
        color: "text-red-600 bg-red-100",
    },
    {
        _id: 7,
        name_bn: "কার্ড জেনারেটেড",
        name_en: "CARD_GENERATED",
        color: "text-green-600 bg-green-100",
    },
    {
        _id: 8,
        name_bn: "উপজেলায় পাঠানো হয়েছে",
        name_en: "SENT_TO_UPAZILLA",
        color: "text-green-600 bg-green-100",
    },
];

export const villageData = ["অনুমোদিত", "বাতিলকৃত"];

export const banglaToEnglish = {
    PDF: "PDF",
    এক্সেল: "xlsx",
    দৈনিক: "daily",
    সাপ্তাহিক: "weekly",
    মাসিক: "monthly",
    সকল: "all",
    "উপকারভোগীর নাম": "name",
    "পরিচয় পত্র নং": "nid",
    ঠিকানা: "union",
    মোবাইল: "mobile",
    "অনুমোদন স্ট্যাটাস": "status",
    ডিলার: "Dealer",
    "সুপার এডমিন": "Super Admin",
    "ইউনিয়ন এডমিন": "Union Admin",
    "উপজেলা এডমিন": "Upazila Admin",
    ড্যাশবোর্ড: "dashboard",
    "উপকারভোগী ম্যানেজমেন্ট": "beneficiaries",
    "কার্ড ম্যানেজমেন্ট": "cards",
    "ব্যাবহারকারী ম্যানেজমেন্ট": "users",
    "বিতরণ ম্যানেজমেন্ট": "distribution-schedule",
    "প্রোফাইল সেটিংস": "profile-settings",
    "রিপোর্ট ম্যানেজমেন্ট": "reports",
    ইউনিয়ন: "union",
    উপজেলা: "upazila",
};
