import React from "react";
import { useForm } from "react-hook-form";
import { Box } from "../../sections/BeneficiaryForm.jsx";
import FormFields from "../../components/FormFields/index.js";
import Buttons from "../../components/Buttons";
import { withToast } from "../../hoc/withToast";

const ProfileUpdateForm = ({
    singleUserInfo,
    handleUserUpdate,
    showToast,
    disableButton,
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({});

    const onSubmit = (data) => {
        if (data.name === "") {
            showToast("ব্যাবহারকারির নাম  আবশ্যক.", true);
        } else {
            handleUserUpdate(data);
        }
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="mt-9 mb-4 flex flex-col justify-end"
        >
            <div className="flex flex-col items-end justify-center gap-10">
                <div className="flex flex-col sm:flex-col md:flex-row justify-between lg:flex-row w-full">
                    <Box>
                        <h6 className="text-slate-800 font-medium text-base mb-2">
                            আপনার প্রোফাইল নাম
                        </h6>
                        <FormFields.Text
                            register={register}
                            defaultValue={
                                singleUserInfo ? singleUserInfo.name : ""
                            }
                            errors={errors}
                            name="name"
                            width="w-full"
                            // isReadOnly={create ? !create : getEdit ? !getEdit : true}
                            validations={{ required: false }}
                            // errorMsg={errors?.name}
                        />
                    </Box>
                    <Box>
                        <h6 className="text-slate-800 font-medium text-base mb-2">
                            আপনার মোবাইল নাম্বার
                        </h6>
                        <FormFields.Text
                            register={register}
                            defaultValue={
                                singleUserInfo ? singleUserInfo.mobile : ""
                            }
                            errors={errors}
                            name="mobile"
                            width="w-full"
                            // isReadOnly={create ? !create : getEdit ? !getEdit : true}
                            validations={{ required: false }}
                            // errorMsg={errors?.name}
                        />
                    </Box>
                    <Box>
                        <h6 className="text-slate-800 font-medium text-base mb-2">
                            আপনার ইমেইল এড্রেস
                        </h6>
                        <FormFields.Text
                            register={register}
                            defaultValue={
                                singleUserInfo ? singleUserInfo.email : ""
                            }
                            errors={errors}
                            name="email"
                            width="w-full"
                            // isReadOnly={create ? !create : getEdit ? !getEdit : true}
                            validations={{ required: false }}
                            // errorMsg={errors?.name}
                        />
                    </Box>
                </div>
                <Buttons.IconWithLabel
                    disabled={disableButton}
                    label="আপডেট করুন"
                    paddingX="px-3"
                    paddingY="py-2.5"
                    bgColor={disableButton ? "bg-green-400" : "bg-green-600"}
                    cursor={
                        disableButton ? "cursor-not-allowed" : "cursor-pointer"
                    }
                    radius="rounded-[2px]"
                    textColor="text-white"
                    textSize={"font-bold"}
                    type="submit"
                />
            </div>
        </form>
    );
};

export default withToast(ProfileUpdateForm);
