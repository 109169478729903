"use client";
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { USER_MANAGEMENT_URL } from "../helpers/APIURL";
import { withToast } from "../hoc/withToast";
import UserManagementService from "../services/UserManagementService";
import UserService from "../services/UserService";
import ProfileService from "../services/userProfileService";

const UserContext = createContext({});
export const useUserContext = () => {
    return useContext(UserContext);
};

const UserProvider = ({ showToast, children }) => {
    const [loader, setLoader] = useState(false);
    const [user, setUser] = useState(null);
    const [userList, setUserList] = useState([]);
    const [usersTotalPages, setUsersTotalPages] = useState(null);
    const [userParams, setUserParams] = useState({
        page: 1,
        limit: 10,
        startDate: null,
        role: null,
        union: null,
        search: null,
    });
    const [userUpdateInfo, setUserUpdateInfo] = useState({});

    useEffect(() => {
        const user = sessionStorage.getItem("user");
        if (user) {
            setUser(JSON.parse(user));
        }
        setUserParams({
            page: 1,
            limit: 10,
            startDate: null,
            role: null,
            union: null,
            search: null,
        });
    }, []);

    // useEffect(() => {
    //     getUserInfo(user?.token, user?.id);
    // }, [user]);

    // console.log(user);

    const LoginUser = async (user) => {
        try {
            setLoader(true);
            const res = await UserService.loginUser(user);
            setUser(res.data);
            sessionStorage.setItem("user", JSON.stringify(res.data));
            // return res.data;
            if (res.status) {
                setLoader(false);
                showToast(res.data.message);
                return res;
            }
        } catch (error) {
            showToast(error.response.data.message, true);
            setLoader(false);
        }
    };

    const logoutUser = () => {
        sessionStorage.removeItem("user");
        showToast("লগআউট সফল হয়েছে");
        return true;
    };

    //User Management

    const getUsers = React.useCallback(
        async (token, page, limit, queryParams) => {
            try {
                setLoader(true);
                const res = await UserManagementService.getUsers({
                    token,
                    page,
                    limit,
                    queryParams,
                    url: USER_MANAGEMENT_URL,
                });
                setUserList(res.data.users);
                setUsersTotalPages(res.data.totalPages);
                setLoader(false);
            } catch (error) {
                setLoader(false);
                if (error.response.status === 404) {
                    setUserList([]);
                } else return;
                showToast(error.response.data.message, true);
            }
        },
        [setLoader, showToast]
    );

    const getUsersById = useCallback(async (token, id) => {
        try {
            setLoader(true);
            const res = await UserManagementService.getUsersById(token, id);
            // setSingleBeneficiaries(res.data.beneficiary);
            if (res) {
                setLoader(false);
                return res.data.user;
            }
        } catch (error) {
            setLoader(false);
        }
    }, []);

    const createUser = async (token, data) => {
        try {
            setLoader(true);
            const res = await UserManagementService.createUser(token, data);
            if (res.status) {
                setLoader(false);
                showToast(res.data.message);
                return res.status;
            }
        } catch (error) {
            setLoader(false);
            showToast(error.response.data.message, true);
        }
    };

    //----- getUserProfileInfo ------//
    const getUserInfo = useCallback(
        async (token, id) => {
            try {
                setLoader(true);
                const res = await ProfileService.getUserProfileInfo(token, id);
                if (res.status) {
                    setLoader(false);
                    setUserUpdateInfo(res.data.user);
                    // showToast(res.data.message);
                    return res.status;
                }
            } catch (error) {
                // console.log(error);
                setLoader(false);
                showToast(error.response.data.message, true);
            }
        },
        [setLoader, setUserUpdateInfo, showToast]
    );

    const deleteUsers = async (token, data) => {
        // console.log("call from context");
        try {
            setLoader(true);
            const res = await UserManagementService.deleteUsers(token, data);
            if (res.status) {
                setLoader(false);
                showToast(res.data.message);
                return res.status;
            }
        } catch (error) {
            // console.log(error);
            setLoader(false);
            showToast(error.response.data.message, true);
        }
    };

    //-----Update user ------ //
    const updateProfile = async (token, id, data) => {
        try {
            setLoader(true);
            const res = await ProfileService.updateUserInfo(token, id, data);
            if (res.status) {
                setLoader(false);
                showToast(res.data.message);
                setUserUpdateInfo(res.data.user);
                // getUsers();
                return res.status;
            }
        } catch (error) {
            setLoader(false);
            showToast(error.response.data.message, true);
        }
    };

    // ------ Update user Password ------ //
    const updatePassword = async (token, data) => {
        try {
            setLoader(true);
            const res = await ProfileService.updateUserPass(token, data);
            if (res.status) {
                setLoader(false);
                showToast(res.data.message);
                return res.status;
            }
        } catch (error) {
            setLoader(false);
            showToast(error.response.data.message, true);
        }
    };

    const updateUser = async (token, data, id) => {
        try {
            setLoader(true);
            const res = await UserManagementService.updateUser(token, data, id);
            if (res.status) {
                setLoader(false);
                showToast(res?.data?.message);
                return res.status;
            }
        } catch (e) {
            showToast(e?.response?.data?.message);
        } finally {
            setLoader(false);
        }
    };

    const resetUserPassword = async (token, data, id) => {
        try {
            setLoader(true);
            const res = await UserManagementService.resetUserPassword(
                token,
                data,
                id
            );
            if (res.status) {
                setLoader(false);
                showToast(res?.data?.message);
                return res.status;
            }
        } catch (e) {
            showToast(e?.response?.data?.message);
        } finally {
            setLoader(false);
        }
    };

    return (
        <UserContext.Provider
            value={{
                LoginUser,
                user,
                userList,
                getUsers,
                usersTotalPages,
                setUser,
                setUserList,
                setUsersTotalPages,
                setLoader,
                userParams,
                setUserParams,
                loader,
                userUpdateInfo,
                setUserUpdateInfo,
                createUser,
                updateUser,
                updateProfile,
                updatePassword,
                getUserInfo,
                getUsersById,
                deleteUsers,
                resetUserPassword,
                logoutUser,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default withToast(UserProvider);
